//ThemeToggle.js
import {useContext} from 'react';
import {FaSun, FaMoon} from "react-icons/fa";
import {ThemeContext} from '../ThemeContext';
import {Props, ThemeContextType, connector} from '../../types';

function ThemeToggle(props: Props) {
  const {theme, changeTheme} = useContext(ThemeContext) as ThemeContextType;

  const {isLoggedIn} = props

  const handleThemeChange = (event: string) => {
    changeTheme(event);
  };

  return (
    <div className={`transition duration-500 ease-in-out rounded-full p-2 ${isLoggedIn ? "hidden" : "inline"} md:inline`}>
        {theme === 'dark' ? 
          <FaSun
              onClick={() => handleThemeChange(theme === 'dark' ? 'light' : 'dark')}
              className="text-gray-500 dark:text-gray-600 text-2xl cursor-pointer"
          />
         : 
          <FaMoon
              onClick={() => handleThemeChange(theme === 'dark' ? 'light' : 'dark')}
              className="text-gray-500 dark:text-gray-600 text-2xl cursor-pointer"
          />
        }
    </div>
  )
}

export default connector(ThemeToggle)