import {Props} from "../../../types"
import Google from "../Google"
import logo from '../../../assets/img/JK.png';
import { LazyLoadImage } from "react-lazy-load-image-component";

function SSO(props: Props) {
  const {text} = props
  return (
    <div className="rounded-t-xl mb-0 px-6 py-6 bg-white dark:bg-gray-900">
        <div className="text-center mb-3">
          <div className="flex items-center justify-center align-middle flex-wrap">
            <span className="text-blueGray-500 text-sm font-bold mx-3 dark:text-gray-400">
              Welcome to
            </span>
            <LazyLoadImage src={logo}
              visibleByDefault={true}
              className="block w-6 h-6"
              alt="Spendit"
            />
              <span className='
                ml-1
                text-gray-500
                text-xl
                font-bold
                font-serif
                dark:text-gray-400
                '
              >
                pendit
              </span>
          </div>
        </div>
        <div className="text-center">
            <Google {...props} text={text}/>
        </div>
        <hr className="mt-6 border-b-1 border-blueGray-300" />
    </div>
  )
}

export default SSO