import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Conversion, FinanceProps} from '../../types';

export const initialState = {
    currency: {
        name: "MYR",
        value: "MYR",
        rate: 1,
    }
} as FinanceProps;

const financeSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
      setCurrency: (state, action: PayloadAction<Conversion>) => {
        state.currency = action.payload;
      },
      reset: () => initialState,
    },
});

export const {setCurrency, reset} = financeSlice.actions;
export default financeSlice.reducer;