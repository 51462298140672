import component from '../../components';

function LoadingOverlay() {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
        <component.ButtonLoader isLoading={true} classNameProps='h-12 w-12 dark:text-gray-200' />
        <h2 className="text-center text-white text-xl font-semibold">Loading...</h2>
        <p className="w-1/3 text-center text-white">This may take a few seconds</p>
    </div>
  )
}

export default LoadingOverlay