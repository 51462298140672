import ListTable from "./ListTable";
import RadioTable from "./RadioTable";
import Table from "./Table";
import ShortenTable from "./ShortenTable";

const table = {
    ListTable,
    RadioTable,
    Table,
    ShortenTable
}

export default table;