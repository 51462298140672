

const colorConvert = (color: string) => {
    if (color === "red") {
        return `text-red-500 hover:text-red-600 dark:text-red-200 dark:hover:text-red-50`
    } else if (color === "green") {
        return `text-green-500 hover:text-green-600 dark:text-green-200 dark:hover:text-green-50`
    } else if (color === "blueGray") {
        return `text-blueGray-500 hover:text-blueGray-600 dark:text-blueGray-200 dark:hover:text-blueGray-50`
    } else {
        return `text-yellow-500 hover:text-yellow-600 dark:text-yellow-200 dark:hover:text-yellow-50`
    } 
}

const color = {
    colorConvert
}

export default color;