import {SharedModalProps, SubmitModalProps} from '../../../types';
import component from '../../../components'

function DeleteLoanModal(props: SharedModalProps & SubmitModalProps) {
  const {
    loanName = "Loan",
    open = false,
    closeModal = () => null,
    submit,
    isLoading = false
  } = props;

  return (
    <component.Modal
      title={`Delete ${loanName}`}
      open={open}
      closeModal={closeModal}
    >
      <component.SubmitModal 
        closeModal={closeModal}
        isLoading={isLoading}
        text="Are you sure ?"
        type="button"
        onClick={submit}
        colorClose='blue'
        colorSubmit='red'
      />
    </component.Modal>
  );
}

export default DeleteLoanModal;
