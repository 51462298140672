import { Transition } from '@headlessui/react'
import { AlertProps } from '../../types'

function Alert(props: AlertProps) {
  const {alert, message, status} = props

  return (
    <Transition
        show={alert}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
    >
        <div className="absolute p-5 z-[100]">
          <div className="bg-white rounded-lg border-gray-300 border p-3 shadow-lg dark:bg-gray-800 dark:border-transparent">
            <div className="flex flex-row">
              <div className="px-2">
                {status === "info" ?
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  :
                  status === "success" ?
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                }
              </div>
              <div className="ml-2 mr-6">
                <span className={`block text-gray-500 dark:text-white break-all`}>{message}</span>
              </div>
            </div>
            {/* <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-2">
              <div className="after:animate-shimmer after:translate-x-full bg-blue-600 h-2.5 rounded-full" style={{width: `${width}%`}}></div>
            </div> */}
          </div>
        </div>
    </Transition>
  )
}

export default Alert