import { useMemo } from 'react'
import { RadioGroup, Tab } from '@headlessui/react'
import moment from 'moment'
import currency from 'currency.js'
import { BasicProps, Conversion, ListTableProps } from '../../types'
import functions from '../../function'
import component from '../../components'

function RadioTable(props: ListTableProps) {
    const currencyLabel: Conversion = JSON.parse(localStorage.getItem("currency")!)
    const {
        data = {data: {}, years: []},
        click,
        loading,
        id = "",
    } = props

    const months = useMemo(() => {
        return [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
    }, []);

    const totalYear = Object.keys(data.data)

    const idx = totalYear
                      .map((x, index) => x === moment().format("YYYY") ? index : undefined)
                      .filter(x => x !== undefined)

    return (
        <div className="w-full">
            <Tab.Group defaultIndex={idx[0]}>
                <Tab.List className={`
                    snap-x 
                    ${totalYear.length > 3 && totalYear.length < 7 &&"sm:justify-center"}
                    ${totalYear.length > 7 && "sm:justify-start"}
                    ${totalYear.length < 3 ? "justify-center" : "justify-start"}
                    snap-mandatory 
                    flex
                    overflow-x-scroll
                    scrollbar-hide 
                    scroll-smooth
                    mx-3
                    space-x-3
                    pb-4
                `}
                >
                    {Object.keys(data.data).map((year) => (
                        <Tab 
                            key={year}
                            className={({ selected }) => 
                                `${click ? "cursor-pointer": "cursor-default"}
                                    snap-center 
                                    snap-always 
                                    ${selected ?
                                        "bg-gray-700 dark:bg-gray-500 text-blueGray-200"
                                        :
                                        "bg-white hover:bg-blueGray-300 dark:bg-blueGray-800 dark:hover:bg-blueGray-700 text-blueGray-500"
                                    }                          
                                    
                                    dark:text-white
                                    flex-shrink-0
                                    flex 
                                    rounded-lg
                                    hover:shadow-lg
                                    focus:outline-none
                                `
                            }
                        >
                            <div className={`
                                align-top 
                                items-center 
                                justify-center
                                p-4
                                hover:shadow-lg
                                w-full 
                                rounded-lg 
                                py-2.5 
                                text-sm 
                                font-medium 
                                leading-5 
                            `}>
                                {year}
                            </div>
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels className="mt-2">
                    {Object.keys(data.data).map((year) => (
                        <Tab.Panel key={year} className="overflow-y-hidden">
                            <div className="snap-y h-[50vh] mx:auto overflow-x-hidden lg:h-[70vh] md:h-[63vh] scrollbar-hide scroll-smooth">
                                <RadioGroup value={null} onChange={() => null}>
                                    <RadioGroup.Label className="sr-only">Radio Table</RadioGroup.Label>
                                        <div className="space-y-2 py-1">
                                            {months.map((month) => (
                                                data.data[year][month]?.sort((a: any, b: any) => {
                                                    if (b) {
                                                    return new Date(a.date).valueOf() - new Date(b.date).valueOf()
                                                    } else {
                                                    return new Date(a.date).valueOf() 
                                                    }
                                                }).map((x: any, ids: number)=> {
                                                    let color = "blueGray"
                                                    if (x.status === "paid") {
                                                        color = "green"
                                                    } else {
                                                        if (moment(x.date).isAfter(moment())) {
                                                            color = "yellow"
                                                        } else {
                                                            color = "red"
                                                        }
                                                    }
                                                    const val = currency(data.data[year][month][0].paid_amount, {precision: 2}).value
                                                    
                                                    return (
                                                        <RadioGroup.Option
                                                            key={ids}
                                                            value={x}
                                                            className={
                                                                `
                                                                snap-start 
                                                                snap-mandatory
                                                                grid 
                                                                place-items-center
                                                                text-gray-900  
                                                                bg-white
                                                                hover:bg-slate-50 
                                                                dark:bg-gray-900
                                                                dark:hover:text-white 
                                                                dark:text-white
                                                                dark:hover:bg-gray-700
                                                                m-3
                                                                relative 
                                                                cursor-pointer 
                                                                rounded-2xl 
                                                                px-5 
                                                                py-4 
                                                                shadow-md 
                                                                focus:outline-none
                                                                `
                                                            }
                                                        >
                                                            <div className="flex w-full items-center justify-between">
                                                                <div className="flex items-center">
                                                                    <div className="text-sm">
                                                                        <RadioGroup.Label
                                                                            as="span"
                                                                            className={`
                                                                                font-medium  
                                                                            `}
                                                                        >
                                                                            <div className='flex space-x-2 align-middle'>
                                                                                <div className="shrink-0 text-white pt-3">
                                                                                    <CheckIcon icondata={x} />
                                                                                </div>
                                                                                <div className={`flex-1 p-2 text-2xl ${color === "red" && "animate-pulse"} font-bold flex text-${color}-500 dark:text-${color}-300`}>
                                                                                    <span>
                                                                                        {currencyLabel.name}&nbsp;
                                                                                    </span>
                                                                                    <span>
                                                                                        {functions.currencyConversion(x.status === 'paid' ? val : data.data[year][month][0].payment)}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </RadioGroup.Label>
                                                                        <RadioGroup.Description
                                                                            as="span"
                                                                            className={`inline`}
                                                                        >
                                                                            <div className='pt-2 font-medium text-sm font-sans'>
                                                                                <span>
                                                                                    {month}
                                                                                </span>{' '}
                                                                                <span aria-hidden="true">&middot;</span>{' '}
                                                                                <span>
                                                                                    {moment(x.date).format("YYYY-MM-DD")}
                                                                                </span>{' '}
                                                                                <span aria-hidden="true">&middot;</span>{' '}
                                                                                <span>
                                                                                    Interest Charged: {
                                                                                        functions.currencyConversion(data.data[year][month].map(
                                                                                            (x: any) => x.interest)
                                                                                                .reduce(
                                                                                                (partialSum: any, a: any) => partialSum + a
                                                                                                , 0))
                                                                                    }
                                                                                </span>{' '}
                                                                            </div>
                                                                        </RadioGroup.Description>
                                                                    </div>
                                                                </div>
                                                                <div className="shrink-0 text-white">
                                                                    <button
                                                                        type="button"
                                                                        className='
                                                                        rounded-2xl
                                                                        shadow-md
                                                                        text-center
                                                                        p-2
                                                                        text-sm
                                                                        font-medium
                                                                        text-gray-700
                                                                        dark:text-gray-200
                                                                        dark:hover:text-gray-300
                                                                        hover:text-gray-800 
                                                                        dark:bg-blueGray-600
                                                                        dark:hover:bg-gray-800
                                                                        hover:bg-slate-200
                                                                        hover:ring-2 
                                                                        hover:ring-white 
                                                                        hover:ring-opacity-60 
                                                                        hover:ring-offset-2 
                                                                        hover:ring-offset-blueGray-300
                                                                    '
                                                                    onClick={() => click && click(x)}
                                                                    disabled={loading}
                                                                    >   
                                                                        <>
                                                                            {loading && parseInt(id) === parseInt(x.id) ?
                                                                                <component.ButtonLoader isLoading={true}/>   
                                                                                :
                                                                                parseInt(id) === parseInt(x.id) &&
                                                                                <component.ButtonLoader isLoading={true}/> 
                                                                            }
                                                                            {parseInt(id) !== parseInt(x.id) &&
                                                                                <component.LoanIcon status={x.status} />
                                                                            }
                                                                        </>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </RadioGroup.Option>
                                                )
                                            })
                                        ))}
                                    </div>
                                </RadioGroup>
                            </div>
                        </Tab.Panel>
                    ))} 
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}

export default RadioTable

function CheckIcon(props: BasicProps) {
    const {icondata} = props

    if (icondata?.status! === "paid") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-6 h-6 text-green-500 dark:text-green-300 animate-pulse`}>
                <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
            </svg>


        )
    } else {
        return (
            <>
                {
                    moment(icondata?.date).isAfter(moment()) ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-6 h-6 text-yellow-500 dark:text-yellow-300 animate-pulse`}>
                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-6 h-6 text-red-500 dark:text-red-300 animate-pulse`}>
                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                    </svg>
                }
            </>
        )
       
    }

}


