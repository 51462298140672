import {Link} from 'react-router-dom';
import logo from '../../assets/img/JK.png';
import { LazyLoadImage } from "react-lazy-load-image-component";

function NotFound() {
  return (
    <div
      className="
                flex
                items-center
                justify-center
                overflow-hidden
                w-screen
                h-screen
                bg-gradient-to-r
                from-indigo-600
                to-blue-400
            "
    >
      <div className="px-40 py-20 bg-white rounded-md shadow-xl">

        <div className="flex flex-col items-center">
          <LazyLoadImage src={logo}
            visibleByDefault={true}
            className='w-24 h-auto'
            alt="Spendit"
          />
          
          <h1 className="font-bold text-red-600 text-9xl">404</h1>

          <h6 className="
            mb-2
            text-2xl
            font-bold
            text-center
            text-gray-800
            md:text-3xl
            "
          >
            <span className="text-red-500">Oops!</span> Page not found
          </h6>

          <p className="mb-8 text-center text-gray-500 md:text-lg">
            The page you’re looking for doesn’t exist.
          </p>

          <Link
            to="/"
            className="
              px-6
              py-2
              text-sm
              font-semibold
              text-blue-800
              bg-blue-100
              hover:text-blue-100
              hover:bg-blue-400
              hover:shadow-lg
              "
          >
            Go home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
