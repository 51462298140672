import {Fragment, useEffect, useMemo, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {XIcon} from '@heroicons/react/outline';
import {connector, Props} from '../../types';
import moment from 'moment';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { decoding, encoding } from '../../encryption/encryption';
import { useGetNotifications } from './queryNotification';
import { useReadChange, useViewChange } from './mutateNotification';
import component from '../../components';

function Slider(props: Props) {
  const {user} = props
  const [notification, setNotification] = useState<Array<Record<string, any>>>([])
  const {open, setOpen} = props;
  const {data, refetch, isLoading} = useGetNotifications(props.user)

  useEffect(() => {
    if (data) {
      setNotification(data)
    }
  }, [data])

  let st = "ws"
  let url = process.env.REACT_APP_HOSTNAME
  if (process.env.NODE_ENV === "production") {
    url = process.env.REACT_APP_BACKEND_HOSTNAME
    st = "wss"
  }

  const { sendMessage, getWebSocket, readyState } = useWebSocket(`${st}://${url}/api/notification`);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];
  
  useEffect(() => {
    setInterval(() => {
      sendMessage(user.id);
    }, 60000)
    
    if (connectionStatus === "Open") {
      getWebSocket()!.onmessage = function (event) {
        const json: Array<Record<string, any>> = decoding(event.data);
        setNotification(json)
      };
    }
  }, [readyState, connectionStatus, getWebSocket, sendMessage, user.id])

  const datas = useMemo(() => {
    const sorting = notification.sort((x, y) => {
      var c = new Date(y.due_date).getTime();
      var d = new Date(x.due_date).getTime();
      return d > c ? 1 : -1;  
    })

    const viewFilter = sorting.filter(x => !x.view)
    let d: Array<Record<string, any>> = viewFilter.map((x: Record<string, any>)=> ({...x, timeline: moment(x.due_date).toNow(true)}))
    return d
  }, [notification])

  const {mutate: readMutate, isLoading: readLoading} = useReadChange()

  const setRead = (id: number) => {
    const find = notification.find(x => x.id === id)

    const readStats = {
      read_status: !find!.read_status
    }

    const encoded = encoding(readStats)

    const payload = {
      userInformation: encoded,
    };

    const payloads = [id, payload]

    readMutate(payloads, {
      onSuccess: ({data}) => {
        if (data.success) {
          refetch()
        }
      }
    })
  }

  const setOpens = setOpen ? setOpen : () => null;

  const {mutate: viewMutate, isLoading: viewLoading} = useViewChange()

  const clearNotification = () => {
    if (data.length > 0) {
      const allId = datas.map((x: Record<string, any>) => x.id)

      const encoded = encoding({
        notification: allId
      })

      const payload = {
        userInformation: encoded
      }

      viewMutate(payload, {
        onSuccess: ({data}) => {
          if (data.success) {
            refetch()
          }
        }
      })
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpens}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="
              fixed inset-0
              bg-gray-500
              bg-opacity-75
              transition-opacity
              "
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="
              pointer-events-none
              fixed
              inset-y-0
              right-0
              flex
              max-w-full
              pl-10
              "
            >
              <Transition.Child
                as={Fragment}
                enter="
                  transform
                  transition
                  ease-in-out
                  duration-500
                  sm:duration-700
                "
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="
                  transform
                  transition
                  ease-in-out
                  duration-500
                  sm:duration-700
                "
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className="
                    pointer-events-auto
                    relative
                    w-screen
                    max-w-md
                  "
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="
                      absolute
                      top-0
                      left-0
                      -ml-8
                      flex
                      pt-4
                      pr-2
                      sm:-ml-10
                      sm:pr-4
                      "
                    >
                      <button
                        type="button"
                        className="
                          rounded-md
                          text-gray-300
                          hover:text-white
                          focus:outline-none
                          focus:ring-2
                          focus:ring-white
                          "
                        onClick={() => setOpens(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="
                    flex
                    h-full
                    flex-col
                    overflow-y-scroll
                    bg-white
                    dark:bg-blueGray-400
                    py-6
                    shadow-xl
                    "
                  >
                    <div className="flex flex-wrap justify-between align-baseline px-5">
                      <Dialog.Title
                        className="text-lg font-medium text-gray-900 dark:text-white"
                      >
                         Notifications
                      </Dialog.Title>
                      <button className={
                        `
                        inline-flex
                        align-middle 
                        justify-center
                        px-4
                        py-2
                        text-sm
                        text-white
                        font-medium
                        bg-blueGray-500
                        border
                        border-transparent
                        rounded-md
                        hover:bg-blueGray-700
                        mr-7
                        sm:mr-0
                        `
                      }
                        onClick={clearNotification}
                      >
                        Clear
                      </button>
                    </div>
                    <div className="relative mt-6 flex-1 flex-wrap pl-4 pr-14 tsizs:pr-4 xsizs:pr-14 sm:px-6">
                      {(isLoading || readLoading || viewLoading) ? 
                        <>
                          <div className="flex h-full">
                            <div className="m-auto">
                              <component.ButtonLoader isLoading={true} classNameProps="text-inherit" />
                            </div>
                          </div>
                        </>
                        :
                        datas && datas.length > 0 ? datas.map((x: Record<string, any>) => (
                          <div 
                            onClick={() => setRead(x.id)}
                            key={x.id}
                            className={`
                              p-5
                              hover:bg-slate-500
                              rounded-xl
                              mt-6
                              dark:text-white
                              flex 
                              flex-wrap
                              justify-between
                              align-middle
                              dark:hover:bg-gray-900
                              ${x.read_status ? 
                                "dark:bg-blueGray-800 bg-slate-300"
                                :
                                "dark:bg-blueGray-700 bg-slate-400" 
                              }
                            `}
                          >
                            <div
                              className={
                                !x.read_status ?
                                "truncate"
                                :
                                ""
                              }
                            >
                              {x.message}
                            </div>
                            <div className='flex justify-start flex-wrap space-x-2'>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor" 
                                className={`
                                  w-6 
                                  h-6
                                  ${x.read_status ? "text-green-400" : "text-red-400"}
                                `}>
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" 
                                />
                                <path 
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                              <div
                                className='truncate'
                              >
                                {x.timeline}
                              </div>
                            </div>
                          </div>
                        ))
                        :
                        <>
                          <div className="flex h-full align-middle justify-center">
                            <div className="m-auto">
                              <h3 className='text-white animate-pulse maxsizs:pl-10'>No unread notifications ...</h3>
                            </div>
                          </div>
                          <div className="absolute inset-0 px-4 sm:px-6">
                            <div
                              className="
                                h-full
                                border-2
                                border-dashed
                                border-gray-200
                              "
                              aria-hidden="true"
                            />
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default connector(Slider)
