import { useContext } from 'react';
import {connector, Conversion, Props, ThemeContextType} from '../../../types';
import { useGetCurrencyConversion } from '../mutateFinance';
import { ThemeContext } from '../../../theme/ThemeContext';
import { Switch } from '@headlessui/react';
import component from '../../../components';

function Account(props: Props) {
  const {
    setCurrency,
    currency
  } = props;

  const currency_list = [
    {
      name: "MYR",
      value: "MYR",
    },
    {
      name: "USD",
      value: "USD"
    }
  ]
  
  const {mutate} = useGetCurrencyConversion()

  const {theme, changeTheme} = useContext(ThemeContext) as ThemeContextType;

  const handleThemeChange = (event: string) => {
    changeTheme(event);
  };

  const setCurrencyRate = (e: Conversion) => {
    mutate(e.value, {
      onSuccess: ({data}) => {
        let amount: Conversion
        if (data.success) {
          amount = {
            ...e,
            rate: data.data.convertedAmount
          }
        } else {
          amount = {
            ...e,
            rate: 1
          }
        }
        
        localStorage.setItem("currency", JSON.stringify(amount))
        setCurrency(amount)
      },
    });
  }

  return (
    <div className='px-10 py-16 flex flex-col space-y-5'>
      <div className='flex flex-wrap justify-between'>
        <div className='text-lg dark:text-gray-400 font-semibold'>
          Currency: 
        </div>
        <div className='w-40 ml-5'>
          <component.Select
            people={currency_list}
            selected={currency}
            setSelected={setCurrencyRate}
            classNameProps='z-10'
          />
        </div>
      </div>
      <div className='flex flex-wrap justify-between'>
        <div className='text-lg dark:text-gray-400 font-semibold'>
          Theme: 
        </div>
        <div className=''>
          <Switch
            checked={theme === 'dark'}
            onChange={() => handleThemeChange(theme === 'dark' ? 'light' : 'dark')}
            className={`${
              theme === 'dark' ? 'bg-white' : 'bg-blueGray-800 dark:bg-gray-400'
            } relative flex h-6 w-11 items-center content-end align-middle rounded-full`}
          >
            <span
                className={`${
                  theme === 'dark' ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white dark:bg-blueGray-800`}
            />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default connector(Account);