import moment from 'moment';
import { useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { encoding } from '../../encryption/encryption';
import { Props } from '../../types';
import { useChangeStatus, useMemoLoan } from './mutateLoan';
import { useGetLoan } from './queryLoan';
import functions from '../../function';
import component from '../../components';
import currency from 'currency.js';

function Summary(props: Props) {

    const [paidLoans, setPaidLoans] = useState(false);
    const [todayDate, setTodayDate] = useState({
        name: moment().format('YYYY'),
        value: moment().format('YYYY')
    });

    const {
        data,
        isLoading: dataLoading,
        isFetching,
        refetch,
    } = useGetLoan(props.user); 

    const {
        register: registerPaid,
        handleSubmit: handlePaidSubmit,
        formState: {errors: errorsPaid},
        reset: resetPaidValue,
        setValue: setPaidValue,
        control,
        watch: paidWatch,
    } = useForm();

    const processData = useMemoLoan(data)

    if (processData.years.length > 0) {
        const current_year = processData.years.filter(x => x.value === todayDate.value)

        if (current_year.length === 0) {
            setTodayDate(processData.years[0])
        }
    }

    const {
        mutate: statusMutate,
        isLoading: statusLoading,
    } = useChangeStatus();

    const paidLoansModal = () => {
        setPaidLoans(false)
        resetPaidValue()
    }
    
    const checkBoxDefaultSubmit = (userInfo: string, table_id: string) => {
        const payloads = {
          userInformation: userInfo,
        };
    
        const payload = [table_id, payloads];
    
        statusMutate(payload, {
          onSuccess: ({data: resp}) => {
            if (resp.success) {
              paidLoansModal();
              refetch();
            } else {
              console.log(resp.message);
            }
          },
        });
    };
    
    // eslint-disable-next-line
    const checkBoxSubmit = (e: any) => {
        const finder = data.find(x => x.data.id === e.table_id)

        let principal = e.amount_required 
        let amount = e.auto_amount ? 0 : e.paid_amount;
        if (finder?.data?.interest_rate === 0) {
            principal = e.amount_required
            amount = amount - e.amount_required
        } 

        if (amount < 0 && Math.abs(amount) === principal) {
            amount = 0
        }

        if (finder?.data?.interest_rate === 0  && e.auto_amount === false && e.paid_amount === "0") {
            amount = -e.amount_required
        }

        const userInfo = encoding({
            id: e.id,
            status: 'paid',
            principal: functions.currencyDefault(principal),
            extra_payment: functions.currencyDefault(amount),
        });

        checkBoxDefaultSubmit(userInfo, e.table_id);
    };


    const paidLoan = (
        // eslint-disable-next-line
        row: Record<string, any>,
    ) => {
        if (row.status === 'paid') {
            const userInfo = encoding({
            id: row.id,
            status: 'unpaid',
            });
            checkBoxDefaultSubmit(userInfo, row.table_id);
        } else {
            setPaidLoans(true);

            const v1 = row.payment

            setPaidValue('table_id', row.table_id)
            setPaidValue('id', row.id);
            setPaidValue('payment_date', moment(row.date).format("YYYY-MM-DD"));
            setPaidValue('amount_required', functions.currencyConversion(v1));
        }
    };

    if (dataLoading) {
        return <component.Loading />;
    }

    return (
        <div className='p-4 h-full'>
            <div
                className={
                `flex justify-between items-center align-middle flex-wrap`
                }
            >
                <div className={`font-semibold text-sm text-black dark:text-white mt-5`}>
                    <Link to="/loan" className='rounded-md
                        shadow-md
                        p-3
                        text-sm
                        bg-gray-300
                        hover:bg-gray-500
                        dark:bg-gray-500
                        dark:hover:bg-gray-300
                        font-medium
                        dark:text-gray-100
                        dark:hover:text-gray-700
                        hover:text-white'
                    >
                        Back
                    </Link>
                </div>
                {isFetching ?
                    <component.ButtonLoader isLoading={isFetching} />
                :
                <div className={"font-semibold text-lg text-black dark:text-white"}>
                    Loan left for year {todayDate.name}: {" "} {" "} 
                    {
                        functions.currencyConversion(data.map(x => {
                            const loanData = x.data
                            
                            const d = loanData.data.filter(z => moment(z.date).format("YYYY") === todayDate.value)
                            const payment = d.filter(z => z.status === "paid")
                                                  .map(z => currency(z.paid_amount, {precision: 2}).value)
                                                  .reduce((a, b) => currency(a, {precision: 2}).add(b).value, 0)

                            const val = d.map(z => currency(z.payment, {precision: 2}).value)
                                         .reduce((a, b) => currency(a, {precision: 2}).add(b).value, 0)

                            const goodVal = currency(val).subtract(payment).value

                            return goodVal

                        }).reduce((a, b) => currency(a, {precision: 2}).add(b).value, 0))
                    }
                </div>
                }
                
            </div>
            <div className='pt-5'>
                <component.Select
                    people={processData.years}
                    selected={todayDate}
                    setSelected={(e) => setTodayDate(e)}
                />
                <component.ListTable
                    data={processData}
                    click={paidLoan}
                    years={todayDate}
                    loading={statusLoading || isFetching}
                />
            </div>
            <component.ExtraPaidLoanModal
                open={paidLoans}
                closeModal={paidLoansModal}
                register={registerPaid}
                submit={checkBoxSubmit}
                handleSubmit={handlePaidSubmit}
                errors={errorsPaid}
                isLoading={statusLoading}
                watch={paidWatch}
                control={control}
            />
        </div>
    )
}

export default Summary