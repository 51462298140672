import component from '../../components';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {encoding} from '../../encryption/encryption';
import {useUserSignUp} from './mutateAuth';
import Shared from './components/Shared';
import {useForm, SubmitHandler, FieldValues} from 'react-hook-form';
import {CustomModalForm, Props, connector} from '../../types';
import SSO from './components/SSO';

function Signup(props: Props) {
  const {setAlert, setAlertMessage, setAlertStatus} = props;
  const {register, handleSubmit, formState: {errors}, reset, watch} = useForm();

  const navigate = useNavigate();

  const [message, setMessage] = useState('');

  const success = (message: string) => {
    reset();
    setAlert(true)
    setAlertMessage(message)
    setAlertStatus("success")
    navigate('/');
  };

  const {mutate, isLoading} = useUserSignUp();

  const submit: SubmitHandler<FieldValues> = (data) => {
    const userInfo = encoding({
      ...data,
      email: data.email.toLowerCase(),
      exp: Math.round(Date.now() / 1000) + (60 * 10), // 10 min expiration
    });

    const payload = {
      userInformation: userInfo,
    };

    mutate(payload, {
      onSuccess: ({data: res}) => {
        if (res.success) {
          success(res.message);
        } else {
          setMessage(res.message);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const customForm: Array<CustomModalForm> = [
    {
      divProps: {
        className: "col-span-6 sm:col-span-3"
      },
      label: "First name",
      labelProps: {
        htmlFor: "first-name",
      },
      inputClassName: "p-2",
      inputProps: {
        type: "text",
        ...register('firstName', {required: true}),
        autoComplete: "given-name",
        placeholder: "Your Given Name"
      },
      children: errors.firstName &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    },
    {
      divProps: {
        className:"col-span-6 sm:col-span-3"
      },
      label: "Last name",
      labelProps: {
        htmlFor: "last-name",
      },
      inputProps: {
        type: "text",
        ...register('lastName', {required: true}),
        autoComplete: "family-name",
        placeholder: "Your family name"
      },
      children: errors.lastName &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    },
    {
      divProps: {
        className:"col-span-6 sm:col-span-6"
      },
      label: "Email address",
      labelProps: {
        htmlFor: "email-address"
      },
      inputProps: {
        type: "email",
        ...register('email', {
          pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
          required: true,
        }),
        autoComplete: "email",
        placeholder: "Welcome...."
      },
      children: <>
        {errors.email &&
          errors.email.type === 'pattern' &&
            <p className='text-sm text-red-500'>
              Invalid email
            </p>
        }
        {errors.email &&
          errors.email.type === 'required' &&
            <p className='text-sm text-red-500'>
              This field is required
            </p>
        }
        {message !== '' &&
          <p className='text-sm text-red-500'>
            {message}
          </p>
        }
      </>
    },
    {
      divProps: {
        className: "col-span-6"
      },
      label: "Password",
      labelProps: {
        htmlFor: "password"
      },
      inputProps: {
        type: "password",
        ...register('password', {
          required: true,
          minLength: 7,
        }),
        autoComplete: "password",
        placeholder: "Give it a new life ...."
      },
      children: <>
        {errors.password &&
          errors.password.type === 'required' &&
            <p className='text-sm text-red-500'>
              This field is required
            </p>
        }
        {errors.password &&
          errors.password.type === 'minLength' &&
            <p className='text-sm text-red-500'>
              Password should be more than 7 characters
            </p>
        }
        {errors.password &&
          errors.password.type === 'validate' &&
            <p className='text-sm text-red-500'>
              Password is not the same as confirm password
            </p>
        }
      </>
    },
    {
      divProps: {
        className: "col-span-6"
      },
      label: "Confirm Password",
      labelProps: {
        htmlFor: "confirm-password"
      },
      inputProps: {
        type: "password",
        ...register('confirmPassword', {
          validate: (val) => val === watch('password'),
          required: true,
          minLength: 7,
        }),
        autoComplete: "password",
        placeholder: "Now do that again ...."
      },
      children: <>
        {errors.confirmPassword &&
          errors.confirmPassword.type === 'required' &&
            <p className='text-sm text-red-500'>
              This field is required
            </p>
        }
        {errors.confirmPassword &&
          errors.confirmPassword.type === 'minLength' &&
            <p className='text-sm text-red-500'>
              Password should be more than 7 characters
            </p>
        }
        {errors.confirmPassword &&
          errors.confirmPassword.type === 'validate' &&
            <p className='text-sm text-red-500'>
              Confirm password is not the same as password
            </p>
        }
      </>
    }
  ]

  return (
    <Shared
      to="/"
      link="Sign in now"
      header="Create your new account now"
      reset={reset}
      {...props}
    >
      <form
        name='signUp'
        className="mt-8 space-y-6"
        onSubmit={handleSubmit(submit)}
      >
        <div className="shadow overflow-hidden sm:rounded-md">
          <SSO {...props} text="Sign up with" />
          <div className="px-4 py-5 bg-white sm:p-6 dark:bg-gray-900">
            <div className="grid grid-cols-6 gap-6">
              <component.FormCustom customForm={customForm} />
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-900 pb-5">
            <button type="submit"
              className="
                inline-flex
                w-full
                justify-center
                py-2
                px-4
                border
                border-transparent
                shadow-sm
                text-sm
                font-medium
                rounded-md
                text-white
                bg-secondary
                hover:bg-primary
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-primary
                "
            >
              <component.ButtonLoader isLoading={isLoading} />
              Sign Up
            </button>
          </div>
        </div>
      </form>
    </Shared>
  );
}

export default connector(Signup);
