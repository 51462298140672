import {api} from './api';
import {useQuery} from 'react-query';
import {Props} from './types';

export const useCheckUser = (props: Props) => {
  const {
    data,
    isLoading,
    isFetching,
    isSuccess,
    error,
    refetch,
  } = useQuery('check_user', async () => {
    const {setUser, setIsLoggedIn, setAdmin, setUUID} = props;
    const {data: check} = await api.check();

    if (check.success) {
      setUUID(check.uuid)
      setIsLoggedIn(true);
      if (check.user[0]['is_superuser']) {
        setAdmin(true);
      }
      setUser(check.user[0]);
    } else {
      setIsLoggedIn(false);
      setUser({});
    }
  });

  return {data, isLoading, isFetching, isSuccess, error, refetch};
};
