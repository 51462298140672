import {SubmitModalProps, SharedModalProps} from '../../../types';
import component from '../../../components';
import hook from '../../../hooks';

function ResetPasswordModal(
  props: SharedModalProps & SubmitModalProps
) {

  const [hoverRef, isHovered] = hook.useHover();

  const {
    open = false,
    closeModal = () => null,
    submit,
    isLoading = false,
  } = props;

  return (
    <component.Modal
      title="Reset Password ?"
      open={open}
      closeModal={closeModal}
    >
      <component.SubmitModal 
        closeModal={closeModal}
        isLoading={isLoading}
        text={isHovered ? "Yes" : "Are you sure ?"}
        type="button"
        onClick={submit}
        colorSubmit="red"
        colorClose='blue'
        classNameProps="w-40"
        hoverRef={hoverRef}
      />
    </component.Modal>
  );
}

export default ResetPasswordModal;
