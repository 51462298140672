import functions from "../../function"
import { SummarizerProps } from "../../types"

function Summarizer(props: SummarizerProps) {
    const {
        endingBalance,
        interestLeft,
        name,
        color,
    } = props


  return (
    <div className='relative flex flex-shrink align-middle justify-center mt-4 mx-4 rounded-lg bg-white hover:bg-blueGray-300 dark:bg-blueGray-800 dark:hover:bg-blueGray-700'>
        <div className={`
            ${functions.colorConvert(color)}
            bg-blue-200
            dark:bg-primary
            font-medium w-full h-32 pt-11 rounded-l-xl justify-start text-start sm:text-center pl-3 text-xl md:text-4xl sm:text-2xl`}>
            <span>{functions.currencyConversion(endingBalance, undefined, undefined, undefined, true)}</span>
            <br/>
            <span className='text-sm animate-pulse '>   
            Your current loan balance
            </span>
        </div>
        <div className="capitalize whitespace-normal hidden sm:absolute w-24 h-24 font-semibold mt-4 bg-gray-800 dark:bg-gray-500 text-white rounded-full sm:flex justify-center items-center text-center p-5 shadow-xl break-all">
            <span>{name}</span>
        </div>
        <div className={`
            ${functions.colorConvert(color)}
            bg-green-200
            dark:bg-secondary
            font-medium w-full h-32 pt-11 rounded-r-xl justify-start text-end sm:text-center pr-3 text-xl md:text-4xl sm:text-2xl`}>
            <span>{functions.currencyConversion(interestLeft, undefined, undefined, undefined, true)}</span>
            <br/>
            <span className='text-sm animate-pulse '>   
            Your current interest balance
            </span>
        </div>
    </div>
  )
}

export default Summarizer