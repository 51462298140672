import {FieldValues} from 'react-hook-form';
import {GooglePayload, Payload} from '../../types';
import api from '../default';

// User
const signUp = (payload: Payload) =>
  api.post('signup', payload);

const signIn = (payload: Payload) =>
  api.post('signin', payload);

const googleLogin = (payload: GooglePayload) =>
  api.post('signin/google', payload);

const check = () =>
  api.get('check');

const logout = () =>
  api.delete('logout');

const resetPassword = (payload: FieldValues) =>
  api.post('reset-password', payload);

const getResetPassword = (payload: string) =>
  api.get('reset-password', {
    params: {
      id: payload,
    },
  });

const resetUserPassword = (payload: Payload) =>
  api.post('reset-user-password', payload);

const getCurrencyRate = (name: string) =>
  api.get(`currency/${name}`)

const getNotifications = () => 
  api.get("notification")

const notificationReadChange = (id: string, payload: Payload) => 
  api.put(`notification/${id}`, payload)

const notificationViewChange = (payload: Payload) =>
  api.put("notification", payload)

const mainAPI = {
  signUp,
  signIn,
  googleLogin,
  check,
  logout,
  resetPassword,
  getResetPassword,
  resetUserPassword,
  getCurrencyRate,
  getNotifications,
  notificationReadChange,
  notificationViewChange
};

export default mainAPI;
