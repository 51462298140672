import {Suspense} from 'react';
import {useCheckUser} from './queryApp';
import Layout from './layout/Layout';
import {Route, Routes} from 'react-router-dom';
import Signin from './views/auth/Signin';
import Signup from './views/auth/Signup';
import ResetPassword from './views/auth/ResetPassword';
import ResetPasswordID from './views/auth/ResetPasswordID';
import NotFound from './views/error/NotFound';
import {connector, Props} from './types';
import {useGetCurrencyRate} from './layout/components/queryFinance';
import component from './components';

function App(props: Props) {
  const {isLoggedIn} = props;

  const {isLoading, refetch, isFetching} = useCheckUser(props);

  const {isLoading: currencyLoading} = useGetCurrencyRate(props);

  if (isLoading || currencyLoading || isFetching) {
    return (
      <component.Loading />
    );
  } else if (isLoggedIn) {
    return (
      <Routes>
        <Route path="*" element={<Layout refetch={refetch} />} />
      </Routes>
    );
  } else {
    return (
      <Suspense fallback={<component.Loading/>}>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password/:id" element={<ResetPasswordID />} />
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </Suspense>
    );
  }
}

export default connector(App);
