import currency from "currency.js"
import { ColumnRender, FixedSizeArray, ShortColumnRender, SnapRender, Stock, clicker } from "../../../types"
import { Link } from "react-router-dom";
import moment from "moment";
import component from '../../../components';


export const columnRenderer = (clicks: clicker, openSellModal: (row: Record<string, any>) => void)=> {
  const column: Array<ColumnRender> = [
      {
        title: 'Date',
        dataIndex: 'date',
      },
      {
        title: 'Cost/Share',
        dataIndex: 'cost',
      },
      {
          title: 'Shares',
          dataIndex: 'shares',
          render: (_, row) => {
              const soldShares = currency(row.sold!.map((x: Stock) => x.shares)
              .reduce((x: number, y: number) => x + y , 0), {precision: 2})
      
              const shares_left = currency(row.shares, {precision: 2})
                                  .subtract(soldShares)
                                  .value
              
              return (
              <div className="text-center text-sm text-inherit">{shares_left}</div>
              )
          }
      },
      {
        title: 'Edit',
        dataIndex: 'edit',
        render: (_: any, row: Record<string, any>) => {
          return (
            <div className="w-full text-center text-sm text-inherit">
              <button
                type="button"
                onClick={() => clicks(row)}
                className={`
                  inline-flex
                  align-middle 
                  justify-center
                  cursor-pointer
                  p-1
                  w-full
                  text-sm
                  font-medium
                  text-blue-900
                  bg-blue-100
                  border
                  border-transparent
                  rounded-md
                  hover:bg-blue-200
                  focus:outline-none
                  focus-visible:ring-2
                  focus-visible:ring-offset-2
                  focus-visible:ring-blue-500
                `}
              >
                Edit
              </button>
            </div>
          )
        }
      },
      {
        title: 'Sell',
        dataIndex: 'sell',
        render: (_: any, row: Record<string, any>) => {
          const soldShares = currency(row.sold!.map((x: Stock) => x.shares)
            .reduce((x: number, y: number) => x + y , 0), {precision: 2})
  
          const shares_left = currency(row.shares, {precision: 2})
                                .subtract(soldShares)
                                .value
          
          const sell_enable = shares_left !== 0
          return (
            <div className="w-full text-center text-sm text-inherit">
              <button
                type="button"
                disabled={!sell_enable}
                onClick={() => openSellModal(row)}
                className={`
                  inline-flex
                  align-middle 
                  justify-center
                  ${!sell_enable? "cursor-not-allowed" : "cursor-pointer"}
                  p-1
                  w-full
                  text-sm
                  font-medium
                  text-yellow-900
                  bg-yellow-100
                  border
                  border-transparent
                  rounded-md
                  ${!sell_enable? "" : "hover:bg-yellow-200"}
                  focus:outline-none
                  focus-visible:ring-2
                  focus-visible:ring-offset-2
                  focus-visible:ring-yellow-500
                `}
              >
                Sell
              </button>
            </div>
          )
        },
      },
      {
        title: 'Sold',
        dataIndex: 'sold',
        render: (_: any, row: Record<string, any>) => {
          const soldExist = row.sold.length !== 0
          return (
            <div className="w-full text-center text-sm text-inherit">
              <Link
                to={!soldExist? "#" : `${row.id}`}
                className={`
                  inline-flex
                  align-middle 
                  justify-center
                  ${!soldExist? "cursor-not-allowed" : "cursor-pointer"}
                  p-1
                  w-full
                  text-sm
                  font-medium
                  text-green-900
                  bg-green-100
                  border
                  border-transparent
                  rounded-md
                  ${!soldExist? "" : "hover:bg-green-200"}
                  focus:outline-none
                  focus-visible:ring-2
                  focus-visible:ring-offset-2
                  focus-visible:ring-green-500
                `}
              >
                View Sold
              </Link>
            </div>
          )
        },
      }
  ];

  return column
}

export const columnSnapRenderer = () => {
  const columnSnap: FixedSizeArray<SnapRender, 3> = [
    {
      type: "title",
      dataIndex: 'name',
    },
    {
      type: "data",
      dataIndex: 'value'
    },
    {
      type: "data",
      dataIndex: 'value',
      render: (_, row: any) => {
        let keys = "nothing"
        if (typeof(row.value) === "string" && row.value.includes("+")) {
          keys= "positive"
        } else if (typeof(row.value) === "string" && row.value.includes("-")) {
          keys = "negative"
        }
        return (
          <component.EarnLoss key={keys} keys={keys} />
        )
      }
    }
  ]

  return columnSnap
}

export const shortenColumnRenderer = () => {
  const shortenColumn: FixedSizeArray<ShortColumnRender, 2> = [
    {
      dataIndex: 'date',
      render: (date, _) => {
        return (
          <div className="text-center text-sm text-inherit">{moment(date).format("Do ddd, YYYY")}</div>
        )
      }
    },
    {
      dataIndex: 'shares',
      render: (_, row) => {
        const soldShares = currency(row.sold!.map((x: Stock) => x.shares)
          .reduce((x: number, y: number) => x + y , 0), {precision: 2})

        const shares_left = currency(row.shares, {precision: 2})
                              .subtract(soldShares)
                              .value
        
        return (
          <div className="text-center text-sm text-inherit">{shares_left}</div>
        )
      }
    }
  ]

  return shortenColumn
}