import logo from '../../assets/img/JK.png';
import { LazyLoadImage } from "react-lazy-load-image-component";

function Loading() {
  return (
    <div className='flex h-screen bg-white dark:bg-blueGray-800'>
      <p className='m-auto text-center text-xl'>
        <LazyLoadImage src={logo}
          visibleByDefault={true}
          className='animate-bounce w-24 h-24'
          alt="Spendit"
        />
      </p>
    </div>
  );
}

export default Loading;
