import { Link, useParams } from "react-router-dom"
import { useState } from "react";
import moment from "moment";
import currency from "currency.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { ColumnRender, Props, connector, Stock as Stocks, FixedSizeArray, SnapRender} from "../../types";
import { regex } from "../../variables/var";
import component from "../../components";
import hook from '../../hooks';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Dividends(props: Props) {
  const params = useParams()

  const totalYearsSelection = [
    {
      name: "All",
      value: "all"
    },
    {
      name: "10Y",
      value: "10"
    },
    {
      name: "5Y",
      value: "5"
    },
    {
      name: "3Y",
      value: "3"
    },
    {
      name: "1Y",
      value: "1"
    }
  ]

  const [years, selectYears] = useState({
    name: "1Y",
    value: "1"
  });

  const {
    data,
    isLoading: dataLoading
  } = hook.useGetStock(props.user); 

  const filter = hook.useMemoStock(data, params)

  const {
    data: dividends,
    isLoading: dividendsLoading
  } = hook.useGetDividends(filter?.ticker)

  const rgbaColor = component.useColorPalatte()

  const dividendsFilter = hook.useMemoDividends(dividends, years, rgbaColor)

  const column: Array<ColumnRender> = [
    {
      title: 'Date',
      dataIndex: 'Date',
    },
    {
      title: 'Dividends',
      dataIndex: 'Dividends',
    },
    {
      title: 'Dividends Earn',
      dataIndex: 'dividend',
      render: (_, row) => {
        let d: Array<Stocks> = filter?.data!

        const divident_entitle = d.map(x => {
          if (x.sold!.length === 0) {
            if (moment(x.date).isBefore(row.Date)) {
              return {...x, dividend: currency(x.shares, {precision: 2}).multiply(row.Dividends).value}
            } else {
              return {...x, dividend: 0}
            }
          } else {
            const e = x.sold!.map(z => {
              if (moment(row.Date).isBetween(x.date, z.date)) {
                return {...z, dividend: {[row.Date]: false} }
              } else {
                return {...z, dividend: {[row.Date]: true}}
              }
            })

            const value = e.map((z) => z.dividend[row.Date] ? z.shares: 0).reduce((x, y) => x + y, 0)

            return {...x, dividend: currency(x.shares, {precision: 2}).subtract(value).multiply(row.Dividends).value}
          }
        })

        const val = divident_entitle.map(z => z.dividend).reduce((x, y) => x + y, 0)
        
        return (
          <div
            className="text-center text-sm  text-inherit"
          >
            {val}
          </div>
        )
      }
    }
  ];

  const shortenColumn: FixedSizeArray<SnapRender, 3> = [
    {
      "type": "title",
      "dataIndex": "name"
    },
    {
      "type": "data",
      "dataIndex": "value"
    },
    {
      type: "data",
      dataIndex: 'value',
      render: (_, row: any) => {
        const split = row.value.split("CAGR: ")
        const splits = split[1].split("%")

        const val = parseFloat(splits[0])
        let keys = "nothing"
        if (val > 0) {
          keys= "positive"
        } else if (val < 0) {
          keys = "negative"
        }
        return (
          <component.EarnLoss key={keys} keys={keys} />
        )
      }
    }
  ]

  if (dataLoading) {
    return <component.Loading />;
  }
  return (
    <div className='p-4 h-full'>
      <div
        className={`flex flex-wrap justify-between items-center align-middle mt-3 maxsizs:space-y-8`}
      >
          <div className={`font-semibold text-sm text-black dark:text-white`}>
              <Link to={`/stock/${params.id}`} className='rounded-md
                  shadow-md
                  p-3
                  text-sm
                  bg-gray-300
                  hover:bg-gray-500
                  dark:bg-gray-500
                  dark:hover:bg-gray-300
                  font-medium
                  dark:text-gray-100
                  dark:hover:text-gray-700
                  hover:text-white'
              >
                Back
              </Link>
          </div>
          <div className={`font-semibold text-xl text-black dark:text-white text-center buttonmax:w-full`}>
            {filter?.name?.replace(regex, "")}
          </div>
          <div className='space-x-3 flex flex-wrap'>
            <div className='w-40'>
              <component.Select
                people={totalYearsSelection}
                selected={years}
                setSelected={(e) => selectYears(e)}
              />
            </div>
          </div>
      </div>
      <component.Snap 
        shortenColumn={shortenColumn}
        customSnap={dividendsFilter}
        chartsLoading={dividendsLoading}
      />
      <div className="px-4 py-10 md:px-10 grid grid-cols-10 gap-3">
        <div className="bg-white hover:bg-slate-50 shadow-md rounded-md p-3 col-span-10 dark:bg-gray-900">
          <div className='m-auto'>
            <label
              className='justify-center text-lg font-bold text-primary text-center'
            >
              Dividend earn
            </label>
          </div>
          <component.Table
            classNameProps="mt-5 dark:bg-gray-900"
            data={dividendsFilter.length > 0 ? dividendsFilter[0].default : []}
            column={column}
          />
        </div>
      </div>
    </div>
  )
}

export default connector(Dividends)