import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AuthState, User} from '../../types';

const initialState = {
  isLoggedIn: false,
  user: {},
  isLoading: false,
  isClient: false,
  isAdmin: false,
  uuid: "",
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
    setClient: (state, action: PayloadAction<boolean>) => {
      state.isClient = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setUser: (state, action: PayloadAction<User | Record<string, unknown>>) => {
      state.user = action.payload;
    },
    setUUID: (state, action: PayloadAction<string>) => {
      state.uuid = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setAdmin,
  setClient,
  setLoading,
  setIsLoggedIn,
  setUser,
  setUUID,
} = authSlice.actions;
export default authSlice.reducer;
