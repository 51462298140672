import Dashboard from './views/home/Dashboard';
import Loan from './views/loan/Loan';
import LoanID from './views/loan/LoanID';
import Summary from './views/loan/Summary';
import Settings from './views/settings/Settings';
import Stock from './views/stock/Stock';
import StockDetails from './views/stock/StockDetails';
import SoldInfo from './views/stock/SoldInfo';
import Dividends from './views/stock/Dividends';

const navigation = [
  {
    name: 'Dashboard',
    href: '/',
    component: Dashboard,
    nav: true,
  },
  {
    name: 'Loan',
    href: '/loan',
    component: Loan,
    nav: true,
  },
  {
    name: 'Loan-Details',
    href: '/loan/:id',
    component: LoanID,
    nav: false,
  },
  {
    name: 'Loan',
    href: '/loan/summary',
    component: Summary,
    nav: false,
  },
  {
    name: 'Stock',
    href: '/stock',
    component: Stock,
    nav: true,
  },
  {
    name: 'Stock-Details',
    href: '/stock/:id',
    component: StockDetails,
    nav: false,
  },
  {
    name: 'Stock-Sold-Info',
    href: '/stock/:id/:stockid',
    component: SoldInfo,
    nav: false,
  },
  {
    name: 'Dividends',
    href: '/stock/:id/dividends',
    component: Dividends,
    nav: false,
  },
  {
    name: 'Settings',
    href: '/settings/profile',
    component: Settings,
    nav: false,
  },
  {
    name: 'Settings',
    href: '/settings/account',
    component: Settings,
    nav: false,
  },
  {
    name: 'Settings',
    href: '/settings/password',
    component: Settings,
    nav: false,
  }
];


export default navigation;
