import { useEffect, useState, useRef } from "react";

const useHover = () =>  {
    const [value, setValue] = useState(false);
    const ref: any = useRef(null);
  
    useEffect(
      () => {
        const handleMouseOut = (event: any) =>  {
          if (ref.current && !ref.current.contains(event.target)) {
            setValue(false)
          }
        }
        const handleMouseOver = (event: any) => {
          if (ref.current && ref.current.contains(event.target)) {
            setValue(true)
          }
        }
        document.addEventListener("mouseover", handleMouseOver);
        document.addEventListener("mouseout", handleMouseOut);
        return () => {
          document.removeEventListener("mouseover", handleMouseOver);
          document.removeEventListener("mouseout", handleMouseOut);
        };
      },
      [] // Recall only if ref changes
    );
    return [ref, value];
}

const useOutside = (setMenu: React.Dispatch<React.SetStateAction<boolean>>) => {
  const ref: any = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setMenu(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setMenu]);

  return [ref]
}

const homeComponents = {
  useHover,
  useOutside
}

export default homeComponents;