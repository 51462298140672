import { ChartProps } from '../../types'
import { Line } from 'react-chartjs-2';
import component from '..';

function LineChart(props: ChartProps) {
  const {
    color,
    className="",
    chartsData,
  } = props;
  
  const rgbaColor = component.useColorPalatte()

  return (
    <div className={className}>
        <Line 
            options={{
                elements: {
                    line: {
                        borderColor: rgbaColor(color)
                    }
                },
                layout: {
                    padding: 20
                },
                responsive: true,
                scales: {
                    x: {
                        display: false,
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        display: false,
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    title: {
                        display: false,
                    },
                    legend: {
                        display: false
                    },
                },
            }} 
            data={chartsData}
        />
    </div>
  )
}

export default LineChart