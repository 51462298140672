import authReducer from './reducers/authReducer';
import thunk from 'redux-thunk';
import {configureStore} from '@reduxjs/toolkit';
import alertReducer from './reducers/alertReducer';
import financeReducer from './reducers/financeReducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    alert: alertReducer,
    finance: financeReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {
//  posts: PostsState, comments: CommentsState, users: UsersState
// }
export type AppDispatch = typeof store.dispatch
