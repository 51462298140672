import { useContext } from "react";
import { ThemeContext } from "../../theme/ThemeContext";
import { ThemeContextType } from "../../types";

export const useColorPalatte = () => {
    const {theme} = useContext(ThemeContext) as ThemeContextType;
    const colorPalatte: Record<string, any> = {
        "red": {
            "light": '#ef4444',
            "dark": '#fecaca'
        },
        'blueGray': {
            'light': 'rgba(100, 116, 139, 1)',
            'dark': 'rgba(226, 232, 240, 1)',
        },
        "green": {
            'light': '#22c55e',
            'dark': '#bbf7d0',
        },
        "yellow": {
            'light': '#eab308',
            'dark': '#fef08a'
        }
      }
    
    const rgbaColor = (color: string) => colorPalatte[color][theme]

    return rgbaColor
}
