import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { api } from "../../api";

export const useGetCurrencyConversion = () => {
    const {
      mutate,
      isLoading,
      // eslint-disable-next-line
    } = useMutation<AxiosResponse<any, any>, unknown, string, unknown>(
        (name) => api.getCurrencyRate(name),
    );
  
    return {mutate, isLoading};
};