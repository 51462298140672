import api from '../default';
import {Payload} from '../../types';

const addLoan = (payload: Payload) =>
  api.post('loan/add', payload);

const getLoanDetails = () =>
  api.get('loan');

const getLoanDetailsById = (id: string) =>
  api.get(`loan/${id}`);

const deleteLoan = (id: string) =>
  api.delete(`loan/${id}`);

const changeStatus = (id: string, payload: Payload) =>
  api.patch(`loan/${id}`, payload);

const editLoan = (id: string, payload: Payload) =>
  api.put(`loan/${id}`, payload);

const loanAPI = {
    addLoan,
    getLoanDetails,
    getLoanDetailsById,
    deleteLoan,
    changeStatus,
    editLoan
}

export default loanAPI;