import { CustomModalForm } from "../../types"

function ModalCustom(props: CustomModalForm) {
  const {children, inputProps, labelProps, label, divProps, inputClassName} = props

  return (
    <div {...divProps}>
        <label
          className="block text-sm font-medium text-blueGray-700 dark:text-gray-400"
          {...labelProps}
        >
          {label}
        </label>
        <input
          className={`
          ${inputClassName ? inputClassName: ""}
          dark:text-white
          dark:bg-gray-700
          border-gray-300
          dark:border-gray-900
            mt-1
            block
            w-full
            px-3
            py-2
            shadow-sm
            sm:text-sm
            rounded-md
            focus:outline-none
            focus:ring-secondary
            focus:border-secondary
            placeholder-blueGray-500
            focus:z-10
          `}
          {...inputProps}
        />
      {children}
    </div>
  )
}

export default ModalCustom