import component from '../../../components';
import {useController} from 'react-hook-form';
import {
  Conversion,
  SharedModalProps,
  FormModalProps,
  SubmitModalProps,
  WatchModalProps,
  CustomModalForm,
  ControlModalProps
} from '../../../types';

function LoanModal(
    props: SharedModalProps & FormModalProps & SubmitModalProps & WatchModalProps & ControlModalProps
) {
  const currency: Conversion = JSON.parse(localStorage.getItem("currency")!)
  const {
    open = false,
    closeModal = () => null,
    isLoading = false,
    register,
    handleSubmit,
    errors,
    submit,
    watch,
    title = "example",
    name = "example",
    buttonName = "Add",
    minimum = 0,
    control
  } = props;

  const {field} = useController({
    defaultValue: true,
    control,
    name: 'loan-type',
  });

  const interest_rate = watch('interest-rate') 

  const loan_type = watch('loan-type') 

  const years = watch("duration")

  let min = minimum
    if (years > 0) {
      min = 0
    }

  const customForm: Array<CustomModalForm> = [
    {
      label: "Loan",
      divProps: {
        "className": "col-span-6 sm:col-span-6"
      },
      labelProps: {
        "htmlFor": "loan"
      },
      inputProps: {
        "type": "text",
        ...register('loan', {required: true})
      },
      children: errors.loan &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    },
    {
      divProps: {
        "className": "col-span-6 sm:col-span-6"
      },
      labelProps: {
        "htmlFor": "total"
      },
      inputProps: {
        type: "number",
        step: ".01",
        min: 0,
        ...register('total-amount', {
          required: true, 
          min: 0, 
          validate: (val) => {
          if (typeof watch('total-amount') === "string") {
            return !(val === '0' && watch('total-amount') === '0')
          } else if (typeof watch('total-amount') === "number") {
            return !(val === '0' && watch('total-amount') === 0)
          } else {
            return true
          }
        } })
      },
      label: `Total Amount (${currency.name})`,
      children: errors['total-amount'] &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    },
    {
      divProps: {
        "className": "col-span-6 sm:col-span-3"
      }, 
      labelProps: {
        "htmlFor": "duration"
      }, 
      inputProps: {
        type:"number",
        step: 1,
        min: 0,
        ...register('duration', {
          required: true,
          min: 0,
          validate: (val) => {
            if (typeof watch('months') === "string") {
              return !(val === '0' && watch('months') === '0')
            } else if (typeof watch('months') === "number") {
              return !(val === '0' && watch('months') === 0)
            } else {
              return true
            }
          } 
        })
      }, 
      label: "Years of borrow",
      children: <>
        {errors.duration &&
          errors.duration.type === 'required' &&
          <p className='text-sm text-red-500'>
            This field is required
          </p>
        }
        {errors.duration &&
          errors.duration.type === 'min' &&
          <p className='text-sm text-red-500'>
            Year should not be less than 0
          </p>
        }
        {errors.duration &&
          errors.duration.type === 'validate' &&
          <p className='text-sm text-red-500'>
            Year cannot be 0 when months is 0
          </p>
        }
      </>
    },
    {
      divProps: {
        "className": "col-span-6 sm:col-span-3"
      },
      labelProps: {
        "htmlFor": "months"
      },
      inputProps: {
        type: "number",
        min: min,
        step: 1,
        max: 12,
        ...register('months', {
          required: true,
          min: min,
          max: 12,
          validate: (val) => {
            if (typeof watch('duration') === "string") {
              return !(val === '0' && watch('duration') === '0')
            } else if (typeof watch('duration') === "number") {
              return !(val === '0' && watch('duration') === 0)
            } else {
              return true
            }
          }
        })
      },
      label: "Months of borrow",
      children: <>
        {errors.months &&
          errors.months.type === 'required' &&
          <p className='text-sm text-red-500'>
            This field is required
          </p>
        }
        {errors.months &&
          errors.months.type === 'max' &&
          <p className='text-sm text-red-500'>
            Month should not be more than 12
          </p>
        }
        {errors.months &&
          errors.months.type === 'min' &&
          <p className='text-sm text-red-500'>
            Month should not be less than 0
          </p>
        }
        {errors.months &&
          errors.months.type === 'validate' &&
          <p className='text-sm text-red-500'>
            Months cannot be 0 when years is 0
          </p>
        }
      </>
    },
    {
      divProps: {
        "className": "col-span-6 sm:col-span-6"
      },
      labelProps: {
        "htmlFor": "interest-rate"
      },
      inputProps: {
        type: "number",
        step: ".01",
        min: 0,
        ...register('interest-rate', {required: true})
      },
      label: "Interest rate",
      children: errors['interest-rate'] &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    },
    {
      divProps: {
        "className": "col-span-6 sm:col-span-6"
      },
      labelProps: {
        "htmlFor": "date"
      },
      inputProps: {
        type: "date",
        ...register('date', {required: true})
      },
      label: "Start date",
      children: errors.date &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    },
    {
      custom: interest_rate == 0 || interest_rate === undefined || interest_rate === "",
      divProps: {
        "className": "col-span-6 sm:col-span-6"
      },
      labelProps: {
        "htmlFor": "loan-type",
        "className": "pr-3 text-sm font-medium text-blueGray-700 dark:text-gray-400"
      },
      label: `Loan Type: ${loan_type ? "Morgage" : "Fixed"}`,
      inputProps: {
        ...field,
        type: "checkbox",
        defaultChecked: loan_type,
        className: `
          h-4 w-4
          text-secondary
          focus:ring-primary
          border-gray-300
          rounded
        `
      },
    },
  ]

  return (
    <component.Modal title={title} open={open} closeModal={closeModal}>
      <form name={name} onSubmit={handleSubmit(submit)}>
        <div className="mt-2">
          <div className="grid grid-cols-6 gap-6">
            <component.FormCustom customForm={customForm} />
          </div>
        </div>
        <component.SubmitModal 
          closeModal={closeModal}
          isLoading={isLoading}
          text={buttonName}
        />
      </form>
    </component.Modal>
  );
}

export default LoanModal;
