import React, {Fragment, useState} from 'react';
import {Disclosure, Menu, Transition} from '@headlessui/react';
import {BellIcon, MenuIcon, XIcon} from '@heroicons/react/outline';
import {Link, useLocation} from 'react-router-dom';
import logo from '../../assets/img/JK.png';
import routes from '../../routes';
import {NavProps, Props} from '../../types';
import component from '../../components';
import { LazyLoadImage } from "react-lazy-load-image-component";

function Navbar(props: Props & NavProps) {
  const location = useLocation()
  const {userLogout, user} = props;


  const [openNoti, setNotiOpen] = useState(false);
  

  function classNames(...classes: Array<string>) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <Disclosure as="nav" className="bg-gray-800 dark:bg-gray-900">
      {({open}) => (
        <>
          <div className="max-w-9xl mx-auto xsizs:px-1 px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="
                absolute
                inset-y-0
                left-0
                flex
                items-center
                md:hidden
                "
              >
                {/* Mobile menu button*/}
                <Disclosure.Button
                  className="
                    inline-flex
                    items-center
                    justify-center
                    p-2
                    rounded-md
                    text-gray-400
                    hover:text-white
                    hover:bg-gray-700
                    focus:outline-none
                    focus:ring-2
                    focus:ring-inset
                    focus:ring-white
                    "
                >
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                        <XIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                </Disclosure.Button>
              </div>
              <div className="
                flex-1
                flex
                items-center
                justify-center
                md:items-stretch
                md:justify-start
                "
              >
                <div className="flex-shrink-0 flex items-center">
                  <Link
                    to="/"
                  >
                    <LazyLoadImage src={logo}
                      visibleByDefault={true}
                      className="block md:hidden h-10 w-auto"
                      alt="Spendit"
                    />
                    <LazyLoadImage src={logo}
                      visibleByDefault={true}
                      className="hidden md:block h-10 w-auto"
                      alt="Spendit"
                    />
                  </Link>
                  <span className='
                    ml-1
                    mt-3
                    text-gray-300
                    text-xl
                    font-bold
                    font-serif
                    '
                  >
                    pendit
                  </span>
                </div>
                <div className="hidden md:block sm:ml-6">
                  <div className="flex space-x-4">
                    {
                      routes.map((item) => {
                        if (!item.nav) {
                          return (
                            <React.Fragment key={item.href}></React.Fragment>
                          )
                        }
                        const path = location.pathname.split("/")[1]
                        const itemPath = item.href.split("/")[1]
                        return (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={
                              classNames(path === itemPath ?
                                `bg-gray-900
                                text-white
                                dark:bg-gray-400
                                dark:text-blueGray-800`
                                :
                                `text-gray-300
                                hover:bg-gray-700
                                hover:text-white
                                dark:text-gray-400
                                dark:hover:text-white
                                dark:hover:bg-gray-700`,
                              'px-3 py-2 rounded-md text-sm font-medium',
                              )}
                            aria-current={
                              path === itemPath  ?
                              'page' : undefined
                            }
                          >
                            {item.name}
                          </Link>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div className="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                md:static
                md:inset-auto
                md:ml-6
                md:pr-0
                "
              >
                {/* <ThemeToggle {...props} /> */}
                <button
                  type="button"
                  className="
                    bg-gray-800
                    p-1
                    rounded-full
                    text-gray-400
                    hover:text-white
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-offset-gray-800
                    focus:ring-white
                  "
                  onClick={() => setNotiOpen(!openNoti)}
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <component.Slider open={openNoti} setOpen={setNotiOpen} />

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative z-50">
                  <div>
                    <Menu.Button
                      className="
                        bg-gray-800
                        flex
                        text-sm
                        rounded-full
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-offset-gray-800
                        focus:ring-white
                        "
                    >
                      <span className="sr-only">Open user menu</span>
                      <component.UserIcon classNameProps='w-8 h-8 text-md'>
                        {
                          Object
                              .prototype
                              .hasOwnProperty.call(
                                  user,
                                  'firstname',
                              ) &&
                          user.firstname.charAt(0)
                        }
                        {
                          Object
                              .prototype
                              .hasOwnProperty.call(
                                  user,
                                  'lastname',
                              ) &&
                          user.lastname.charAt(0)
                        }
                      </component.UserIcon>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className="
                        origin-top-right
                        absolute
                        right-0
                        mt-2
                        w-48
                        rounded-md
                        shadow-lg
                        py-1
                        bg-white
                        dark:bg-gray-700  
                        ring-1
                        ring-black
                        ring-opacity-5
                        focus:outline-none
                        "
                    >
                      
                      <Menu.Item>
                        {({active}) => (
                          <Link
                            to="/settings/profile"
                            className={
                              classNames(active ?
                                'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700 dark:text-white dark:hover:text-gray-900',
                              )
                            }
                          >
                                Settings
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({active}) => (
                          <Link
                            to="#"
                            onClick={userLogout}
                            className={
                              classNames(active ?
                                'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700 dark:text-white dark:hover:text-gray-900',
                              )
                            }
                          >
                            Sign out
                          </Link>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <Transition
            enter="transition-all duration-1000 ease-in-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition-all duration-400 ease-in-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {routes.map((item) => {
                  if (!item.nav) {
                    return (
                      <React.Fragment key={item.href}></React.Fragment>
                    )
                  }
                  const path = location.pathname.split("/")[1]
                  const itemPath = item.href.split("/")[1]
                  return (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      to={item.href}
                      className={
                        classNames(path === itemPath ?
                          'bg-gray-900 text-white' :
                          'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium',
                        )}
                      aria-current={
                        path === itemPath ?
                        'page' : undefined
                      }
                    >
                      {item.name}
                    </Disclosure.Button>
                    )
                })}
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}

export default Navbar;
