import React, {Suspense} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import NotFound from '../views/error/NotFound';
import NoPermission from '../views/error/NoPermission';
import routes from '../routes';
import component from '../components';
import {connector, Props} from '../types';
import { useUserLogout } from './components/mutateLayout';

function Layout(props: Props) {
  const {setUser, setIsLoggedIn} = props
  const location = useLocation();

  const navigate = useNavigate();
  const {mutate, isLoading} = useUserLogout();

  const userLogout = () => {
    mutate('', {
      onSuccess: () => {
        setUser({});
        setIsLoggedIn(false);
        navigate('/');
      },
    });
  };

  const createElement = (ele: React.ReactElement) => {
    if (isLoading) {
      return <component.Loading />
    }
    return (
      <div>
        <component.Navbar {...props} userLogout={userLogout}/>
        {ele}
      </div>
    );
  };

  // eslint-disable-next-line
  const routing = (prop: Record<string, any>, key: number) => {
    return (
      <Route
        path={prop.href}
        element={createElement(React.createElement(prop.component))}
        key={key}
      />
    );
  };

  // eslint-disable-next-line
  const getRoutes = (route: Array<Record<string, any>>) => {
    return route.map((prop, key) => routing(prop, key));
  };

  return (
    <Suspense fallback={<component.Loading />}>
      <Routes>
        {getRoutes(routes)}
        {routes.filter((x) => x.href === location.pathname).length === 0 ?
          <Route path="*" element={<NotFound />} /> :
          <Route path="*" element={<NoPermission />} />
        }
      </Routes>
    </Suspense>
  );
}

export default connector(Layout);
