import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { api } from "../../api";
import { Payload } from "../../types";

export const useSearchTicker = () => {
  const {
    mutate,
    isLoading,
    // eslint-disable-next-line
  } = useMutation<AxiosResponse<any, any>, unknown, string, unknown>(
    (payload) => api.getTicker(payload),
  );

  return {mutate, isLoading};
};

export const useAddStock = () => {
  const {
    mutate,
    isLoading
  } = useMutation<AxiosResponse<any, any>, AxiosError, Payload, unknown>(
    (payload) => api.addStock(payload)
  );
  
  return {mutate, isLoading};
}

export const useDeleteStock = () => {
  const {
    mutate,
    isLoading
  } = useMutation<AxiosResponse<any, any>, AxiosError, Payload, unknown>(
    (payload) => api.deleteStock(payload)
  );
  
  return {mutate, isLoading};
}

export const useAddAdditionalStock = () => {
  const {
    mutate,
    isLoading
  } = useMutation<AxiosResponse<any, any>, AxiosError, Array<any>, unknown>(
    (payload) => api.addAdditionalStock(payload[0], payload[1])
  );
  
  return {mutate, isLoading};
}

export const useSellStock = () => {
  const {
    mutate,
    isLoading
  } = useMutation<AxiosResponse<any, any>, AxiosError, Array<any>, unknown>(
    (payload) => api.sellStock(payload[0], payload[1])
  );
  
  return {mutate, isLoading};
}

export const useDeleteAdditionalStock = () => {
  const {
    mutate,
    isLoading
  } = useMutation<AxiosResponse<any, any>, AxiosError, Array<any>, unknown>(
    (payload) => api.deleteAdditionalStock(payload[0], payload[1])
  );
  
  return {mutate, isLoading};
}