import { useQuery } from "react-query";
import { api } from "../../api";
import { DataRender, DividendsPayload, StockData, User } from "../../types";
import moment from "moment";
import currency from "currency.js";

const useGetStock = (user?: User) => {
    const {
      data,
      isLoading,
      isFetching,
      isError,
      error,
      isSuccess,
      refetch,
    } = useQuery(
        [
          'getStockDetails',
          user?.id,
        ],
        async () => {
          const {data: stockDetails} = await api.getStockDetails();
          if (stockDetails.success) {

            const d: Array<StockData> = stockDetails.data
            
            return d
          }
          return []
        });
  
    const d: Array<StockData> = data!;
  
    return {data: d, isLoading, isFetching, isError, error, isSuccess, refetch};
}

const useGetAllDividends = (stockData: Array<StockData>) => {
  const {
    data,
    isLoading,
    isFetching,
    isError,
    error,
    isSuccess,
    refetch,
  } = useQuery(["getAllDividends", stockData], async () => {
    if (stockData) {
      const dividenData = Promise.all(stockData.map(async (x) => {
        const {data: getDividendDetails} = await api.getDividendDetails(x.ticker);
        if (getDividendDetails.success) {
          let d = x.data
          const dvdData = JSON.parse(getDividendDetails.data)
          
          const n = dvdData.data.map((row: Record<string, any>) => {
            const divident_entitle = d.map(z => {
              if (z.sold!.length === 0) {
                if (moment(z.date).isBefore(row.Date)) {
                  return {...z, dividend: currency(z.shares, {precision: 2}).multiply(row.Dividends).value}
                } else {
                  return {...z, dividend: 0}
                }
              } else {
                const f = z.sold!.map(e => {
                  if (moment(row.Date).isBetween(e.date, z.date)) {
                    return {...e, dividend: {[row.Date]: false} }
                  } else {
                    return {...e, dividend: {[row.Date]: true}}
                  }
                })

                const value = f.map((g) => g.dividend[row.Date] ? g.shares: 0).reduce((a, b) => a + b, 0)

                return {...z, dividend: currency(z.shares, {precision: 2}).subtract(value).multiply(row.Dividends).value}
              }
            })
            const val = divident_entitle.map(z => z.dividend).reduce((x, y) => x + y, 0) ?? 0
            return val
          });
          const dividend = n.reduce((a: number, b: number) => currency(a, {precision: 2}).add(b).value,  0) ?? 0
          return dividend
        } else {
          return 0
        }
      }));
      const stockDividenDatas = await dividenData;
      return stockDividenDatas
    } else{
      return undefined
    }
  })

  return {data, isLoading, isFetching, isError, error, isSuccess, refetch};
}

const useGetDividends = (ticker: string | undefined) => {
  const {
    data,
    isLoading,
    isFetching,
    isError,
    error,
    isSuccess,
    refetch,
  } = useQuery(["getDividendDetails", ticker], async () => {
    if (ticker) {
      const {data: getDividendDetails} = await api.getDividendDetails(ticker);

      if (getDividendDetails) {
        const dividends: DividendsPayload = getDividendDetails
        if (dividends.success) {

          const dvdData = JSON.parse(getDividendDetails.data)

          return dvdData
        }
      }
      return undefined
    } else{
      return undefined
    }
  })

  return {data, isLoading, isFetching, isError, error, isSuccess, refetch};
}

const useGetStockHistory = (user?: User) => {
  const {
    data,
    isLoading,
    isFetching,
    isError,
    error,
    isSuccess,
    refetch,
  } = useQuery(
      [
        'getStockHistory',
        user?.id,
      ],
      async () => {
        const { data } = await api.getStockHistory()
        if (data.success) {
          const d: Array<DataRender> = data.data
          return d
        }
        return []
      });
      
  return {data, isLoading, isFetching, isError, error, isSuccess, refetch};
}


const stockQuery = {
    useGetStock,
    useGetAllDividends,
    useGetDividends,
    useGetStockHistory
}

export default stockQuery;

  