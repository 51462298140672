import component from "../../../components";
import { 
    CustomModalForm,
    FormModalProps,
    SharedModalProps, 
    Stock, 
    SubmitModalProps, 
    WatchModalProps 
} from "../../../types";

function AddAditionalModal(
    props: 
        SharedModalProps & 
        SubmitModalProps & 
        WatchModalProps & 
        FormModalProps
) {
    const {
        open = false,
        closeModal = () => null,
        isLoading = false,
        register,
        handleSubmit,
        errors,
        submit,
        watch,
        stock,
        clicks = () => null,
        status = false,
        deleteSubmit,
        deleteLoading,
    } = props;
  
  const sell = watch('sell');
  const id = watch('id');
  const sell_id = watch('sell_id');

  const find = stock?.data.find((x: Stock) => x.id === sell_id)

  const customForm: Array<CustomModalForm> = [
    {
      label: "Cost/Share",
      divProps: {
        className: "col-span-6 sm:col-span-6"
      },
      labelProps: {
          htmlFor: "cost"
      },
      inputProps: {
          type: "number",
          step: ".000001",
          ...register('cost', {required: true, min: 0}),
      },
      children: errors.cost &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    },
    {
      label: "Shares",
      divProps: {
        className: "col-span-6 sm:col-span-6"
      },
      labelProps: {
          htmlFor: "shares"
      },
      inputProps: {
          type: "number",
          min: "0",
          step: ".00001",
          ...register('shares', {required: true, min: 0, max: find?.shares})
      },
      children: errors.shares && errors.shares.type === "max" ?
      <p className='text-sm text-red-500'>
          Shares should be lower than {find?.shares}
      </p>
      : errors.shares &&
      <p className='text-sm text-red-500'>
          This field is required
      </p>
    },
    {
      label: "Trade Date",
      divProps: {
        className: "col-span-6 sm:col-span-6"
      },
      labelProps: {
          htmlFor: "date"
      },
      inputProps: {
          type: "date",
          ...register('date', {required: true}),
      },
      children: errors.date &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    }
  ]

  return (
    <component.Modal title={stock?.ticker} open={open} closeModal={closeModal}>
        <div className="mt-3">
            <form name="add_subtract_stock" onSubmit={handleSubmit(submit)}>
                <div className="mt-6">
                    <div className="grid grid-cols-6 gap-6">
                        <component.FormCustom customForm={customForm} />
                    </div>
                </div>
                <component.SubmitModal 
                    closeModal={clicks}
                    isLoading={isLoading}
                    text={status ? "Edit" : "Add"}
                    disabled={sell}
                >
                    {(id !== 1 && status) &&
                        <button
                            type={"button"}
                            onClick={() => deleteSubmit!({id: id})}
                            className={`
                                ${deleteLoading? "cursor-progress" : ""}
                                inline-flex
                                align-middle 
                                justify-center
                                px-4
                                py-2
                                ${deleteLoading? "pt-[9px]" : ""}
                                text-sm
                                font-medium
                                text-yellow-900
                                bg-yellow-100
                                border
                                border-transparent
                                rounded-md
                                hover:bg-yellow-200
                                focus:outline-none
                                focus-visible:ring-2
                                focus-visible:ring-offset-2
                                focus-visible:ring-yellow-500
                            `}
                        >
                            <component.ButtonLoader isLoading={deleteLoading} classNameProps="text-inherit" />
                            <div className={`${deleteLoading && 'mt-1'} `}>Delete</div>
                        </button>
                    }
                </component.SubmitModal>
            </form>
        </div>
    </component.Modal>
  )
}

export default AddAditionalModal