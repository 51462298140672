import { useMemo } from "react"
import { DataRender, LoanData, LoanDetailsIDPayload } from "../../types"
import currency from "currency.js"
import functions from "../../function"
import { emptyLoanData } from "../../variables/loan/loan_var"

const useGetLoanSnap = (data: Array<LoanDetailsIDPayload>) => {
    const d: any = useMemo(() => {
        if (data && data.length > 0) {
          const newD = data.map(z => {
            const loanData = z.data
            const paid = loanData.data.filter(g => g.status === "paid")

            const labels = paid.map(g => g.date)

            const paid_data = paid.map(g => currency(g.paid_amount, {precision: 2}).value)

            const datasets = {
                labels,
                datasets: [
                    {
                    data: paid_data,
                    pointRadius: 0,
                    tension: 0.6
                    },
                ],
              };

              const payment_made = paid_data.reduce((z, y) => currency(z!, {precision: 2}).add(y!).value, 0)?? 0
              const amount_owe = currency(loanData.total_with_interest, {precision: 2}).subtract(payment_made).value
  
              const val = amount_owe
      
              return {
                ...z,
                ...loanData,
                name: loanData.name,
                value: functions.currencyConversion(val, true),
                chartData: datasets
              }
          })

          return newD
        } 
        return []
    }, [data])

    return d
}

const useGetLoanSnapById = (data: LoanDetailsIDPayload) => {
  const d = useMemo(() => {
    if (data && data.data) {
      const loanData = data.data

      const paid = loanData.data.filter(z => z.status === "paid")

      const labels = paid.map(z => z.date)
    
      const paid_data = paid.map(z => currency(z.paid_amount, {precision: 2}).value)

      const datasets = {
        labels,
        datasets: [
            {
            data: paid_data,
            pointRadius: 0,
            tension: 0.6
            },
        ],
      };

      const payment_made = paid_data.reduce((z, y) => currency(z!, {precision: 2}).add(y!).value, 0)?? 0
      const amount_owe = currency(loanData.total_with_interest, {precision: 2}).subtract(payment_made).value

      const val = amount_owe

      return {
        ...loanData,
        name: loanData.name,
        value: functions.currencyConversion(val, true),
        chartData: datasets
      }
    }

    return {
      name: 'loaner',
      value: 0,
      chartData: {
        labels: [],
        datasets: [
            {
              data: [],
              pointRadius: 0,
              tension: 0.6
            },
        ],
      }
    }
  }, [data])

  return d
}

const useLoanGetSearch = (combo: Array<LoanDetailsIDPayload>, filter: string) => {
    const search = useMemo(() => {
        if (combo) {
          if (filter === "") {
            return combo
          } else {
            const filters = combo.filter(x => {
              const searchData = x.data.data.filter(y => y.date.toLowerCase().includes(filter.toLowerCase()) || y.status.toLowerCase().includes(filter.toLowerCase()))
              return x.data.name.toLowerCase().includes(filter.toLowerCase()) || x.data.created_at.toLowerCase().includes(filter.toLowerCase()) || searchData.length > 0
            })
            
            return filters
          }
        } 
        return []
    
    }, [combo, filter])

    return search
}

const useLoanFilterData = (data: DataRender, id: string | undefined) => {
  const filter_data: LoanData = useMemo(() => {
    if (data) {
        const filters = data.find((x: LoanData)=> x.id === id)

        if (filters) {
            return filters
        }

    }
    return emptyLoanData
  }, [data, id])

  return filter_data
}

const loanMemo = {
  useGetLoanSnap,
  useLoanGetSearch,
  useLoanFilterData,
  useGetLoanSnapById
}

export default loanMemo;