import {api} from '../../api';
import {useMutation} from 'react-query';
import {AxiosResponse} from 'axios';

export const useUserLogout = () => {
  const {
    mutate,
    isLoading,
    // eslint-disable-next-line
  } = useMutation<AxiosResponse<any, any>, unknown, string, unknown>(
      () => api.logout(),
  );

  return {mutate, isLoading};
};