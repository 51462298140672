import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useNavigate} from 'react-router-dom';
import {useGetLoan} from './queryLoan';
import {connector, DataRender, FixedSizeArray, Props, SnapRender} from '../../types';
import hook from '../../hooks';
import method from '../../method';
import component from '../../components';

function Loan(props: Props) {

  const [addLoan, setAddLoan] = useState(false)
  const [filter, setFilter] = useState("")

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: {
      errors,
    },
    reset,
    watch: addWatch,
    control
  } = useForm();

  const addModal = () => {
    setAddLoan(!addLoan)
    reset()
  }

  const {
    data: combo,
    isLoading: dataLoading,
    refetch,
    isFetching
  } = useGetLoan(props.user); 

  const {submit, isLoading} = method.useLoanSubmitHandler(addModal, refetch)

  const data = hook.useLoanGetSearch(combo, filter)

  const customSnap = hook.useGetLoanSnap(data)

  const columnSnap: FixedSizeArray<SnapRender, 3> = [
    {
      "type": "title",
      "dataIndex": "name"
    },
    {
      "type": "data",
      "dataIndex": "value"
    },
    {
      type: "data",
      dataIndex: 'value',
      render: (_, row: DataRender) => {
        let keys = "nothing"
        if (row.color === "red") {
          keys = "negative"
        } else if (row.color === "green") {
          keys = "positive"
        }
        return (
          <component.EarnLoss key={keys} keys={keys} />
        ) 
      }
    }
  ]

  const clicks = (row: DataRender) => {
    navigate(row.id)
  }

  if (dataLoading || isFetching) {
    return <component.Loading />;
  }

  return (
    <div className='p-4'>
      <div
        className={
          `flex flex-wrap ${data.length > 0 ?
          'mb-5 justify-between items-end align-middle':
          'justify-between items-center align-middle'}`
        }
      >
        {data && data.length > 0 &&
          <div className={`font-semibold text-lg text-black dark:text-white`}>
            Select your loan
          </div>
        }
        <div className='space-x-3 flex flex-wrap'>
          <component.Button 
            onClick={addModal}
            type="button"
            className={`
              bg-green-300
              hover:bg-primary
              hover:text-white
              dark:bg-secondary
              dark:hover:bg-primary
              dark:hover:text-gray-200
            `}
            children="Add Loan"
          />
          {data && data.length > 0 &&
            <Link
              to="summary"
              className='
                rounded-md
                shadow-md
                p-3
                text-sm
                bg-orange-300
                hover:bg-orange-500
                hover:text-white
                dark:bg-orange-800
                dark:hover:bg-orange-500
                font-medium
                dark:text-gray-200
              '
            >
              Summary
            </Link>
          }
        </div>
        <div className='flex justify-end buttonmax:w-full buttonmax:pt-5'>
            <input 
              className='rounded-xl w-full dark:bg-blueGray-800 dark:text-gray-100'
              type="search"
              placeholder='Search'
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
        </div>
      </div>
      <component.Snap 
        customSnap={customSnap}
        shortenColumn={columnSnap}
        chartsLoading={dataLoading}
        click={clicks}
      />
      <component.LoanModal
        open={addLoan}
        closeModal={addModal}
        register={register}
        submit={submit}
        handleSubmit={handleSubmit}
        errors={errors}
        isLoading={isLoading}
        watch={addWatch}
        title="Add a loan"
        name="add_loan"
        buttonName="Add"
        control={control}
      />
    </div>
  )
}

export default connector(Loan)