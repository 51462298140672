import homeComponents from './components/useHooks'
import loanMemo from './loan/useLoanMemo'
import stockMemo from './stock/useStockMemo'
import stockQuery from './stock/useStockQuery'

const hook = {
    ...homeComponents,
    ...loanMemo,
    ...stockMemo,
    ...stockQuery
}

export default hook;