import { useQuery } from "react-query";
import { api } from "../../api";
import { User } from "../../types";

export const useGetNotifications = (user?: User) => {
    const {
      data,
      isLoading,
      isFetching,
      isError,
      error,
      isSuccess,
      refetch,
    } = useQuery(
        [
          'getNotifcation',
          user?.id,
        ],
        async () => {
          const { data } = await api.getNotifications();
          if (data.success) {
            return data.data;
          }
          return []
        });

    return {data, isLoading, isFetching, isError, error, isSuccess, refetch};
  }