import {api} from '../../api';
import {useMutation} from 'react-query';
import {AxiosResponse} from 'axios';
import {LoanData, Payload, RenderData} from '../../types';
import { useMemo } from 'react';
import moment from 'moment';
import { groupBy } from 'lodash';

export const useAddLoan = () => {
  const {
    mutate,
    isLoading,
    // eslint-disable-next-line
  } = useMutation<AxiosResponse<any, any>, unknown, Payload, unknown>(
      (payload) => api.addLoan(payload),
  );

  return {mutate, isLoading};
};

export const useDeleteLoan = () => {
  const {
    mutate,
    isLoading,
    // eslint-disable-next-line
  } = useMutation<AxiosResponse<any, any>, unknown, string, unknown>(
      (payload) => api.deleteLoan(payload),
  );

  return {mutate, isLoading};
};

export const useChangeStatus = () => {
  const {
    mutate,
    isLoading,
    // eslint-disable-next-line
  } = useMutation<AxiosResponse<any, any>, unknown, Array<any>, unknown>(
      (payload) => api.changeStatus(payload[0], payload[1]),
  );

  return {mutate, isLoading};
};

export const useEditLoan = () => {
  const {
    mutate,
    isLoading,
    // eslint-disable-next-line
  } = useMutation<AxiosResponse<any, any>, unknown, Array<any>, unknown>(
      (payload) => api.editLoan(payload[0], payload[1]),
  );

  return {mutate, isLoading};
};


export const useMemoLoan = (data: any) => {
  const processData = useMemo(() => {
    if (data) {

      const allPayment: Array<RenderData> = [];
    
      data.forEach((x: any) => {
        const loanData = x.data
        const withNaming = loanData.data.map((z: any) => ({
          ...z,
          table_id: loanData.id,
          years: loanData.years,
          months: loanData.months,
          month: moment(z.date).format('MMMM'),
          year: moment(z.date).format('YYYY'),
        }));

        withNaming.forEach((element: any) => {
          allPayment.push(element)
        });
      });
      const yearGroup = groupBy(allPayment, (x) => x.year);

      let monthGroup = {}
      
      Object.keys(yearGroup).forEach(z => {
        monthGroup = {
          ...monthGroup,
          [z]: groupBy(yearGroup[z], (e) => e.month)
        }
      })

      const years = Object.keys(monthGroup).map(x => ({name: x, value: x}))
      
      return {
        data: monthGroup,
        years: years,
      }
    } else {
      return {
        data: {},
        years: [],
      }
    }

  }, [data])

  return processData
}

export const useMemoLoanIDDetails = (data: any) => {
  const processData = useMemo(() => {
    if (data) {
      const loanData: LoanData = data.data

      const allPayment: Array<RenderData> = [];

      const withNaming = loanData.data.map((z) => ({
        ...z,
        table_id: loanData.id,
        years: loanData.years,
        months: loanData.months,
        month: moment(z.date).format('MMMM'),
        year: moment(z.date).format('YYYY'),
      }));

      withNaming.forEach((element: any) => {
        allPayment.push(element)
      });

      const yearGroup = groupBy(allPayment, (x) => x.year);

      let monthGroup = {}
      
      Object.keys(yearGroup).forEach(z => {
        monthGroup = {
          ...monthGroup,
          [z]: groupBy(yearGroup[z], (e) => e.month)
        }
      })

      const years = Object.keys(monthGroup).map(x => ({name: x, value: x}))
      
      return {
        data: monthGroup,
        years: years,
      }
    } else {
      return {
        data: {},
        years: [],
      }
    }

  }, [data])

  return processData
}