import color from './component/color';
import stockCalculate from './stock/calculation';
import conversion from './currency/conversion';

const functions = {
    ...color,
    ...stockCalculate,
    ...conversion
}

export default functions