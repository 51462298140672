import {Payload} from '../../types';
import api from '../default';

const addStock = (payload: Payload) => 
  api.post("stock/add", payload)

const deleteStock = (payload: Payload) => 
  api.post("stock/delete", payload)

const getStockDetails = () => 
  api.get("stock")

const getStockHistory = () => 
  api.get('stocks/history')

const addAdditionalStock =(id: string, payload: Payload) => 
  api.post(`stock/${id}/add`, payload)

const sellStock =(id: string, payload: Payload) => 
  api.post(`stock/${id}/sell`, payload)

const deleteAdditionalStock =(id: string, payload: Payload) => 
  api.put(`stock/${id}/delete`, payload)

const getTicker = (search: string) => 
  api.get(`ticker/${search}`)

const getDividendDetails = (ticker: string) => 
  api.get(`dividends/${ticker}`)

const financeApi = {
    addStock,
    deleteStock,
    getStockDetails,
    getStockHistory,
    addAdditionalStock,
    sellStock,
    deleteAdditionalStock,
    getTicker,
    getDividendDetails
}

export default financeApi;