import { FieldValues, SubmitHandler } from "react-hook-form";
import { encoding } from "../../encryption/encryption";
import { useAddLoan } from "../../views/loan/mutateLoan";
import { RefetchQuery, clicker } from "../../types";
import functions from "../../function";

const useLoanSubmitHandler = (addModal: clicker, refetch: RefetchQuery) => {
    const {mutate, isLoading} = useAddLoan();
    const submit: SubmitHandler<FieldValues> = (e) => {
      const amount = parseFloat(e["total-amount"])
      const userInfo = encoding({
        ...e,
        "total-amount": `${functions.currencyDefault(amount)}`,
        exp: Math.round(Date.now() / 1000) + (60 * 10), // 10 min expiration
      });
  
      const payload = {
        userInformation: userInfo,
      };
  
      mutate(payload, {
        onSuccess: ({data: res}) => {
          if (res.success) {
            addModal();
            refetch();
          } else {
            console.log(res);
          }
        },
      });
    };

    return {submit, isLoading}
}

const loanMethod = {
  useLoanSubmitHandler
}

export default loanMethod;