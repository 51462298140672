import {FieldValues, SubmitHandler, useForm} from 'react-hook-form';
import {CustomModalForm, Props} from '../../../types';
import component from '../../../components';

function Profile(props: Props) {
  const {register, handleSubmit, formState: {errors}, reset} = useForm();

  const isLoading = false

  const updateUserInformation: SubmitHandler<FieldValues> = (data) => {
    console.log(data)
    console.log("update user information")
    reset()
  }

  const {user} = props;

  const random = ["r", "b", "t", "l"]

  const randomDir = (n = 4) => {
    return Math.floor(Math.random() * n)
  }

  const customForm: Array<CustomModalForm> = [
    {
      divProps: {
        className: "col-span-6 sm:col-span-3"
      },
      labelProps: {
        htmlFor: "first-name"
      },
      label: "First Name",
      inputProps: {
        type: "text",
        defaultValue: user.firstname,
        ...register('firstName', {required: true}),
        autoComplete: "given-name",
        placeholder: "Your Given Name"
      },
      children: errors.firstName &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    },
    {
      divProps: {
        className: "col-span-6 sm:col-span-3"
      },
      labelProps: {
        htmlFor: "last-name"
      },
      label: "Last name",
      inputProps: {
        type: "text",
        defaultValue: user.lastname,
        ...register('lastName', {required: true}),
        autoComplete: "family-name",
        placeholder: "Your family name"
      },
      children: errors.lastName &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    },
    {
      divProps: {
        className: "col-span-6"
      },
      labelProps: {
        htmlFor: "email-address"
      },
      label: "Email address",
      inputClassName: `emailAddress ${user.type === "google" ? "cursor-not-allowed" : "cursor-text"}`,
      inputProps: {
        type: "email",
        defaultValue: user.email,
        ...register('email', {
          pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
          required: !(user.type === "google"),
        }),
        disabled: user.type === "google",
        autoComplete: "email",
        placeholder: "Welcome...."
      },
      children: <>
        {errors.email &&
          errors.email.type === 'pattern' &&
            <p className='text-sm text-red-500'>
              Invalid email
            </p>
        }
        {errors.email &&
          errors.email.type === 'required' &&
            <p className='text-sm text-red-500'>
              This field is required
            </p>
        }
      </>
    }
  ]
 
  return (
    <div className='px-10 py-16'>
      <form id="updateUserInfo" onSubmit={handleSubmit(updateUserInformation)}>
        <div className={`
          py-10
          flex-1
          flex
          items-center
          justify-center
          bg-slate-200
          rounded-xl
          mb-10
          bg-gradient-to-r from-[#24C6DC] to-[#514A9D]
          dark:from-blue-700 dark:to-red-600
          dark:hover:from-orange-400 dark:hover:to-indigo-700
          hover:bg-gradient-to-${random[randomDir()]} hover:from-[#3D7EAA] hover:to-[#FFE47A]
          `}
        >
          <component.UserIcon classNameProps='w-16 h-16 text-xl'>
            {
              Object
                  .prototype
                  .hasOwnProperty.call(
                      user,
                      'firstname',
                  ) &&
              user.firstname.charAt(0)
            }
            {
              Object
                  .prototype
                  .hasOwnProperty.call(
                      user,
                      'lastname',
                  ) &&
              user.lastname.charAt(0)
            }
          </component.UserIcon>
        </div>
        <div className="px-4 py-5 bg-white sm:p-6 mt-10 rounded-xl dark:bg-gray-900">
          <div className="grid grid-cols-6 gap-6">
            <component.FormCustom customForm={customForm} />
          </div>
          <div className='pt-7'>
          <button
            type="submit"
            className="
              inline-flex
              align-center
              justify-center
              px-4
              py-2
              text-sm
              font-medium
              text-blue-900
              bg-blue-100
              border
              border-transparent
              rounded-md
              hover:bg-blue-200
              focus:outline-none
              focus-visible:ring-2
              focus-visible:ring-offset-2
              focus-visible:ring-blue-500
              "
            >
              <component.ButtonLoader isLoading={isLoading} classNameProps="text-inherit" />
              <div className={`${isLoading && 'mt-1'} `}>Update</div>
            </button> 
          </div>
        </div>
      </form>
    </div>
  );
}

export default Profile;
