import {useEffect, useMemo, useState} from 'react';
import ReactPaginate from 'react-paginate';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/solid';
import {BasicProps} from '../../types';
import component from '../../components';

const initialState = {
  offset: 0,
  data: [],
  perPage: 10,
  currentPage: 0,
  pageCount: 0,
};

function Table(props: BasicProps) {
  const {data = [], column = [], loading = false, classNameProps, id, click = undefined} = props;
  const [pagi, setPagi] = useState(initialState);

  useEffect(() => {
    const selectedPage = 0;
    const offset = selectedPage * pagi.perPage;

    setPagi((page) => ({...page, currentPage: selectedPage, offset: offset}));
  }, [id, pagi.perPage]);

  const newData = useMemo(() => {
    if (data.length > 0) {
      const slice = data.slice(pagi.offset, pagi.offset + pagi.perPage);

      setPagi((page) =>
        ({...page, pageCount: Math.ceil(data.length / pagi.perPage)}),
      );

      return slice;
    } else {
      return data;
    }
  }, [data, pagi.perPage, pagi.offset]);

  const handlePageClick = (e: Record<'selected', number>) => {
    const selectedPage = e.selected;
    const offset = selectedPage * pagi.perPage;

    setPagi((page) => ({...page, currentPage: selectedPage, offset: offset}));
  };

  return (
    <div className={`flex flex-col ${classNameProps}`}>
      {loading &&
        <component.LoadingOverlay/>
      }
      <div
        className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
      <div
        className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          className={`
            shadow
            overflow-hidden
            rounded-lg
            `}
        >
          {newData.length === 0 ?
            <div className='p-5 animate-pulse align-middle py-56 justify-center flex min-w-full items-center dark:text-blueGray-400 text-blueGray-800'>No result found...</div>
            :
            <table className='min-w-full' >
              <thead className='bg-white shadow-md mb-3 dark:bg-gray-900'>
                <tr>
                  {column.length > 0 && column.map((x, i)=> (
                    <th
                      key={i}
                      scope="col"
                      className="
                        py-4
                        text-center
                        text-xs
                        font-medium
                        text-secondary
                        uppercase
                        tracking-wider
                      "
                    >
                      {x.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-900">
                {newData.length > 0 &&
                  newData[0].id !== 0 &&
                    newData.map((x, i) => {
                      return (
                        <tr key={i} className='hover:bg-slate-50 dark:hover:bg-gray-700 dark:hover:text-white dark:text-gray-400'>
                          {column.map((y, z) => (
                              <td
                                key={z}
                                className={`
                                  ${click? "cursor-pointer": ""}
                                  p-1
                                  py-4
                                  whitespace-break
                                  `}
                                onClick={() => click && click!(x)}
                              >
                                {y.render ?
                                  y.render(x[y.dataIndex], x) :
                                  <div
                                    className="
                                      text-center
                                      text-sm
                                    "
                                  >
                                    {x[y.dataIndex]}
                                  </div>
                                }
                              </td>
                            ))
                          }
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          }
        </div>
      </div>
    </div>
      {newData.length > 0 &&
        <ReactPaginate
          previousLabel={
            <ChevronLeftIcon
              className='text-secondary w-5 h-5 align-baseline dark:text-white'
            />
          }
          nextLabel={
            <ChevronRightIcon
              className='text-secondary w-5 h-5 align-baseline dark:text-white'
            />
          }
          breakLabel={'...'}
          pageCount={pagi.pageCount}
          forcePage={pagi.currentPage}
          onPageChange={handlePageClick}
          className='
            flex
            relative
            col-span-12
            flex-wrap
            sm:flex-nowrap
            justify-between
            md:justify-center
            items-center
            align-baseline
            border-transparent
            transform
            translate-x-0
            ease-in-out
            dark:bg-gray-900
            rounded-b-xl
            pb-5
            '
          pageClassName="
            hidden
            md:flex
            align-baseline
            bg-white
            dark:bg-gray-700
            dark:hover:bg-gray-800
            hover:bg-slate-50
            dark:border-gray-900
            dark:text-white
            text-secondary
            relative
            mt-10
            mr-2
            inline-flex
            items-center
            border
            text-sm
            font-medium
            rounded-xl
          "
          activeLinkClassName='
            hidden
            md:flex
            cursor-not-allowed
            align-baseline
            dark:bg-gray-800
            dark:border-gray-800
            dark:text-white
            bg-slate-50
            text-secondary
            relative
            inline-flex
            items-center
            border
            text-sm
            font-medium
            rounded-xl
          '
          pageLinkClassName='
            hidden
            md:flex
            px-4
            py-2
          '
          previousLinkClassName={`
            ${pagi.currentPage === 0 && 'hidden'} 
            align-baseline
            mt-10 
            bg-white
            rounded-xl
            text-secondary
            relative
            inline-flex
            dark:bg-gray-700
            dark:hover:bg-gray-800
            dark:border-gray-900
            hover:bg-slate-50
            dark:text-white
            items-center
            px-4
            py-2
            border
            mr-2
            ml-2
            text-sm
            font-medium
          `}
          nextLinkClassName={`
            ${pagi.currentPage === pagi.pageCount - 1 && 'hidden'}
            align-baseline
            mt-10
            bg-white
            rounded-xl
            text-secondary
            relative
            inline-flex
            items-center
            dark:bg-gray-700
            dark:hover:bg-gray-800
            dark:border-gray-900
            hover:bg-slate-50
            dark:text-white
            px-4
            py-2
            mr-2
            ml-2
            border
            text-sm
            font-medium
          `}
          breakLinkClassName="
            hidden
            md:flex
            align-baseline
            mt-10
            bg-white
            text-secondary
            relative
            inline-flex
            items-center
            px-4
            dark:bg-gray-700
            dark:hover:bg-gray-800
            dark:border-gray-900
            hover:bg-slate-50
            dark:text-white
            py-2
            mr-2
            ml-2
            border
            text-sm
            font-medium
            rounded-xl
          "
        />
      }
    </div>
  );
}

export default Table;

