import Modal from "./Modal";
import SubmitModal from "./SubmitModal";

// Stock
import AddAditionalModal from "./stock/AddAditionalModal";
import AddStockModal from "./stock/AddStockModal";
import SellAdditionalModal from "./stock/SellAdditionalModal";

// Loan
import DeleteLoanModal from "./loan/DeleteLoanModal";
import ExtraPaidLoanModal from "./loan/ExtraPaidLoanModal";
import LoanModal from "./loan/LoanModal";

// Settings
import ResetPasswordModal from "./settings/ResetPasswordModal";

const modal = {
    AddAditionalModal,
    AddStockModal,
    SellAdditionalModal,
    Modal,
    SubmitModal,
    DeleteLoanModal,
    ExtraPaidLoanModal,
    LoanModal,
    ResetPasswordModal
}

export default modal