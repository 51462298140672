import loanIDMethod from "./loan/LoanIDMethod";
import loanMethod from "./loan/LoanMethod";
import stockDetailsMethod from "./stock/StockDetailsMethod";


const method = {
    ...loanIDMethod,
    ...loanMethod,
    ...stockDetailsMethod
}

export default method