import { FieldValues, SubmitHandler, UseFormReset, UseFormSetValue } from "react-hook-form"
import { encoding } from "../../encryption/encryption"
import { useAddAdditionalStock, useDeleteAdditionalStock, useSellStock } from "../../views/stock/mutateStock"
import { RefetchQuery, StockData, clicker } from "../../types"

const useAdditionalSubmit = (filter: StockData, clicks: clicker, refetch: RefetchQuery) => {
    const {mutate: additionalMutate, isLoading: additionalLoading} = useAddAdditionalStock()

    const additionalSubmit: SubmitHandler<FieldValues> = (e) => {
        if (filter) {
          const encoded = encoding(e)
    
          const payload = {
            userInformation: encoded
          }
    
          const mergeIDnPayload = [
            filter.id, payload
          ]
    
          additionalMutate(mergeIDnPayload, {
            onSuccess: ({data}) => {
              if (data.success) {
                clicks({})
                refetch()
              }
            }
          })
        }
    }

    return {additionalSubmit, additionalLoading}
}

const useSellStockSubmit = (filter: StockData, sellReset: UseFormReset<FieldValues>, refetch: RefetchQuery, setSellModal: React.Dispatch<React.SetStateAction<boolean>>) => {
    const {mutate: sellStockMutate, isLoading: sellStockLoading} = useSellStock()

    const sellStockSubmit: SubmitHandler<FieldValues> = (e) => {
        if (filter) {
          const encoded = encoding(e)
    
          const payload = {
            userInformation: encoded
          }
    
          const mergeIDnPayload = [
            filter.id, payload
          ]
    
          sellStockMutate(mergeIDnPayload, {
            onSuccess: ({data}) => {
              if (data.success) {
                setSellModal(false)
                sellReset()
                refetch()
              }
            }
          })
        }
    }

    return {sellStockSubmit, sellStockLoading}
}

const useDeleteAdditionalSubmit = (filter: StockData, clicks: clicker, refetch: RefetchQuery) => {
    const {mutate: deleteAdditionalMutate, isLoading: deleteAdditionalLoading} =  useDeleteAdditionalStock()

    const deleteAdditionalSubmit = (e: Record<string, any>) => {
      if (filter) {
        const encoded = encoding(e)
        const payload = {
          userInformation: encoded
        }
  
        const mergeIDnPayload = [
          filter.id, payload
        ]
  
        deleteAdditionalMutate(mergeIDnPayload, {
          onSuccess: ({data}) => {
            if (data.success) {
              clicks({})
              refetch()
            }
          }
        })
      } 
    }

    return {deleteAdditionalSubmit, deleteAdditionalLoading}
}

const useClicks = (
  setStatus: React.Dispatch<React.SetStateAction<boolean>>, 
  setAdditionalValue: UseFormSetValue<FieldValues>, 
  additionalReset: UseFormReset<FieldValues>, 
  setSelect: React.Dispatch<React.SetStateAction<boolean>>,
  select: boolean,
) => {
  const clicks = (x: any, y?: any) => {
    if (Object.prototype.hasOwnProperty.call(x, "cost")) {
      setStatus(true)
      setAdditionalValue("cost", x.cost)
      setAdditionalValue("shares", x.shares)
      setAdditionalValue("date", x.date)
      setAdditionalValue("id", x.id)
    } else {
      setStatus(false)
      additionalReset()
    }
    setSelect(!select)
  }

  return clicks
}

const stockDetailsMethod = {
  useAdditionalSubmit,
  useSellStockSubmit,
  useDeleteAdditionalSubmit,
  useClicks
}

export default stockDetailsMethod;
