import Shared from './components/Shared';
import {useResetPassword} from './mutateAuth';
import component from '../../components';
import {useForm, SubmitHandler, FieldValues} from 'react-hook-form';
import {connector, CustomModalForm, Props} from '../../types';

function ResetPassword(props: Props) {
  const {setAlert, setAlertMessage, setAlertStatus} = props;
  const {register, handleSubmit, formState: {errors}, reset} = useForm();

  const {mutate, isLoading} = useResetPassword();

  const resetPassword: SubmitHandler<FieldValues> = (data) => {
    mutate(data, {
      onSuccess: (res) => {
        reset();
        setAlert(true);
        if (res.data.success) {
          setAlert(true)
          setAlertMessage(
            `Successful sent reset user email,
             Please check your email to reset the password`
          )
          setAlertStatus("success")
        } else {
          setAlertStatus("error")
          setAlert(true)
          setAlertMessage(
            res.data.message
          )
        }
      },
    });
  };

  const customForm: Array<CustomModalForm> = [
    {
      divProps: {
        className: "col-span-6 sm:col-span-6"
      },
      label: "Email address",
      labelProps: {
        htmlFor: "email-address"
      },
      inputProps: {
        type: "email",
        ...register('email', {
          pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
          required: true,
        }),
        autoComplete: "email",
      },
      children: <>
        {errors.email &&
          errors.email.type === 'pattern' &&
            <p className='text-sm text-red-500'>
              Invalid email
            </p>
        }
        {errors.email &&
          errors.email.type === 'required' &&
            <p className='text-sm text-red-500'>
              This field is required
            </p>
                }
      </>
    }
  ]

  return (
    <Shared to="/" link="Sign in now" header="Reset Password" reset={reset} {...props}>
      <form id="resets" onSubmit={handleSubmit(resetPassword)}>
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6 dark:bg-gray-900">
            <div className="grid grid-cols-6 gap-6">  
              <component.FormCustom customForm={customForm} />
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-900 pb-5">
            <button
              type="submit"
              className="
                inline-flex
                w-full
                justify-center
                py-2
                px-4
                border
                border-transparent
                shadow-sm
                text-sm
                font-medium
                rounded-md
                text-white
                bg-secondary
                hover:bg-primary
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-primary
                "
            >
              <component.ButtonLoader isLoading={isLoading} />
              Reset Password Now
            </button>
          </div>
        </div>
      </form>
    </Shared>
  );
}

export default connector(ResetPassword);
