import { useEffect } from 'react';
import component from '../components';
import {connector, Props, ThemeProviderProps} from '../types';

const Background = (props: ThemeProviderProps & Props) => {
    const {alert, message, status, setAlert, setAlertMessage, setAlertStatus} = props;

    useEffect(() => {
        if (alert) {
            setTimeout(() => {
                setAlert(false)
                setAlertMessage("")
                setAlertStatus("")
            }, 4200)
        }
    }, [alert, setAlert, setAlertMessage, setAlertStatus])

    const { children } = props
    return (
        <div className="bg-slate-100 dark:bg-gradient-to-br dark:from-blueGray-700 dark:to-blueGray-800 transition-all h-screen md:overflow-x-hidden overflow-y-auto">
            <component.Alert alert={alert} message={message} status={status} />
            {children}
        </div>
    )
}

export default connector(Background);