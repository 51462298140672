import { useQuery } from "react-query"
import {api} from '../../api';
import { Conversion, Props } from "../../types";
import {initialState} from '../../store/reducers/financeReducer';

export const useGetCurrencyRate = (props: Props) => {
    const {currency, setCurrency} = props
    const {
        data,
        isLoading,
        isFetching,
        isError,
        error,
        isSuccess,
        refetch,
    } = useQuery(["getCurrencyRate", currency, setCurrency], async () => {
        if (localStorage.getItem("currency") !== null) {
            setCurrency(JSON.parse(localStorage.getItem("currency")!))
        } else {
            const {data: currencyRate} = await api.getCurrencyRate(currency.value);

            let currencyVal: Conversion
            if (currencyRate.success) {
                currencyVal = {
                    ...currency,
                    rate: currencyRate.data.convertedAmount
                }
            } else {
                currencyVal = initialState.currency
            }

            localStorage.setItem("currency", JSON.stringify(currencyVal))
            setCurrency(currencyVal)
        }
        
    });
    
    return {data, isLoading, isFetching, isError, error, isSuccess, refetch};
}