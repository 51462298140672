import { useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom'
import { connector, Props, StockData } from '../../types';
import { regex } from '../../variables/var';
import { columnRenderer, columnSnapRenderer, shortenColumnRenderer } from '../../data/stock/render/StockDetailsRender';
import method from '../../method';
import hook from '../../hooks';
import component from '../../components';

function StockDetails(props: Props) {
  const params = useParams()

  const {
    currency: currentCurrency
  } = props

  const symbol = currentCurrency.name === "MYR" ? "RM" : "$"

  const [select, setSelect] = useState(false)
  const [sellModal, setSellModal] = useState(false)
  const [status, setStatus] = useState(false)

  const {
    data,
    isLoading: dataLoading,
    refetch,
    isFetching: fetchLoading,
  } = hook.useGetStock(props.user); 

  const {
    register: additionalRegister,
    handleSubmit: additionalHandleSubmit,
    formState: {
      errors: additionalErrors,
    },
    reset: additionalReset,
    watch: additionalWatch,
    setValue: setAdditionalValue,
  } = useForm();

  
  const {
    register: sellRegister,
    handleSubmit: sellHandleSubmit,
    formState: {
      errors: sellErrors,
    },
    reset: sellReset,
    watch: sellWatch,
    setValue: setSellValue
  } = useForm();

  const closeSellModal = () => {
    setSellModal(!sellModal)
    sellReset()
  }

  const openSellModal = (row: Record<string, any>) => {
    setSellModal(true)
    setSellValue("id", row.id)
  }

  const filter: StockData = hook.useMemoStock(data, params)

  const clicks = method.useClicks(setStatus, setAdditionalValue, additionalReset, setSelect, select)

  const {additionalSubmit, additionalLoading} = method.useAdditionalSubmit(filter, clicks, refetch)

  const {sellStockSubmit, sellStockLoading} = method.useSellStockSubmit(filter, sellReset, refetch, setSellModal)

  const {deleteAdditionalSubmit, deleteAdditionalLoading} = method.useDeleteAdditionalSubmit(filter, clicks, refetch)
  
  const createSnap = hook.useStockGetSnapData(filter, symbol)
  
  const column = columnRenderer(clicks, openSellModal)
  const shortenColumn = shortenColumnRenderer()
  const columnSnap = columnSnapRenderer()

  if (dataLoading) {
    return <component.Loading />;
  }

  if (fetchLoading) {
    return <component.LoadingOverlay/>;
  }

  return (
    <div className='p-4 h-full'>
      <div
        className={`flex flex-wrap justify-between items-center align-middle mt-3 maxsizs:space-y-8`}
      >
          <div className={`font-semibold text-sm text-black dark:text-white`}>
            <Link to="/stock" className='rounded-md
              shadow-md
              p-3
              text-sm
              bg-gray-300
              hover:bg-gray-500
              dark:bg-gray-500
              dark:hover:bg-gray-300
              font-medium
              dark:text-gray-200
              dark:hover:text-gray-700
              hover:text-white'
            >
              Back
            </Link>
          </div>
          <div className={`font-semibold text-xl text-black dark:text-white text-center buttonmax:w-full`}>
            {filter?.name?.replace(regex, "")}
          </div>
          <div className='space-x-3 flex flex-wrap'>
            <Link
              to="dividends"
              className={`
                rounded-md
                shadow-md
                p-3
                text-sm
                bg-green-300
                hover:bg-green-500
                dark:bg-green-500
                dark:hover:bg-green-300
                font-medium
                dark:text-gray-200
                dark:hover:text-gray-700
                hover:text-white
              `}
            >
              Dividends Calendar
            </Link>
            <button
              onClick={clicks}
              type="button"
              className={`
              rounded-md
              shadow-md
              p-3
              text-sm
              bg-yellow-300
              hover:bg-yellow-500
              dark:bg-yellow-500
              dark:hover:bg-yellow-300
              font-medium
              dark:text-gray-200
              dark:hover:text-gray-700
              hover:text-white
              `}
            >
              Add Lot
            </button>
          </div>
      </div>
      <div className='inline md:hidden'>
        <component.Snap
          shortenColumn={columnSnap}
          customSnap={createSnap}
        />
      </div>
      <div className='mt-5'>
        <div className='hidden md:inline'>
          <component.Table 
            column={column}
            data={filter?.data && filter.data}
            loading={fetchLoading}
          />
        </div>
        <div className='inline md:hidden'>
          <component.ShortenTable 
            shortenColumn={shortenColumn}
            column={column}
            data={filter?.data && filter.data}
          />
        </div>
      </div>
      <component.AddAditionalModal 
        open={select}
        closeModal={clicks}
        isLoading={additionalLoading}
        register={additionalRegister}
        handleSubmit={additionalHandleSubmit}
        errors={additionalErrors}
        submit={additionalSubmit}
        watch={additionalWatch}
        stock={filter}
        clicks={clicks}
        status={status}
        deleteSubmit={deleteAdditionalSubmit}
        deleteLoading={deleteAdditionalLoading}
      />
      <component.SellAdditionalModal
        open={sellModal}
        closeModal={closeSellModal}
        isLoading={sellStockLoading}
        register={sellRegister}
        handleSubmit={sellHandleSubmit}
        errors={sellErrors}
        submit={sellStockSubmit}
        watch={sellWatch}
        stock={filter}
        clicks={clicks}
        status={status}
      />
    </div>
  )
}

export default connector(StockDetails)