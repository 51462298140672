import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import { DataRender, Props, connector } from '../../types';
import { useGetLoanById } from './queryLoan';
import {
  useMemoLoanIDDetails,
} from './mutateLoan';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { encoding } from '../../encryption/encryption';
import component from '../../components';
import hook from '../../hooks';
import method from '../../method';
import currency from 'currency.js';
import functions from '../../function';

function LoanID(props: Props) {
    // Get url params
    const params = useParams()

    // State component here
    // const [enabled, setEnabled] = useState(false);
    const [editLoans, setEditLoans] = useState(false);
    const [deleteLoans, setDeleteLoans] = useState(false);
    const [paidLoans, setPaidLoans] = useState(false);
    const [todayDate, setTodayDate] = useState({
      name: moment().format('YYYY'),
      value: moment().format('YYYY')
    });
    const [rowId, setRowId] = useState("")

    const {
        register,
        handleSubmit,
        formState: {
          errors,
        },
        reset,
        setValue,
        watch,
        control: controlEdit,
    } = useForm();

    const {
        register: registerPaid,
        handleSubmit: handlePaidSubmit,
        formState: {errors: errorsPaid},
        reset: resetPaidValue,
        setValue: setPaidValue,
        control,
        watch: paidWatch,
    } = useForm();

    const {
        data,
        isLoading: dataLoading,
        isFetching: fetchLoading,
        refetch,
    } = useGetLoanById(params); 

    // const filter_data: LoanData = hook.useLoanFilterData(data, params.id)

    const processData = useMemoLoanIDDetails(data)

    if (processData.years.length > 0) {
        const current_year = processData.years.filter(x => x.value === todayDate.value)

        if (current_year.length === 0) {
            setTodayDate(processData.years[0])
        }
    }

    const paidLoansModal = () => {
        setPaidLoans(false)
        resetPaidValue()
    }


    const {checkBoxDefaultSubmit, statusLoading} = method.useChangeLoanMethod(data?.data, paidLoansModal, refetch, props)

    useEffect(() => {
        if (!statusLoading) {
            setRowId("")
        }
    }, [statusLoading])

    // eslint-disable-next-line
    const checkBoxSubmit = (e: any) => {
        let principal = e.amount_required 
        let amount = e.auto_amount ? 0 : e.paid_amount;
        if (data?.data.interest_rate === 0) {
            principal = e.amount_required
            amount = currency(amount, {precision: 2}).subtract(e.amount_required).value
        } 

        if (amount < 0 && Math.abs(amount) === principal) {
            amount = 0
        }

        if (data?.data.interest_rate === 0) {
            if (e.auto_amount === false && e.paid_amount === "0") {
                amount = -e.amount_required
            }
            
        } else {
            if (e.auto_amount === false) {
                amount = currency(amount, {precision: 2}).subtract(principal).value
            }
        }

        const userInfo = encoding({
            id: e.id,
            status: 'paid',
            principal: functions.currencyDefault(principal),
            extra_payment: functions.currencyDefault(amount),
        });

        setRowId(e.id)

        checkBoxDefaultSubmit(userInfo);
    };

    const paidLoan = (
        // eslint-disable-next-line
        row: Record<string, any>,
    ) => {
        if (row.status === 'paid') {
            const userInfo = encoding({
                id: row.id,
                status: 'unpaid',
            });
            setRowId(row.id)
            checkBoxDefaultSubmit(userInfo);
        } else {
            setPaidLoans(true);
            const v1 = row.payment
            setPaidValue('id', row.id);
            setPaidValue('payment_date', moment(row.date).format("YYYY-MM-DD"));
            setPaidValue('amount_required', functions.currencyConversion(v1));
        }
    };

    const deleteLoansModal = () => {
        setDeleteLoans(!deleteLoans)
        reset()
    }

    const {deleteLoan, deleteLoading} = method.useDeleteLoanMethod(data?.data, deleteLoansModal, refetch)

    const editLoansModal = () => {
        setEditLoans(!editLoans)
        reset()
        if (data?.data.loan_type === "Fixed") {
            setValue("loan-type", false)
        }
        setValue('loan', data?.data?.name, {shouldValidate: true});
        setValue('total-amount', functions.currencyConversion(data?.data?.total_amount));
        setValue('interest-rate', data?.data?.interest_rate);
        setValue('duration', data?.data?.years);
        setValue('months', data?.data?.months);
        setValue('date', moment(data?.data?.data[0].date).format("YYYY-MM-DD"));
    }
    
    const {editLoan, editLoading} = method.useEditLoanMethod(data?.data, editLoansModal, refetch)

    const getPaid = data?.data.data.filter(x => x.status === "paid")

    let min = 0
    if (data?.data.years === 0) {
        min = getPaid.length + 1
    }
   
    const chartsData = hook.useGetLoanSnapById(data)

    const color = data?.color;

    const checker = (charts: DataRender) => {
        if (charts.datasets[0].data.reduce((x: number, y: number) => x + y, 0) === 0) {
            return false
        } 
        return true
    }

    const lengthChecker = (charts: DataRender) => {
        if (charts.datasets[0].data.length === 1) {
            return false
        } 
        return true
    }

    if (dataLoading) {
        return <component.Loading />;
    }

    return (
        <div className='p-4'>
            <div
                className={`flex justify-between items-center align-middle mt-3 maxsizs:space-y-8`}
            >
                <Link to="/loan" className='
                    rounded-md
                    shadow-md
                    p-3
                    mt-8
                    text-sm
                    bg-gray-300
                    hover:bg-gray-500
                    dark:bg-gray-500
                    dark:hover:bg-gray-300
                    font-medium
                    dark:text-gray-100
                    dark:hover:text-gray-700
                    hover:text-white'
                >
                    Back
                </Link>
                <div className={`${functions.colorConvert(color)} text-center sm:hidden text-2xl font-semibold rounded-2xl px-3 capitalize`}>
                    {data.data!.name}
                </div>
                <div className='space-x-3'>
                    <component.Button 
                        type='button'
                        onClick={editLoansModal}
                        children="Edit Loan"
                        className={`
                            bg-yellow-300
                            hover:bg-yellow-500
                            dark:bg-yellow-600
                            dark:hover:bg-yellow-300
                        `}
                    />
                    <component.Button 
                        type='button'
                        onClick={deleteLoansModal}
                        children="Delete Loan"
                        className={`
                            bg-red-300
                            hover:bg-red-500
                            dark:bg-red-600
                            dark:hover:bg-red-300
                        `}
                    />
                </div>
            </div> 
            <component.Summarizer 
                color={color}
                endingBalance={data.endingBalance}
                interestLeft={data.interestLeft}
                name={data.data!.name}
            />
            <div className='pt-5 md:columns-2 maxsizs:flex maxsizs:flex-wrap'>
                <component.RadioTable 
                    data={processData}
                    click={paidLoan}
                    filtered={data.data}
                    years={todayDate}
                    loading={statusLoading || fetchLoading}
                    id={rowId}
                />
                <div className='mt-5 mx-auto grid grid-cols-2 gap-4 xsizs:grid-cols-1 xsizs:gap-1 sm:grid-cols-3 sm:gap-3 md:grid-cols-2 md:gap-2'>
                    <component.Dash
                        color={color}
                        labelRender="Loan Payment Chart"
                    >
                        {(statusLoading || fetchLoading) ?
                            <div className='flex justify-center mt-10'>
                                <component.ButtonLoader 
                                    isLoading={statusLoading || fetchLoading} 
                                    classNameProps={`
                                        ${functions.colorConvert(color)}
                                        w-10
                                        h-10                                    
                                    `} 
                                />
                            </div>
                            :
                            chartsData.chartData ?
                                checker(chartsData.chartData) ?
                                    lengthChecker(chartsData.chartData) ?
                                    <component.LineChart 
                                        color={color}
                                        className='w-auto h-30 dark:bg-gray-900 bg-white rounded-2xl'
                                        chartsData={chartsData.chartData}
                                    />
                                    :
                                    <div className={`p-12 rounded-2xl text-center dark:bg-gray-900 bg-white ${functions.colorConvert(color)}`}>
                                        Collecting data....
                                    </div>
                                :
                                    <div className={`p-12 rounded-2xl text-center dark:bg-gray-900 bg-white ${functions.colorConvert(color)}`}>
                                        No data available....
                                </div>
                            :
                            <></>
                        }
                    </component.Dash> 
                    <component.Dash
                        color={color}
                        labelRender={
                            <>
                                {color === "red" ?
                                    "Overdue"
                                    :
                                    color === "yellow" ?
                                    "On time" 
                                    :
                                    "Paid Extra"
                                }
                                <div className='animate-pulse'>
                                    (Est.)
                                </div>
                            </>
                        }
                    >
                        {
                            (statusLoading || fetchLoading) ?
                            <div className='flex justify-center mt-2'>
                                <component.ButtonLoader 
                                    isLoading={statusLoading || fetchLoading} 
                                    classNameProps={`
                                        ${functions.colorConvert(color)}
                                        w-10
                                        h-10                                    
                                    `} 
                                />
                            </div>
                            :
                            <div
                                className="
                                    text-2xl
                                    font-bold
                                    animate-pulse
                                "
                                >

                                {functions.currencyConversion(
                                    currency(functions.currencyConversion(data.paidExtra), {precision: 2}).value,
                                    false, undefined, undefined, true
                                )}
                            </div>
                        }
                    </component.Dash>    
                    <component.Dash
                        color={color}
                        labelRender="Interest Paid"
                    >
                        {
                            (statusLoading || fetchLoading) ?
                            <div className='flex justify-center mt-2'>
                                <component.ButtonLoader 
                                    isLoading={statusLoading || fetchLoading} 
                                    classNameProps={`
                                        ${functions.colorConvert(color)}
                                        w-10
                                        h-10                                    
                                    `} 
                                />
                            </div>
                            :
                            <div
                                className="
                                    text-xl
                                    font-bold
                                    animate-pulse
                                "
                                >
                                {functions.currencyConversion(data.interestPaid, false, undefined, undefined, true)}
                            </div>
                        }
                    </component.Dash>
                </div>
            </div>
            <component.ExtraPaidLoanModal
                open={paidLoans}
                closeModal={paidLoansModal}
                register={registerPaid}
                submit={checkBoxSubmit}
                handleSubmit={handlePaidSubmit}
                errors={errorsPaid}
                isLoading={statusLoading}
                watch={paidWatch}
                control={control}
            />
            <component.LoanModal
                open={editLoans}
                closeModal={editLoansModal}
                register={register}
                submit={editLoan}
                handleSubmit={handleSubmit}
                errors={errors}
                isLoading={editLoading}
                watch={watch}
                title="Edit loan"
                name="edit_loan"
                buttonName='Save'
                minimum={min}
                control={controlEdit}
            />
            <component.DeleteLoanModal
                loanName={data?.data?.name}
                open={deleteLoans}
                closeModal={deleteLoansModal}
                isLoading={deleteLoading}
                submit={deleteLoan}
            />
        </div>
    )
}

export default connector(LoanID)