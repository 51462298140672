import functions from "../../function";
import { DashProps } from "../../types";

function Dash(props: DashProps) {
    const {
        color =  "green",
        labelRender = <></>,
        children
    } = props;

    return (
        <div className={`p-12 pt-14 rounded-2xl text-center dark:bg-gray-900 bg-white ${functions.colorConvert(color)}`}>
            <label
                className='text-lg font-bold'
            > 
                {labelRender}  
            </label>
            {children}
        </div>
    )
}

export default Dash;