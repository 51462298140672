import { useNavigate } from "react-router-dom";
import { useChangeStatus, useDeleteLoan, useEditLoan } from "../../views/loan/mutateLoan";
import { LoanData, Props, RefetchQuery, clicker } from "../../types";
import { FieldValues, SubmitHandler } from "react-hook-form";
import { encoding } from "../../encryption/encryption";
import functions from "../../function";

const useDeleteLoanMethod = (filter_data: LoanData, deleteLoansModal: clicker, refetch: RefetchQuery) => {
    const navigate = useNavigate()
    const {mutate: deleteMutate, isLoading: deleteLoading} = useDeleteLoan();


    const deleteLoan = () => {
        deleteMutate(filter_data!.id, {
        onSuccess: ({data: res}) => {
            if (res.success) {
                deleteLoansModal();
                refetch()
                navigate("/loan")
            } else {
                console.log(res.message);
            }
        },
        });
    };

    return {deleteLoan, deleteLoading}
}

const useEditLoanMethod = (filter_data: LoanData, editLoansModal: clicker, refetch: RefetchQuery) => {
    const {mutate: editMutate, isLoading: editLoading} = useEditLoan();
    const editLoan: SubmitHandler<FieldValues> = (newD) => {
        const amount = parseFloat(newD["total-amount"])
        const userInfo = encoding({
            ...newD,
            "total-amount": `${functions.currencyDefault(amount)}`,
            exp: Math.round(Date.now() / 1000) + (60 * 10), // 10 min expiration
        });
    
        const payloads = {
            userInformation: userInfo,
        };
    
        const payload = [
            filter_data!.id,
            payloads,
        ];
    
        editMutate(payload, {
            onSuccess: ({data: res}) => {
            if (res.success) {
                editLoansModal();
                refetch();
            } else {
                console.log(res.message);
            }
            },
        });
    };

    return {editLoan, editLoading}
}

const useChangeLoanMethod = (
    filter_data: LoanData, 
    paidLoansModal: clicker, 
    refetch: RefetchQuery,
    props: Props
) => {
    const {setAlert, setAlertMessage, setAlertStatus} = props
    const {
        mutate: statusMutate,
        isLoading: statusLoading,
    } = useChangeStatus();

    const checkBoxDefaultSubmit = (userInfo: string) => {
        const payloads = {
            userInformation: userInfo,
        };

        const payload = [filter_data!.id, payloads];

        statusMutate(payload, {
            onSuccess: ({data: res}) => {
                if (res.success) {
                    paidLoansModal()
                    refetch();
                } else {
                    const value = res.message.split("Extra Payment: ")[1]
                    const val = functions.currencyConversion(parseInt(value), true)
                    setAlert(true)
                    setAlertMessage(`Extra payment of ${val} were made`)
                    setAlertStatus("error")
                }
            }
        });
    };

    return {checkBoxDefaultSubmit, statusLoading}
}

const loanIDMethod = {
    useDeleteLoanMethod,
    useEditLoanMethod,
    useChangeLoanMethod
}

export default loanIDMethod;