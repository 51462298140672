import {useParams, useNavigate} from 'react-router-dom';
import {FieldValues, SubmitHandler, useForm} from 'react-hook-form';
import {connector, CustomModalForm, Props} from '../../types';
import Shared from './components/Shared';
import NotFound from '../error/NotFound';
import {encoding} from '../../encryption/encryption';
import component from '../../components';
import {useResetUserPassword, useGetUserInfo} from './mutateAuth';

function ResetPasswordID(props: Props) {
  const {register, handleSubmit, formState: {errors}, reset, watch} = useForm();
  const {setAlert, setAlertMessage, setAlertStatus} = props;

  const params = useParams();
  const navigate = useNavigate();

  const {data, isLoading} = useGetUserInfo(params);

  const {mutate, isLoading: resetLoading} = useResetUserPassword();

  const resetPassword: SubmitHandler<FieldValues> = (param) => {
    const userInfo = encoding({
      ...param,
      id: params.id,
      exp: Math.round(Date.now() / 1000) + (60 * 10), // 10 min expiration
    });
    const payload = {userInformation: userInfo};
    mutate(payload, {
      onSuccess: () => {
        reset();
        setAlert(true)
        setAlertMessage(
          `Successful sent reset user email,
            Please check your email to reset the password`
        )
        setAlertStatus("success")
        navigate('/');
      },
    });
  };

  const customForm: Array<CustomModalForm> = [
    {
      divProps: {
        className: "col-span-6 sm:col-span-6"
      },
      label: "New Password",
      labelProps: {
        htmlFor: "password"
      },
      inputProps: {
        type: "password",
        ...register('password', {required: true, minLength: 7}),
        autoComplete: "password",
        placeholder: "Give it a new life ...."
      },
      children: <>
        {errors.password &&
          errors.password.type === 'required' &&
            <p className='text-sm text-red-500'>
              This field is required
            </p>
        }
        {errors.password &&
          errors.password.type === 'minLength' &&
            <p className='text-sm text-red-500'>
              Password should be more than 7 characters
            </p>
        }
        {errors.password &&
          errors.password.type === 'validate' &&
            <p className='text-sm text-red-500'>
              Password is not the same as confirm password
            </p>
        }
      </>
    },
    {
      divProps: {
        className: "col-span-6 sm:col-span-6"
      },
      label: "Confirm New Password",
      labelProps: {
        htmlFor: "confirm-password"
      },
      inputProps: {
        type: "password",
        ...register('confirmPassword', {
          validate: (val) => val === watch('password'),
          required: true,
          minLength: 7,
        }),
        autoComplete: "password",
        placeholder: "Now do that again ...."
      },
      children: <>
        {errors.confirmPassword &&
          errors.confirmPassword.type === 'required' &&
            <p className='text-sm text-red-500'>
              This field is required
            </p>
        }
        {errors.confirmPassword &&
          errors.confirmPassword.type === 'minLength' &&
            <p className='text-sm text-red-500'>
              Password should be more than 7 characters
            </p>
        }
        {errors.confirmPassword &&
          errors.confirmPassword.type === 'validate' &&
            <p className='text-sm text-red-500'>
              Confirm password is not the same as password
            </p>
        }
      </>
    }
  ]


  if (isLoading) {
    return (
      <component.Loading />
    );
  } else if (data !== undefined && data.success) {
    return (
      <Shared
        to="/"
        link="Sign in now"
        header={`Reset Password Now!`}
        reset={reset}
        {...props}
      >
        <div
          className='
            flex
            items-center
            justify-center
            '
        >
          
          <div className='max-w-md w-full space-y-8 '>
            <div>
                <h2 className='
                  text-center
                  text-3xl
                  font-extrabold
                  text-blueGray-900
                  dark:text-white
                  '
                >
                  {data.data.email}
                </h2>
              </div>
          </div>
        </div>
        <form id="resets-password-now" onSubmit={handleSubmit(resetPassword)}>
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6 dark:bg-gray-900">
              <div className="grid grid-cols-6 gap-6">
                <component.FormCustom customForm={customForm} />
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 pb-5 dark:bg-gray-900">
              <button
                type="submit"
                className="
                  inline-flex
                  w-full
                  justify-center
                  py-2
                  px-4
                  border
                  border-transparent
                  shadow-sm
                  text-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-secondary
                  hover:bg-primary
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-primary
                  "
              >
                <component.ButtonLoader isLoading={resetLoading} />
                  Reset Password Now
              </button>
            </div>
          </div>
        </form>
      </Shared>
    );
  } else {
    return (
      <NotFound/>
    );
  }
}

export default connector(ResetPasswordID);
