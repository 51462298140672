import {Link} from 'react-router-dom';
import ThemeToggle from '../../../theme/toggle/ThemeToggle';
import {Props} from '../../../types';

function Shared(props: Props) {
  const {header, link, to, reset} = props;

  return (
    <div>
      <div className="absolute right-0 top-0 mr-4 mt-4 md:mr-6 md:mt-6">
        <ThemeToggle {...props} />
      </div>
      <div
        className='
          flex
          items-center
          justify-center
          pt-24
          pb-5
          px-4
          sm:px-6
          lg:px-8
          '
      >
        
        <div className='max-w-md w-full space-y-8 '>
          {header &&
            <div>
              <h2 className='
                mt-6
                text-center
                text-3xl
                font-extrabold
                text-blueGray-900
                dark:text-white
                '
              >
                {header}
              </h2>
            </div>
          }
          {props.children}
        </div>
      </div>
      <div className='flex justify-center pb-28 bg-inherit'>
        {!header &&
          <div className='text-sm'>
            <Link
              to='/reset-password'
              className='
                px-5
                float-right
                font-medium
                text-blueGray-500
                hover:text-blueGray-800
                dark:text-blueGray-200
                dark:hover:text-blueGray-300
                '
            >
                Forgot your password?
            </Link>
          </div>
        }
        <div className='text-sm'>
          <Link
            to={to ? to : ''}
            onClick={reset}
            className='
              px-5
              float-right
              font-medium
              text-blueGray-500
              hover:text-blueGray-800
              dark:text-blueGray-200
              dark:hover:text-blueGray-100
              '
          >
            {link}
          </Link>
        </div>
      </div>
    </div>
  );
}


export default Shared;
