import ButtonLoader from "./ButtonLoader";
import Loading from "./Loading";
import LoadingOverlay from "./LoadingOverlay";

const loader = {
    ButtonLoader,
    Loading,
    LoadingOverlay,
}

export default loader;