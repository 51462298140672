import { Fragment } from 'react'
import { FormCustom as FormCustoms } from '../../types'
import component from '../../components'

function FormCustom(props: FormCustoms) {
  const {
    customForm
  } = props 
  
  return (
    <>
        {customForm.map((x, y) => {
          if (x.custom) {
            return (
              <Fragment key={y}></Fragment>
            )
          }
          return (
            <component.ModalCustom
              key={y}
              divProps={x.divProps}
              labelProps={x.labelProps}
              label={x.label}
              inputProps={x.inputProps}
              inputClassName={x.inputClassName}
            >
              {x.children}
            </component.ModalCustom>
          )
        })}
    </>
  )
}

export default FormCustom