import jwt from 'jwt-encode';
import jwt_decode from "jwt-decode";

export const encoding = (data: Record<string, any> | Array<Record<string, any>>) => {
  const token = process.env.REACT_APP_JWT_TOKEN ?
    process.env.REACT_APP_JWT_TOKEN : '';
  return jwt(data, token);
};


export const decoding = (jwtUserInfo: string) => {
  const decoded: Array<Record<string, any>> = jwt_decode(jwtUserInfo);
    
  return decoded
}