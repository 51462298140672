import {BasicProps} from '../../types';

function UserIcon(props: BasicProps) {

  const {children, classNameProps} = props

return (
    <div
      className={`
      ${classNameProps}
      relative
      flex
      justify-center
      items-center
      rounded-full
      bg-primary
      text-white
      uppercase`}
    >
      {children}
    </div>
  )
}

export default UserIcon