import {SharedModalProps} from '../../types'
import component from '../../components'

function SubmitModal(props: SharedModalProps) {
  const {
    closeModal = () => null,
    isLoading = false,
    text = "Submit",
    type,
    onClick,
    colorSubmit = "blue",
    colorClose = "red",
    children,
    disabled = false,
    hoverRef, 
    classNameProps = "",
  } = props

  return (
    <div className="mt-4 space-x-3">
      {!disabled &&
        <button
        ref={hoverRef}
        type={type ? type: "submit"}
        onClick={type !== "submit" ? onClick : undefined}
        className={`
          ${isLoading? "cursor-progress" : ""}
          ${classNameProps}
          inline-flex
          align-middle 
          justify-center
          px-4
          py-2
          ${isLoading? "pt-[9px]" : ""}
          text-sm
          font-medium
          text-${colorSubmit}-900
          bg-${colorSubmit}-100
          border
          border-transparent
          rounded-md
          hover:bg-${colorSubmit}-200
          focus:outline-none
          focus-visible:ring-2
          focus-visible:ring-offset-2
          focus-visible:ring-${colorSubmit}-500
          `}
      >
        <component.ButtonLoader isLoading={isLoading} classNameProps="text-inherit" />
        <div className={`${isLoading && 'mt-1'} `}>{text}</div>
      </button>
      }
      {children}
      <button
        type="button"
        className={`
          inline-flex
          justify-center
          px-4
          py-2
          text-sm
          font-medium
          text-${colorClose}-900
          bg-${colorClose}-100
          border
          border-transparent
          rounded-md
          hover:bg-${colorClose}-200
          focus:outline-none
          focus-visible:ring-2
          focus-visible:ring-offset-2 
          focus-visible:ring-${colorClose}-500
        `}
        onClick={closeModal}
      >
        Close
      </button>
    </div>
  )
}

export default SubmitModal