import modal from './modal';
import loader from './loader';
import table from './table';
import slider from './slider';
import select from './select';
import form from './form';
import nav from './nav';
import color from './color';
import alert from './alert';
import dash from './dash';
import icons from './icons';
import chart from './chart';
import button from './button';

const component = {
    ...modal,
    ...loader,
    ...table,
    ...slider,
    ...select,
    ...form,
    ...nav,
    ...color,
    ...alert,
    ...icons,
    ...dash,
    ...chart,
    ...button,
}

export default component;