import {Fragment, useMemo} from "react"
import moment from "moment";
import currency from "currency.js";
import {ListTableProps} from "../../types"
import functions from '../../function';
import component from "../../components";

function ListTable(props: ListTableProps) {
  const {
    data = {data: {}, years: []},
    filtered,
    fulldata,
    click,
    years,
    loading,
  } = props

  const months = useMemo(() => {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  }, []);

  return (
    <>
      {loading &&
        <component.LoadingOverlay/>
      }
      <br/>
      {months.map((z, k) => (
        <div key={k} className="px-3">
          {data.data[years.value][z] &&
            <div className="grid grid-cols-3 gap-3 mt-2">
              <div className="text-xs text-gray-700 uppercase dark:text-gray-400 justify-self-start">{z}</div>
              <div className="text-sm text-gray-600 justify-self-center dark:text-gray-300">Interest: {
                functions.currencyConversion(data.data[years.value][z].map(
                  (x: any) => x.interest)
                    .reduce(
                      (partialSum: any, a: any) => partialSum + a
                    , 0))
                }</div>
              <div className="text-sm text-gray-600 justify-self-end dark:text-gray-50">Paid: {
                functions.currencyConversion(data.data[years.value][z].map(
                  (x: any) => currency(x.paid_amount, {precision: 2}).add(x.extra_payment).value)
                  .reduce(
                    (partialSum: any, a: any) => {
                      if (a) {
                        return partialSum + a
                      }
                      return partialSum
                    }
                  , 0))
                }
              </div>
              <hr className="col-span-3 border-b-2"/>
            </div>
          }
          {data.data[years.value][z]?.sort((a: any, b: any) => {
            
            if (b) {
              return new Date(a.date).valueOf() - new Date(b.date).valueOf()
            } else {
              return new Date(a.date).valueOf() 
            }
          }).map((x: any, id: number)=> {
            const fil = fulldata?.find(ds => ds.id === x.table_id)?? filtered!

            const val = currency(x.paid_amount, {precision: 2})
                                  .add(x.extra_payment).value?? 0

            return (
              <Fragment key={id}>
                <div
                  onClick={() => click && click(x)}
                  className={`
                    rounded-xl
                    grid
                    grid-cols-4
                    gap-4
                    hover:bg-white
                    hover:text-black
                    dark:hover:bg-gray-700
                    dark:hover:text-white
                    dark:text-gray-400
                    text-blueGray-700
                    py-4
                    px-2
                    cursor-pointer
                  `}
                    >
                  <div className="justify-self-start">{moment(x.date).format("YYYY-MM-DD")}</div>
                  {fulldata?
                    <div className="truncate ">{fil.name}</div>
                    :
                    <div className="truncate "></div>
                  }
                  <div className="justify-self-center">{
                    functions.currencyConversion(x.status === 'paid' ? val : x.payment)
                  }</div>
                  <div className="justify-self-end">{functions.currencyConversion(val)}</div>
                </div>
              </Fragment>
            )
          })
          }
        </div>
      ))}
    </>
    
  )
}

export default ListTable
