import axios from 'axios';

let host = "http"
if (process.env.NODE_ENV === "production") {
  host = "https"
}

const api = axios.create({
  baseURL: "/api/",
  withCredentials: true,
  headers: {'Access-Control-Allow-Origin': `${host}://${process.env.REACT_APP_HOSTNAME}`}
});

axios.defaults.withCredentials = true

export default api;