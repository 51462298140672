import {api} from '../../api';
import {useQuery} from 'react-query';
import {LoanData, User, LoanDetailsIDPayload} from '../../types';
import { initialSelected } from '../../variables/loan/loan_var';

export const useGetLoanDetails = (
    // eslint-disable-next-line
    setSelected?: React.Dispatch<React.SetStateAction<any>>,
    selectOptions?: string,
    user?: User | Record<string, any>,
) => {
  const {
    data,
    isLoading,
    isFetching,
    isError,
    error,
    isSuccess,
    refetch,
  } = useQuery(
      [
        'getLoanDetails',
        setSelected,
        user?.id,
      ],
      async () => {
        const {data: loanDetails} = await api.getLoanDetails();

        if (loanDetails.success) {
          if (setSelected) {
            if (loanDetails.data.length > 0) {
              let d: LoanData;

              const loanData: Array<LoanData> = loanDetails.data;

              if (selectOptions === '') {
                d = loanData[0];
              } else {
                // eslint-disable-next-line
                d = loanData.find((x) => x.id === selectOptions)!;
              }

              setSelected(d);
            } else {
              setSelected(initialSelected);
            }
          }

          return loanDetails.data;
        }
        return [];
      }, {
        refetchOnMount: "always",
      });

  // eslint-disable-next-line
  const d: Array<LoanDetailsIDPayload> = data!;

  return {data: d, isLoading, isFetching, isError, error, isSuccess, refetch};
};

export const useGetLoan = (user?: User) => {
  const {
    data,
    isLoading,
    isFetching,
    isError,
    error,
    isSuccess,
    refetch,
  } = useQuery(
      [
        'getLoanDetails',
        user?.id,
      ],
      async () => {
        const {data: loanDetails} = await api.getLoanDetails();
        if (loanDetails.success) {
          return loanDetails.data;
        }
        return []
      }, {
        refetchOnMount: "always",
      });

  const d: Array<LoanDetailsIDPayload> = data!;

  return {data: d, isLoading, isFetching, isError, error, isSuccess, refetch};
}

export const useGetLoanById = (params: Record<string, any>) => {
  const {
    data,
    isLoading,
    isFetching,
    isError,
    error,
    isSuccess,
    refetch,
  } = useQuery(
      [
        'getLoanDetailsById',
        params?.id,
      ],
      async () => {
        const {data: loanDetails} = await api.getLoanDetailsById(params.id);
        if (loanDetails.success) {
          return loanDetails.data;
        }
        return []
      });

  const d: LoanDetailsIDPayload = data!;

  return {data: d, isLoading, isFetching, isError, error, isSuccess, refetch};
}
