import { DataRender, SnapProps, SnapRender } from "../../types";
import { regex } from "../../variables/var";
import functions from "../../function";
import component from "../../components";

function Snap(props: SnapProps) {
  const {
    customSnap = [],
    shortenColumn = [],
    click,
    chartsLoading,
    selectedDelete = [],
    additionalProps = "",
  } = props

  let title: SnapRender = {"dataIndex": "", "render": undefined, "type": ""}
  title = shortenColumn.find(x => x && x.type === "title")

  const data = shortenColumn.filter(x => x && x.type === "data")

  const checker = (charts: DataRender) => {
    if (charts.datasets[0].data.reduce((x: number, y: number) => x + y, 0) === 0) {
        return false
    } 
    return true
  }

  const lengthChecker = (charts: DataRender) => {
    if (charts.datasets[0].data.length === 1) {
        return false
    } 
    return true
  }

  return (
    <>
        {customSnap.length === 0 ?
            <div className="dark:bg-blueGray-800 bg-white flex rounded-2xl justify-center m-10 hover:shadow-lg">
                <div className='dark:text-blueGray-400 text-blueGray-800 align-top items-center justify-center px-4 pb-16 pt-2'>
                {
                    chartsLoading ?
                    <div className='flex justify-center font-bold pt-14'>
                        <component.ButtonLoader 
                            isLoading={chartsLoading} 
                            classNameProps={`
                                text-blueGray-900 
                                dark:text-blueGray-400   
                                w-10
                                h-10                                    
                            `} 
                        />
                    </div>
                    :
                    <div className='flex justify-center font-bold pt-14 animate-pulse'>
                        No search found ...
                    </div>
                }
                </div>
            </div>
            :
            <div className={`
                mt-4 
                px-10 
                snap-x 
                md:mx-auto 
                ${customSnap.length === 1 ? "justify-center" : ""}
                snap-mandatory 
                flex
                overflow-x-scroll
                space-x-6 
                rounded-2xl 
                scrollbar-hide 
                scroll-smooth
                `}>
                {customSnap.map((x, y: number) => {
                    let color: string = "blueGray"
                    if (shortenColumn.length > 0) {
                        if (shortenColumn[2] && shortenColumn[0]) {
                            const renderer = shortenColumn[2].render
                            const value = renderer ? renderer(x[shortenColumn[0].dataIndex],x)
                            :
                            x[shortenColumn[2].dataIndex]

                            if (value) {
                                if (value.key === "positive") {
                                    color = "green"
                                } else if (value.key === "negative") {
                                    color = "red"
                                } else if (value.key === "nothing") {
                                    color = "yellow"
                                }
                            }
                        }
                    }

                    const findSelected = selectedDelete.find(z => z.id === x.id)?.checked

                    let addons = "bg-white hover:bg-blueGray-300 dark:bg-blueGray-800 dark:hover:bg-blueGray-700"
                    if (findSelected) {
                        addons = "bg-gray-100 dark:bg-gray-500"
                    }

                    return (
                        <div 
                            key={y} 
                            className={`
                                ${click ? "cursor-pointer": "cursor-default"}
                                snap-center 
                                snap-always 
                                ${addons}
                                flex-shrink-0
                                flex 
                                rounded-2xl
                                hover:shadow-lg
                            `} 
                            onClick={() => click && click(x)}
                        >
                            <div className={`
                                ${functions.colorConvert(color)}
                                align-top 
                                items-center 
                                justify-center
                                px-4
                                pt-2
                                ${additionalProps}
                                w-auto
                                hover:shadow-lg
                            `}>
                                <div className="pr-36"></div>
                                <div className='flex justify-start font-bold'>
                                    {title ?
                                        title.render ?
                                            title.render(x[title.dataIndex], x)
                                            :
                                            x[title.dataIndex].replace(regex, "")
                                        :
                                        <></>
                                    }
                                </div>
                                <div className='flex justify-between space-x-10'>
                                    {data.length > 0 && data.map((z: any) => {
                                        return (
                                            <div key={Math.random()} className="animate-pulse whitespace-nowrap">
                                                {
                                                    z.render ?
                                                    z.render(x[z.dataIndex], x)
                                                        :
                                                    x[z.dataIndex]
                                                }
                                            </div>
                                            
                                        )
                                    })}
                                </div>
                                <div className="pt-4">
                                    {
                                        chartsLoading &&
                                        <div className="px-32 pb-16 pt-2">
                                            <component.ButtonLoader 
                                                isLoading={chartsLoading} 
                                                classNameProps={`
                                                    text-blueGray-900 
                                                    dark:text-blueGray-400   
                                                    w-10
                                                    h-10                                    
                                                `} 
                                            />
                                        </div>
                                    }
                                    {x.chartData ?
                                        checker(x.chartData) ?
                                            lengthChecker(x.chartData) ?
                                            <component.LineChart 
                                                color={color}
                                                chartsData={x.chartData}
                                            />
                                            :
                                            <div className='align-top items-center justify-center px-20 pb-12'>
                                                <div className='flex justify-center font-bold animate-pulse whitespace-nowrap'>
                                                    Collecting data ...
                                                </div>
                                            </div>
                                        :
                                        <div className='align-top items-center justify-center px-20 pb-12'>
                                            <div className='flex justify-center font-bold animate-pulse whitespace-nowrap'>
                                                No data available ...
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        }
    </>
  )
}

export default Snap