import component from '../../../components';
import { 
  CustomModalForm,
  FormModalProps,
  SharedModalProps,
  Stock,
  SubmitModalProps,
  WatchModalProps
} from "../../../types";
import currency from 'currency.js';

function SellAdditionalModal(
  props: 
    SharedModalProps & 
    FormModalProps & 
    WatchModalProps & 
    SubmitModalProps
) {

  const {
    open = false,
    closeModal = () => null,
    isLoading = false,
    register,
    handleSubmit,
    errors,
    submit,
    watch,
    stock,
} = props;
  const id = watch('id');

  const current = stock?.data.find((x:Stock) => x.id === id)

  const values = () => {
    if (current && current.sold.length > 0) {
      const n_of_shares = currency(current.sold.map((x: Stock) => x.shares).reduce((x: number, y: number) => x + y, 0), {precision: 2})
      return Math.abs(currency(n_of_shares, {precision: 2}).subtract(current.shares).value)
    } else if (current) {
        return Math.abs(currency(current.shares, {precision: 2}).value)
    }
    return undefined
  }

  const customForm: Array<CustomModalForm> = [
    {
      label: "Cost/Share",
      divProps: {
        className: "col-span-6 sm:col-span-6"
      },
      labelProps: {
        htmlFor: "sell_cost"
      },
      inputProps: {
        type: "number",
        step: ".000001",
        ...register('sell_cost', {required: true, min: 0})
      },
      children: errors.sell_cost &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    },
    {
      label: 'Shares',
      divProps: {
        className: "col-span-6 sm:col-span-6"
      },
      labelProps: {
        htmlFor: "sell_shares"
      },
      inputProps: {
        type: "number",
        min: "0",
        step: ".00001",
        ...register('sell_shares', {required: true, min: 0, max: values()}),
        placeholder: values() !== 0 ? `Max: ${values()}`: "Any Value",
      },
      children: errors.sell_shares && errors.sell_shares.type === "max" ?
      <p className='text-sm text-red-500'>
          Shares should be lower than {values()}
      </p>
      : errors.sell_shares &&
      <p className='text-sm text-red-500'>
          This field is required
      </p>
    },
    {
      label: "Trade Date",
      divProps: {
        className: "col-span-6 sm:col-span-6"
      },
      labelProps: {
        htmlFor: "sell_date"
      },
      inputProps: {
        type: "date",
        ...register('sell_date', {required: true}),
      },
      children: errors.sell_date &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    }
  ]



  return (
    <component.Modal title={`Sell ${stock?.ticker}`} open={open} closeModal={closeModal}>
      <div className="mt-3">
        <form name="add_subtract_stock" onSubmit={handleSubmit(submit)}>
          <div className="mt-6">
            <component.FormCustom customForm={customForm} />
            <component.SubmitModal 
              closeModal={closeModal}
              isLoading={isLoading}
              text={"Submit"}
            />
          </div>
        </form>
      </div>
    </component.Modal>
  )
}

export default SellAdditionalModal