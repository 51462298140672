import {Fragment} from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {CheckIcon, SelectorIcon} from '@heroicons/react/solid';
import {BasicProps} from '../../types';

export default function Select(props: BasicProps) {
  const {people = [], selected: select = {
    created_at: '',
    data: [],
    id: 0,
    name: '',
    user_id: 0,
  }, setSelected = () => null, position ="absolute"} = props;

  return (
    <div>
      <Listbox value={select} onChange={setSelected}>
        <div className="relative">
          <Listbox.Button
            className="
              relative
              w-full
              py-2
              pl-3
              h-9
              pr-10
              text-left
              bg-white
              rounded-lg
              shadow-md
              cursor-pointer
              focus:outline-none
              focus-visible:ring-2
              focus-visible:ring-opacity-75
              focus-visible:ring-white
              focus-visible:ring-offset-orange-300
              focus-visible:ring-offset-2
              focus-visible:border-indigo-500
              sm:text-sm
              dark:bg-gray-700  
              dark:text-white
            "
          >
            <span
              className="block truncate">
              {Object.keys(select).length > 0 && select.name}
            </span>
            <span
              className="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
                "
            >
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`
                ${position}
                w-full
                py-1
                mt-1
                overflow-auto
                text-base
                bg-white
                rounded-md
                shadow-lg
                max-h-60
                ring-1
                ring-black
                ring-opacity-5
                focus:outline-none
                sm:text-sm
                dark:bg-gray-700  
                dark:text-white
                z-10
                `}
            >
              {people.length > 0 && people.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({active}) =>
                    `${active ?
                      'text-amber-900 bg-amber-100 dark:bg-gray-900 dark:text-white z-50' :
                      'text-gray-900 dark:text-gray-400 z-50'
                    }
                    cursor-pointer select-none relative py-2 pl-10 pr-4`
                  }
                  value={person}
                >
                  {({selected}) => (
                    <>
                      <span
                        className={`${
                          (selected || select.name === person.name) ? 'font-medium' : 'font-normal'
                        } block truncate`}
                      >
                        {person.name}
                      </span>
                      <span
                        className="
                          text-amber-600
                          absolute
                          inset-y-0
                          left-0
                          flex
                          items-center
                          pl-3
                        "
                      >
                        {(selected || select.name === person.name) &&
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        }
                      </span>
                      
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
