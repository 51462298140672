import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AlertProps, AlertStatusProps} from '../../types';

const initialState = {
  alert: false,
  message: "",
  status: "",
} as AlertProps;

const alertSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<boolean>) => {
      state.alert = action.payload;
    },
    setAlertMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setAlertStatus: (state, action: PayloadAction<AlertStatusProps>) => {
      state.status = action.payload;
    },
    reset: () => initialState,
  },
});

export const {setAlert, setAlertMessage, setAlertStatus, reset} = alertSlice.actions;
export default alertSlice.reducer;
