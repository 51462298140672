import {api} from '../../api';
import {useMutation, useQuery} from 'react-query';
import {Payload, GooglePayload} from '../../types';
import {AxiosResponse} from 'axios';
import {FieldValues} from 'react-hook-form';

export const useUserSignUp = () => {
  const {
    mutate,
    isLoading,
    // eslint-disable-next-line
  } = useMutation<AxiosResponse<any, any>, unknown, Payload>(
      (payload) => api.signUp(payload),
  );

  return {mutate, isLoading};
};

export const useUserSignIn = () => {
  const {
    mutate,
    isLoading,
    // eslint-disable-next-line
  } = useMutation<AxiosResponse<any, any>, unknown, Payload>(
      (payload) => api.signIn(payload),
  );

  return {mutate, isLoading};
};

export const useResetPassword = () => {
  const {
    mutate,
    isLoading,
    // eslint-disable-next-line
  } = useMutation<AxiosResponse<any, any>, unknown, FieldValues>(
      (payload) => api.resetPassword(payload),
  );

  return {mutate, isLoading};
};

export const useResetUserPassword = () => {
  const {
    mutate,
    isLoading,
    // eslint-disable-next-line
  } = useMutation<AxiosResponse<any, any>, unknown, Payload>(
      (payload) => api.resetUserPassword(payload),
  );

  return {mutate, isLoading};
};

// eslint-disable-next-line
export const useGetUserInfo = (params: any) => {
  const {
    data,
    isLoading,
    isFetching,
    refetch,
    isError,
    error,
    isSuccess,
  } = useQuery(['reset-email', params], async () => {
    const {
      data: user,
    } = await api.getResetPassword(params.id);

    return user;
  });

  return {data, isLoading, isFetching, refetch, isError, error, isSuccess};
};

export const useGoogleLogin = () => {
  const {
    mutate,
    isLoading,
    // eslint-disable-next-line
  } = useMutation<AxiosResponse<any, any>, unknown, GooglePayload>(
      (payload) => api.googleLogin(payload),
  );

  return {mutate, isLoading};
};
