import type {RootState, AppDispatch} from './store/store';
import {
  setAdmin,
  setUser,
  setClient,
  setLoading,
  setIsLoggedIn,
  setUUID,
} from './store/reducers/authReducer';
import {connect, ConnectedProps} from 'react-redux';
import {
  UseFormReset,
  FieldValues,
  UseFormRegister,
  UseFormHandleSubmit,
  SubmitHandler,
  Control,
  UseFormWatch,
} from 'react-hook-form';
import {
  setAlert,
  setAlertMessage,
  setAlertStatus
} from './store/reducers/alertReducer';
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
} from 'react-query';
import { setCurrency } from './store/reducers/financeReducer';
import { 
  BubbleDataPoint, 
  ChartData,
  ScatterDataPoint, 
//   ChartOptions
} from 'chart.js';

export type clicker = (e?: any, a?: any) => any;

export interface ProcessDataYears {
    name: string;
    value: string;
}

export interface ProcessDataProps {
    data: Record<string, any>;
    years: Array<ProcessDataYears>;
}

export interface ListTableProps {
    data?: ProcessDataProps;
    filtered?: LoanData
    fulldata?: Array<LoanData>
    click?: clicker
    years: {
        name: string;
        value: string;
    },
    loading: boolean;
    id?: string;
}

export interface TooltipProps {
    children?: JSX.Element | JSX.Element[] | React.ReactNode;
    message?: string;
}

export interface User {
    id?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    login_attributes?: string;
    is_superuser?: boolean;
    type?: string;
}

export interface AuthState {
    isLoggedIn: boolean,
    // eslint-disable-next-line
    user: User | Record<string, any>,
    isLoading: boolean,
    isClient: boolean,
    isAdmin: boolean,
    uuid: string,
}

export interface MapStateToProps {
    auth: AuthState
}

export type Loan = {
    interest_rate: number;
    total_amount: number;
    years: number;
    months: number;
} & LoanDataDetails

export type RenderData = Loan & {
    name: string;
    month: string;
    year: string;
}

export interface ColumnRender {
    title: string;
    dataIndex: string;
    // eslint-disable-next-line
    render?: (x: string, y: Record<string, any>) => JSX.Element;
}

type Shift<A extends Array<any>> = 
  ((...args: A) => void) extends ((...args: [A[0], ...infer R]) => void) ? R : never;

type GrowExpRev<A extends Array<any>, N extends number, P extends Array<Array<any>>> = A['length'] extends N ? A : {
  0: GrowExpRev<[...A, ...P[0]], N, P>,
  1: GrowExpRev<A, N, Shift<P>>
}[[...A, ...P[0]][N] extends undefined ? 0 : 1];

type GrowExp<A extends Array<any>, N extends number, P extends Array<Array<any>>> = A['length'] extends N ? A : {
  0: GrowExp<[...A, ...A], N, [A, ...P]>,
  1: GrowExpRev<A, N, P>
}[[...A, ...A][N] extends undefined ? 0 : 1];

type MapItemType<T, I> = { [K in keyof T]: I };

export type FixedSizeArray<T, N extends number> = 
    N extends 0 ? [] : MapItemType<GrowExp<[0], N, []>, T>;


export interface ShortenTableProps {
    data?: Array<DataRender>;
    column: Array<ColumnRender>;
    shortenColumn: FixedSizeArray<ShortColumnRender, 2>
    click?: clicker
}

export interface ShortColumnRender {
    dataIndex: string;
    // eslint-disable-next-line
    render?: (x: string, y: Record<string, any>) => JSX.Element;
}

// eslint-disable-next-line
export type DataRender = Record<string, any>


// Types for All Modal
export interface SharedModalProps {
    open?: boolean;
    isLoading?: boolean;
    // eslint-disable-next-line
    closeModal: (e: any) => void;
    loanName?: string;
    title?: string;
    name?: string;
    buttonName?: string;
    stock?: Record<string, any>;
    clicks?: (e: any) => void;
    status?: boolean;
    deleteSubmit?: (e: any) => void;
    deleteLoading?: boolean;
    tickerLoading?: boolean;
    ticker?: string;
    setTicker?: React.Dispatch<React.SetStateAction<string>>
    stockSelection?: Array<Record<string, any>>;
    setStockSelection?: React.Dispatch<React.SetStateAction<never[]>>
    searchTicker?: (data: any) => any;
    selectedStock?: Record<string, any> 
    setSelectedStock?: React.Dispatch<React.SetStateAction<Record<string, any>>>
    text?: string;
    type?: "reset" | "button" | "submit" | undefined;
    onClick?: (data: any) => any;
    colorSubmit?: string;
    colorClose?: string;
    disabled?: boolean;
    children?: JSX.Element | JSX.Element[] | React.ReactNode;
    hoverRef?: any;
    classNameProps?: string;
}

export interface FormModalProps {
    register:  UseFormRegister<FieldValues>;
    handleSubmit: UseFormHandleSubmit<FieldValues>;
    errors:  Record<string, any>;
}

export interface SubmitModalProps {
    submit: SubmitHandler<FieldValues>;
}

export interface WatchModalProps {
    watch: UseFormWatch<FieldValues>;
    minimum?: number;
}

export interface ControlModalProps {
    control: Control<FieldValues>;
}

export interface FormCustom { 
    customForm: Array<CustomModalForm>
}

// Types for Custom Modal Input Props
export interface CustomModalForm {
    children?: JSX.Element | JSX.Element[] | React.ReactNode;
    inputProps: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
    labelProps: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>
    label?: JSX.Element | JSX.Element[] | React.ReactNode;
    divProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    custom?: boolean;
    inputClassName?: string;
}

export interface ThemeProviderProps {
    initialTheme?: string;
    children?: JSX.Element | JSX.Element[] | React.ReactNode;
}

export type ThemeContextType = {
  theme: string;
  changeTheme: (theme: string) => void;
};

export type ThemeProviderFunction = (response: ThemeProviderProps) => any;

export type AlertStatusProps = "info" | "error" | "success" | "";

export interface AlertProps {
    alert: boolean;
    message: string;
    status: AlertStatusProps;
} 

export type RefetchQuery = <TPageData>(options?: (
    RefetchOptions & RefetchQueryFilters<TPageData>
    // eslint-disable-next-line
) | undefined) => Promise<QueryObserverResult<any, unknown>>;

export interface BasicProps {
    position?: string;
    refetch?: RefetchQuery;
    classNameProps?: string;
    isLoading?: boolean;
    className?: string;
    data?: Array<DataRender>;
    column?: Array<ColumnRender>
    loading?: boolean;
    people?: Array<LoanData> | Array<Record<string, any>>;
    selected?: LoanData | {name: string} | Record<string, any>;
    // eslint-disable-next-line
    setSelected?: (e: any) => any;
    open?: boolean;
    title?: string;
    children?: JSX.Element | JSX.Element[] | React.ReactNode;
    header?: string;
    link?: string;
    to?: string;
    reset?: UseFormReset<FieldValues>;
    id?: string;
    // eslint-disable-next-line
    setOpen?: (e: any) => any;
    // eslint-disable-next-line
    click?: clicker
    text?: string;
    icondata?: Record<string, any>
}

export interface GoogleProps {
    // eslint-disable-next-line
    setAdmin: (admin: boolean) => any ;
    // eslint-disable-next-line
    setIsLoggedIn: (isLoggedIn: boolean) => any;
    // eslint-disable-next-line
    setUser: (login: User | Record<string, any>) => any;
}

type PropsFromRedux = ConnectedProps<typeof connector>

export type Props = PropsFromRedux & BasicProps

export interface NavProps {
    userLogout: (e: any) => any;
}

const mapStateToProps = (state: RootState) => {
  return {
    ...state.auth,
    ...state.alert,
    ...state.finance,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    // dispatching plain actions
    setAlert: (alert: boolean) => dispatch(setAlert(alert)),
    setAlertMessage: (message: string) => dispatch(setAlertMessage(message)),
    setAlertStatus: (status: AlertStatusProps) => dispatch(setAlertStatus(status)),
    setCurrency: (currency: Conversion) => dispatch(setCurrency(currency)),
    // eslint-disable-next-line
    setUser: (login: User | Record<string, any>) => dispatch(setUser(login)),
    setIsLoggedIn: (isLoggedIn: boolean) => dispatch(setIsLoggedIn(isLoggedIn)),
    setAdmin: (admin: boolean) => dispatch(setAdmin(admin)),
    setLoading: (loading: boolean) => dispatch(setLoading(loading)),
    setClient: (client: boolean) => dispatch(setClient(client)),
    setUUID: (uuid: string) => dispatch(setUUID(uuid))
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export interface Payload {
    userInformation: string,
}

export interface GooglePayload {
    token_id: string,
}

export interface ResetPassPayload {
    email: string,
}
export interface GoogleResponse {
    clientId: string;
    client_id: string;
    credential: string;
    select_by: string;
}

export type GoogleSuccess = (
    response: GoogleResponse
) => void

export interface LoanUpdateStatus {
    data_id: string
}

export type LoanData = {
    id: string
    name: string
    created_at: string
    user_id: number
    total_amount: number
    loan_type: string
    total_with_interest: number
    interest_total: number
    interest_rate: number
    years: number
    months: number
    data: Array<Loan>
} & LoanUpdateStatus

export interface LoanDataDetails {
    id?: string
    date: string
    payment: number
    principal: number
    interest: number
    ending_balance: number
    status: string
    paid_amount: number
    extra_payment: number
}

export interface Stock {
    date: string;
    cost: number;
    shares: number;
    id: number;
    sell?: boolean;
    sell_id?: number;
    pnl?: number;
    sold?: Array<Stock>;
    ori_shares?: number;
}

export interface StockData {
    created_at: string | undefined;
    data: Array<Stock>;
    id: string;
    name: string;
    exch: string;
    symbol: string;
    currency: string;
    price: number;
    ticker: string;
    rate: number;
    user_id: number;
}

export interface SignIn {
    email: string;
    password: string;
    rememberme: boolean,
}

export interface Conversion {
    name: string;
    value: string;
    rate: number;
}

export interface FinanceProps {
    currency: Conversion
}

export interface DividendsPayload {
    success: boolean;
    cache: boolean;
    data: string;
}

export interface Dividends {
    Date: string;
    Dividends: number;
}

export interface DividendsLineChart {
    labels: Array<string>;
    data: Array<number>;
    ori_data: Array<Dividends> 
}

export type SnapRender = ShortColumnRender & {
    type: string;
} | undefined

export interface SnapProps {
    customSnap: Array<DataRender>,
    shortenColumn: FixedSizeArray<SnapRender, 3>,
    click?: clicker
    chartsLoading?: boolean;
    selectedDelete?: Array<DataRender>,
    additionalProps?: string;
    additionalClick?: clicker
}

export interface IconsProps {
    keys: string;
}

export interface LoanDetailsIDPayload { 
    data: LoanData,
    interestLeft: number,
    endingBalance: number,
    paidExtra: number,
    interestPaid: number,
    color: string,
}

export interface ChartProps {
    color: string;
    chartsData: ChartData<"line", (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>;
    className?: string;
    // chartOptions: ChartOptions<"line">;
}

export interface DashProps {
    color?: string;
    labelRender: JSX.Element | JSX.Element[] | React.ReactNode;
    children: JSX.Element | JSX.Element[] | React.ReactNode;
}

export interface SummarizerProps {
    endingBalance: number
    interestLeft: number
    name: string
    color: string
}

export interface ButtonProps {
    onClick: (e: any) => any
    type: 'submit' | 'reset' | 'button' | undefined;
    children: JSX.Element | JSX.Element[] | React.ReactNode;
    className: string;
}

export interface LoanIconProps {
    status: string;
}