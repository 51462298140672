import {useContext, useMemo, useState} from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import {PolarArea, Pie, Bar} from 'react-chartjs-2';
import {useGetLoanDetails} from '../loan/queryLoan';
import moment from 'moment';
import chroma from 'chroma-js';
import groupby from 'lodash/groupBy';
import {ColumnRender, connector, Conversion, Props, RenderData, ThemeContextType} from '../../types';
import { ThemeContext } from '../../theme/ThemeContext';
import functions from '../../function';
import component from '../../components';
import currency from 'currency.js';

ChartJS.register(
    RadialLinearScale,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);
function Dashboard(props: Props) {
  const currencyLabel: Conversion = JSON.parse(localStorage.getItem("currency")!)
  const [todayDate, setTodayDate] = useState({
    name: moment().format('YYYY'),
    value: moment().format('YYYY')
  });
  const {theme} = useContext(ThemeContext) as ThemeContextType;

  const {data, isLoading: dataLoading} = useGetLoanDetails(undefined, "", props.user);

  const column: Array<ColumnRender> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: () => (
          <div
              className="text-center text-sm text-inherit"
          >
              {moment().format("YYYY-MM-DD")}
          </div>
      )
    },
    {
      title: 'Monthly payment',
      dataIndex: 'payment',
      render: (payment, row) => {
          return (
              <div
                  className="text-center text-sm text-inherit"
              >
              {functions.currencyConversion(parseFloat(payment))}
              </div>
          )
      },
    },
    {
        title: 'Interest charged',
        dataIndex: 'interest',
        render: (interest) => (
            <div
            className="text-center text-sm  text-inherit"
            >
            {functions.currencyConversion(parseFloat(interest))}
            </div>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',

    },
    {
      title: 'Paid',
      dataIndex: 'paid_amount',
      render: (_, row) => (
        <div
          className="text-center text-sm  text-inherit"
        >
          {functions.currencyConversion(row.paid_amount)}
        </div>
      ),
    },
  ];

  const months = useMemo(() => {
    return [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
  }, []);

  const datasets = useMemo(() => {
    if (data) {
      const title = data.map((x) => x.data.name);

      const payment = data.map((x) => (
        {...x, data: {
          ...x.data,
          data: x.data.data.filter((zz) => zz.status === 'paid')
        }}
      ));

      const unpaid = data.map((x) => (
        {...x, data: {
          ...x.data,
          data: x.data.data.filter((zz) => zz.status === 'unpaid')
        }}
      ));

      const years = unpaid.map((x) => x.data.data.length > 0 ?
        (x.data.data.length) / 12 : 0 );

      const amount = data.map((x) => x.data.total_with_interest);

      const paid = data.map((x) => {
        const payment = x.data.data
                         .map(z => z.paid_amount)
                         .reduce((x, y) => currency(x!, {precision: 2}).add(y!).value, 0)?? 0
        return currency(payment, {precision: 2}).value
      });

      const paidTotal = data.map((x) => {
        const oweAmount = x.data.data
                            .filter(z => z.status === "unpaid")
                            .map(z => z.payment)
                            .reduce((x, y) => currency(x!, {precision: 2}).add(y!).value, 0)?? 0
        return oweAmount
      });

    const interestpaid = data.map(x => x.interestPaid).reduce((x, y) => currency(x!, {precision: 2}).add(y!).value, 0)?? 0

      const allPayment: Array<RenderData> = [];

      // eslint-disable-next-line
      let totalYears: Array<any> = [];

      payment.forEach((x) => {
        const withNaming = x.data.data.map((z) => ({
          ...z, 
          name: x.data.name,
          month: moment(z.date).format('MMM'),
          year: moment(z.date).format('YYYY'),
        }));

        withNaming.forEach((z) => totalYears.push(z.year));

        const filter = withNaming.filter((z) =>
          moment(z.date).format('YYYY') === todayDate.name);

        filter.forEach((z) => {
          allPayment.push(z);
        });
      });

      totalYears = totalYears.filter((v, i, a) =>
        a.indexOf(v) === i).sort((a, b) => parseInt(a) - parseInt(b));

      totalYears = totalYears.map((x) => ({name: x}));

      const itemGroup = groupby(allPayment, (x) => x.name);

      const obj = Object.keys(itemGroup).map((x) => {
        const fil = itemGroup[x].map((z) => ({[z.month]: z['paid_amount']}));

        // eslint-disable-next-line
        const item: Array<any> = [];
        months.forEach((c) => {
          const f = itemGroup[x].filter((z) => z.month === c);
          if (f.length === 0) {
            item.push(0);
          } else {
            if (fil.filter((s) => s[c]).length >0) {
              item.push(fil.filter((s) => s[c])[0][c]);
            } else {
              item.push(0)
            }
          }
        });

        return ({
          label: x,
          data: item.map(z => functions.currencyConversion(z)),
          backgroundColor: chroma.random().css('hsl'),
        });
      });

      return {
        title,
        years,
        amount,
        paid,
        interestpaid,
        paidDetails: allPayment,
        barChart: obj,
        selection: totalYears,
        paidTotal,
      };
    } else {
      return {
        title: [],
        years: [],
        amount: [],
        paid: [],
        interestpaid: 0,
        paidDetails: [],
        barChart: [],
        selection: [],
        paidTotal: [],
      };
    }
  }, [data, months, todayDate]);

  if (datasets.selection.length > 0) {
    const current_year = datasets.selection.filter(x => x.value === todayDate.value)

    if (current_year.length === 0) {
        setTodayDate(datasets.selection[0])
    }
  }


  if (dataLoading) {
    return (
      <component.Loading />
    );
  }

  return (
    <div>
      <div className='px-4 py-10 md:px-10 grid grid-cols-10 gap-5'>
      <div className='col-span-10 md:col-span-4'>
          <div className="grid grid-cols-4 gap-5">
            <div className='
              bg-white
              hover:bg-slate-50
              dark:bg-gray-900
              shadow-md
              rounded-md
              p-3
              col-span-4
              md:col-span-2
              xsizs:col-span-10
            '
          >
            <div
              className='
                flex
                w-full
                items-center
                justify-center
                text-center
                align-baseline
              '
            >
              <div>
                <label
                  className='text-lg font-bold text-primary'
                >
                    Total amount of loan
                </label>
                <div
                  className="
                    text-xl
                    font-bold
                    mt-[20%]
                    mb-[20%]
                    lg:mt-[10%]
                    lg:mb-[10%]
                    text-secondary
                  "
                >
                  {
                    functions.currencyConversion(
                      datasets.amount
                              .reduce((x, y) => currency(x!, {precision: 2})
                              .add(y!).value, 0)?? 0
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='
            bg-white
            hover:bg-slate-50
            dark:bg-gray-900
            shadow-md
            rounded-md
            p-3
            col-span-6
            md:col-span-2
            xsizs:col-span-10
            '
          >
            <div
              className='
                flex
                w-full
                items-center
                justify-center
                text-center
                align-baseline
              '
            >
              <div>
                <label
                  className='text-lg font-bold text-primary'
                >
                    Total amount left
                </label>
                <div
                  className="
                    text-xl
                    font-bold
                    mt-[20%]
                    mb-[20%]
                    lg:mt-[10%]
                    lg:mb-[10%]
                    text-secondary
                  "
                >
                  {
                    functions.currencyConversion(
                        datasets.paidTotal.reduce((x, y) => currency(x!, {precision: 2}).add(y!).value, 0)?? 0,
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='
            bg-white
            hover:bg-slate-50
            dark:bg-gray-900
            shadow-md
            rounded-md
            p-3
            col-span-4
            md:col-span-2
            xsizs:col-span-10
            '
          >
            <div
              className='
                flex
                items-center
                justify-center
                text-center
                align-baseline
                '
            >
              <div className='m-auto'>
                <label className='text-lg font-bold text-primary text-center'>
                  Total interest charged
                </label>
                <div
                  className="
                    text-xl
                    font-bold
                    mt-[10%]
                    mb-[10%]
                    text-secondary
                  "
                >
                  {
                    functions.currencyConversion(datasets.interestpaid?? 0)
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='
            bg-white
            hover:bg-slate-50
            dark:bg-gray-900
            shadow-md
            rounded-md
            p-3
            col-span-6
            md:col-span-2
            xsizs:col-span-10
            '
          >
            <div
              className='
                flex
                items-center
                justify-center
                text-center
                align-baseline
              '
            >
              <div className='m-auto'>
                <label
                  className='text-lg font-bold text-primary'
                >
                  Total paid
                </label>
                <div className="text-xl font-bold mt-[20%] text-secondary ">
                  {
                    functions.currencyConversion(
                        datasets.paid.reduce((partialSum, a) => partialSum + a, 0),
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div
            className='
              bg-white
              hover:bg-slate-50
              dark:bg-gray-900
              shadow-md
              rounded-md
              p-3
              col-span-10
              sizs:col-span-4
              md:col-span-5
            '
          >
            <div
              className='
                flex
                w-full
                items-center
                justify-center
                text-center
                align-baseline
              '
            >
              <div className='m-auto'>
                <label
                  className='text-lg font-bold text-primary'
                >
                  Loan end date
                </label>
                <div>
                  {data && data.length > 0 && data.map((x) => (
                    <div className="flex text-xs text-secondary " key={x.data.id}>
                      <div className="flex w-full">
                        <div
                          className="flex-1 pr-3 text-left font-semibold"
                        >
                          {x.data.name}
                        </div>
                        <div
                          className="flex-1 px-3 text-right"
                        >
                          {
                            moment(
                              
                                // x.data[x.data.length - 1]['date'],
                            ).format('YYYY, MMM')
                          }
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          </div>
        </div>
        <div className='
            bg-white
            hover:bg-slate-50
            dark:bg-gray-900
            shadow-md
            rounded-md
            p-3
            col-span-10
            md:col-span-3
            '
          >
            {datasets && data &&
              <PolarArea
                datasetIdKey='id'
                data={{
                  labels: [...datasets.title],
                  datasets: [
                    {
                      label: ' # months left',
                      data: datasets.years,
                      backgroundColor: data.map(() => chroma.random().css('hsl')),
                      borderWidth: 1,
                    }],
                }}
                options={{
                  borderColor: "#4b5563",
                  scales: {
                    r: {
                      grid: {
                        color: theme === "dark" ? "#4b5563" : "#e5e7eb",
                      },
                      ticks: {
                        color: "#0d9488",
                        backdropColor: "transparent",
                      }
                    }
                  },
                  plugins: {
                    title: {
                      display: true,
                      color: "#0284c7",
                      text: "Number of months left",
                      font: {
                        size: 18
                      }
                    },
                    tooltip: {
                      callbacks: {
                        // eslint-disable-next-line
                        label: function(context: Record<string, any>) {
                          let label = context.dataset.label || '';
                          label = label.replace('#', context.raw * 12);
                          label = `${context.label}: ${label}`;
                          return label;
                        },
                      },
                    },
                    legend: {
                      labels: {
                        color: theme === "dark" ? "#e5e7eb" : "#71757c",
                      }
                    }
                  },
                }}
              />
            }
          </div>
          <div
            className='
              bg-white
              hover:bg-slate-50
              dark:bg-gray-900
              shadow-md
              rounded-md
              p-3
              col-span-10
              md:col-span-3
            '
          >
            {datasets && data &&
              <Pie
                datasetIdKey='id'
                data={{
                  labels: [...datasets.title],
                  datasets: [
                    {
                      label: `${currencyLabel.name} #`,
                      data: datasets.amount.map(x => functions.currencyConversion(x)),
                      backgroundColor: data.map(() => chroma.random().css('hsl')),
                      borderWidth: 1,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    title: {
                      display: true,
                      color: "#0284c7",
                      text: "Summary of loan",
                      font: {
                        size: 18
                      }
                    },
                    tooltip: {
                      callbacks: {
                        // eslint-disable-next-line
                        label: function(context: Record<string, any>) {
                          let label = context.dataset.label || '';
                          label = label.replace('#', context.raw);
                          label = `${context.label}: ${label}`;
                          return label;
                        },
                      },
                    },
                    legend: {
                      labels: {
                        color: theme === "dark" ? "#e5e7eb" : "#71757c",
                      }
                    }
                  },
                }}
              />
            }
          </div>
      </div>
      <div className="border-t-4 px-4"></div>
      <div className="flex justify-end items-end align-middle">
        <div className='space-x-3 px-4 md:px-10 pt-5 w-full md:w-72'>
          {datasets && datasets.selection.length > 0 &&
            <component.Select
              people={datasets.selection}
              selected={todayDate}
              setSelected={setTodayDate}
            />
          }
        </div>
      </div>
      <div className="px-4 pt-10 md:px-10">
        <div className="bg-white hover:bg-slate-50 shadow-md rounded-md p-3 col-span-10 dark:bg-gray-900">
          <div className='m-auto'>
            <label
              className='justify-center text-lg font-bold text-primary text-center'
            >
              Monthly paid for year {todayDate.name}
            </label>
          </div>
          <div>
            <Bar
              data={{
                labels: months,
                datasets: datasets.barChart,
              }}
              options={{
                layout: {
                  padding: 20
                },
                responsive: true,
                scales: {
                    x: {
                      stacked: true,
                      ticks:{
                        padding: 0
                      },
                      grid: {
                        display: false
                      },
                    },
                    y: {
                      display: false,
                      stacked: true,
                    }
                },
                plugins: {
                    title: {
                        display: false
                    },
                    tooltip: {
                      callbacks: {
                        label: function(context) {
                          let label = context.dataset.label || '';

                          label = `${label}: ${currencyLabel.name} ${context.raw}`;

                          return label;
                        },
                      },
                    },
                    legend: {
                      position: 'right',
                      labels: {
                        padding: 3,
                        boxWidth: 20,
                        color: theme === "dark" ? "#e5e7eb" : "#71757c",
                        font: {
                          size: 10
                        }
                      }
                    }
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="px-4 md:px-10 pb-10 grid grid-cols-10 gap-3">
        <div className='bg-white hover:bg-slate-50 shadow-md rounded-md p-3 col-span-10 mt-10 dark:bg-gray-900'>
          <div
          className='
            flex
            items-center
            justify-center
            text-center
            align-baseline
            pt-3
          '
        >
          <div className='m-auto'>
            <label
              className='justify-center text-lg font-bold text-primary text-center'
            >
              Loan Paid for year {todayDate.name}
            </label>
          </div>
        </div>
          <component.Table
            classNameProps="mt-5 dark:bg-gray-900"
            data={datasets.paidDetails}
            column={column}
          />
        </div>
      </div>
    </div>
  );
}

export default connector(Dashboard);
