import {useGoogleLogin} from './mutateAuth';
import {useNavigate} from 'react-router-dom';
import {Props, User, ThemeContextType} from '../../types';
import { useCallback, useContext, useEffect } from 'react';
import component from '../../components';
import { ThemeContext } from '../../theme/ThemeContext';
import {CredentialResponse} from 'google-one-tap'

function Google(props: Props) {
  const {setAdmin, setIsLoggedIn, setUser} = props;

  const {theme} = useContext(ThemeContext) as ThemeContextType;

  const {mutate, isLoading} = useGoogleLogin();

  const navigate = useNavigate();

  const success = useCallback((data: User) => {
    if (data.is_superuser) {
      setAdmin(true);
    }
    setUser(data);
    setIsLoggedIn(true);
    navigate('/');
  }, [navigate, setUser, setIsLoggedIn, setAdmin]);

  const onSuccess = useCallback(( response: CredentialResponse ) => {
    if ('credential' in response) {
      const userData = {
        token_id: response.credential,
      };

      mutate(userData, {
        onSuccess: ({data}) => {
          if (data.success) {
            success(data.user[0]);
          } else {
            console.log(data.message);
          }
        },
      });
    }
  }, [mutate, success]);

  const clientID = process.env.REACT_APP_GOOGLE_CLIENT_ID ?
    process.env.REACT_APP_GOOGLE_CLIENT_ID : '';

  useEffect(() => {
    if (google) {
      google.accounts.id.initialize({
        client_id: clientID,
        ux_mode: "popup",
        itp_support: true,
        auto_select: true,
        callback: onSuccess
      });

      google.accounts.id.prompt();

      const googleLoginDiv: HTMLElement = document.getElementById("loginDiv")!;

      google.accounts.id.renderButton(googleLoginDiv, {
        type: "standard",
        theme: theme === "dark" ? "filled_black" : "outline",
        size: "large",
        text: "continue_with",
        shape: "pill",
        width: 200,
      });

    }
  }, [onSuccess, clientID, theme])

  return (
    <div>
      {
        isLoading ? 
          <div className='flex justify-start text-blueGray-500 dark:text-gray-400 align-baseline'>
            <component.ButtonLoader isLoading={isLoading} classNameProps='mt-1 h-8 w-8' />
          </div>
        : 
        <div id="loginDiv" className='w-full' />
      }
    </div>
  );
}

export default Google;
