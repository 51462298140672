import {AxiosResponse} from 'axios';
import { useMutation } from "react-query";
import { api } from "../../api";
import { Payload } from '../../types';

export const useReadChange = () => {
    const {
      mutate,
      isLoading,
      // eslint-disable-next-line
    } = useMutation<AxiosResponse<any, any>, unknown, Array<any>, unknown>(
        (payload) => api.notificationReadChange(payload[0], payload[1]),
    );
  
    return {mutate, isLoading};
};

export const useViewChange = () => {
  const {
    mutate,
    isLoading,
    // eslint-disable-next-line
  } = useMutation<AxiosResponse<any, any>, unknown, Payload, unknown>(
      (payload) => api.notificationViewChange(payload),
  );

  return {mutate, isLoading};
};