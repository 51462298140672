import { Conversion } from "../../types";
import curren from 'currency.js'

export const currencyConversion = (
    amount: number,
    prefix: boolean = false,
    param: Record<string, any> = {},
    format: boolean = false,
    formatter: boolean = false,
) => {
    let formats = Intl.NumberFormat('en', { notation: 'compact' });
    
    const currency: Conversion = JSON.parse(localStorage.getItem('currency')!);
    if (prefix) {
        return `${currency.name} ${curren(amount).multiply(currency.rate).value}`
    }

    if (formatter) {
        return `${currency.name} ${formats.format(curren(amount).multiply(currency.rate).value)}`
    }

    if (format) {
        return curren(amount, {precision: 2, ...param}).multiply(currency.rate).format()
    }

    return curren(amount, {precision: 2, ...param}).multiply(currency.rate).value
}

export const currencyDefault = (amount: number, param: Record<string, any> = {}) => {
    const currency: Conversion = JSON.parse(localStorage.getItem('currency')!);

    return curren(amount, {precision: 2, ...param}).divide(currency.rate).value
}

export const convertAmount = (amount: number, rate: number, param: Record<string, any> = {}) => {
    return curren(amount, {precision: 2, ...param}).multiply(rate).value
}

export const convertBackAmount = (amount: number, rate: number, param: Record<string, any> = {}) => {
    return curren(amount, {precision: 2, ...param}).divide(rate)
}

const conversion = {
    currencyConversion,
    currencyDefault,
    convertAmount,
    convertBackAmount
}

export default conversion;