import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import './assets/styles/index.css';
import App from './App';
import {QueryClient, QueryClientProvider} from 'react-query';
import {Provider} from 'react-redux';
import {store} from './store/store';
import {createRoot} from 'react-dom/client';
import {ThemeProvider} from './theme/ThemeContext';
import Background from './theme/Background';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      // staleTime: twentyFourHoursInMs,
    },
  },
});

// eslint-disable-next-line
const container = document.getElementById('root')! as HTMLElement;

// createRoot(container!) if you use TypeScript
const root = createRoot(container);
root.render(
    <React.StrictMode>
      <Router>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider>
              <Background>
                <App />
              </Background>           
            </ThemeProvider>
          </QueryClientProvider>
        </Provider>
      </Router>
    </React.StrictMode>,

);
