import component from '../../../components';
import { 
  CustomModalForm,
    FormModalProps,
    SharedModalProps,
    SubmitModalProps
} from '../../../types';

function AddStockModal(
    props: 
        SharedModalProps & 
        SubmitModalProps & 
        FormModalProps
) {
    
    const {
        open = false,
        closeModal = () => null,
        isLoading = false,
        register,
        handleSubmit,
        errors,
        submit,
        tickerLoading,
        ticker, 
        setTicker = () => null,
        stockSelection = [{}],
        setStockSelection = () => null,
        searchTicker = () => null,
        selectedStock = {},
        setSelectedStock = () => null,
    } = props;

  const customForm: Array<CustomModalForm> = [
    {
      label: "Cost/Share",
      divProps: {
        className: "col-span-6 sm:col-span-6"
      },
      labelProps: {
          htmlFor: "cost"
      },
      inputProps: {
          type: "number",
          step: ".000001",
          ...register('cost', {required: true, min: 0}),
      },
      children: errors.cost &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    },
    {
      label: "Shares",
      divProps: {
        className: "col-span-6 sm:col-span-6"
      },
      labelProps: {
          htmlFor: "shares"
      },
      inputProps: {
          type: "number",
          min: "0",
          step: ".00001",
          ...register('shares', {required: true, min: 0})
      },
      children: errors.shares &&
      <p className='text-sm text-red-500'>
      This field is required
      </p>
    },
    {
      label: "Trade Date",
      divProps: {
        className: "col-span-6 sm:col-span-6"
      },
      labelProps: {
          htmlFor: "date"
      },
      inputProps: {
          type: "date",
          ...register('date', {required: true}),
      },
      children: errors.date &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    }
  ]

  return (
    <component.Modal title="Add a Stock" open={open} closeModal={closeModal}>
        <div>
            <form name="search_stock" onSubmit={searchTicker}>
                <div className='flex space-x-3 mt-2 w-full'>
                    <component.ModalCustom
                        divProps={{
                            className: 'w-full' 
                        }}
                        labelProps={{
                            htmlFor: "stocks"
                        }}
                        label="Stock Name"
                        inputProps={{
                            required: true,
                            type: "text",
                            value: ticker,
                            onChange: (e) => {
                                setStockSelection([])
                                setSelectedStock({})
                                setTicker(e.target.value)
                            }
                        }}
                    >
                    </component.ModalCustom>
                    <div className='mt-6'>
                        <button 
                            type='submit'
                            className='inline-flex
                                        align-middle 
                                        justify-center
                                        px-4
                                        py-2
                                        text-sm
                                        font-medium
                                        border
                                        border-transparent
                                        rounded-md
                                        focus:outline-none
                                        focus-visible:ring-2
                                        focus-visible:ring-offset-2
                                        text-blue-900
                                        bg-blue-100
                                        hover:bg-blue-200
                                        focus-visible:ring-blue-500
                                    '
                            >
                                <component.ButtonLoader isLoading={tickerLoading} classNameProps="text-inherit" />
                                Search
                        </button>
                    </div>
                </div>
            </form>
            
            <div className='mt-4'>
                {stockSelection.length > 0 &&
                    <>
                        <label
                            htmlFor="cost"
                            className="block mb-1 text-sm font-medium text-blueGray-700 dark:text-gray-400"
                        >
                            Stock
                        </label>
                        <component.Select 
                            position={Object.keys(selectedStock).length > 0 ? "absolute" : "relative"}
                            people={stockSelection}
                            selected={selectedStock!}
                            setSelected={setSelectedStock}
                        />
                    </>
                    
                }
            </div>
            {Object.keys(selectedStock).length > 0 &&
                <div>
                    <form name="add_stock" onSubmit={handleSubmit(submit)}>
                        <div className="mt-6">
                            <div className="grid grid-cols-6 gap-6">
                              <component.FormCustom customForm={customForm} />
                            </div>
                        </div>
                        <component.SubmitModal 
                            closeModal={closeModal}
                            isLoading={isLoading}
                            text="Add"
                        />
                    </form>
                </div>
            }
        </div>
    </component.Modal>
  )
}

export default AddStockModal

