import {useController} from 'react-hook-form';
import {
  ControlModalProps,
  Conversion,
  CustomModalForm,
  FormModalProps,
  SharedModalProps,
  SubmitModalProps,
  WatchModalProps
} from '../../../types';
import component from '../../../components';

function ExtraPaidLoanModal(props: SharedModalProps &  FormModalProps & SubmitModalProps & WatchModalProps & ControlModalProps) {
  const currency: Conversion = JSON.parse(localStorage.getItem("currency")!)
  const {
    open = false,
    closeModal = () => null,
    isLoading = false,
    register,
    handleSubmit,
    errors,
    submit,
    watch,
    control
  } = props;

  const {field} = useController({
    defaultValue: true,
    control,
    name: 'auto_amount',
  });

  const autoAmount: boolean = watch('auto_amount');

  const customForm: Array<CustomModalForm> = [
    {
      divProps: {
        "className": "col-span-6 sm:col-span-6"
      },
      labelProps: {
        "htmlFor": "payment_date"
      },
      inputProps: {
        type: "text",
        ...register('payment_date', {required: true}),
        disabled: true,
      },
      label: "Date",
      children: errors['payment_date'] &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    },
    {
      divProps: {
        "className": "col-span-6 sm:col-span-6"
      },
      labelProps: {
        "htmlFor": "amount_required"
      },
      inputProps: {
        type: "number",
        step: ".01",
        ...register('amount_required', {required: true}),               
        disabled: true,
      },
      label: `Total Amount (${currency.name})`,
      children: errors['amount_required'] &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    },
    {
      divProps: {
        "className": "col-span-6 sm:col-span-6"
      },
      labelProps: {
        "htmlFor": "auto_amount",
        "className": "pr-3 text-sm font-medium text-blueGray-700 dark:text-gray-400"
      },
      label: "Auto Amount",
      inputProps: {
        ...field,
        type: "checkbox",
        defaultChecked: true,
        className: `
          h-4 w-4
          text-secondary
          focus:ring-primary
          border-gray-300
          rounded
        `
      },
    },
    {
      custom: autoAmount,
      divProps: {
        "className": "col-span-6 sm:col-span-6"
      },
      labelProps: {
        htmlFor: "paid_amount"
      },
      inputProps: {
        type: "number",
        step: ".01",
        min: 0,
        ...register('paid_amount', {required: !autoAmount, min: 0})
      },
      label: `Paid Amount (${currency.name})`,
      children: errors['paid_amount'] &&
      <p className='text-sm text-red-500'>
        This field is required
      </p>
    }
  ]

  return (
    <component.Modal title="Paid loan" open={open} closeModal={closeModal}>
      <form name='paid_loan' onSubmit={handleSubmit(submit)}>
        <div className="mt-2">
          <div className="grid grid-cols-6 gap-6">
            <component.FormCustom customForm={customForm} />
          </div>
        </div>
        <component.SubmitModal 
          closeModal={closeModal}
          isLoading={isLoading}
          text="Save"
        />
      </form>
    </component.Modal>
  );
}

export default ExtraPaidLoanModal;
