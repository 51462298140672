import { ButtonProps } from '../../types'

function Button(props: ButtonProps) {
  const {
    onClick = () => {},
    type = "button",
    children,
    className = "",
  } = props

  return (
    <button
      onClick={onClick}
      type={type}
      className={`
      rounded-md
      shadow-md
      p-2
      text-sm
      ${className}
      font-medium
      dark:text-gray-200
      dark:hover:text-gray-700
      hover:text-white
      `}
    >
      {children}
    </button>
  )
}

export default Button