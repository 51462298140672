import EarnLoss from './EarnLoss';
import UserIcon from './UserIcon';
import LoanIcon from './LoanIcon';

const icon = {
    EarnLoss,
    UserIcon,
    LoanIcon
}

export default icon;