
import { CustomModalForm, Props, connector } from "../../../types";
import {FieldValues, SubmitHandler, useForm} from 'react-hook-form';
import { useState } from "react";
import component from "../../../components";

function Password(props: Props) {
  const {user} = props
  const isLoading = false;

  const [resetModal, setResetModal] = useState(false)
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
    watch,
  } = useForm();

  const changePassword: SubmitHandler<FieldValues> = (data) => {
    console.log(data)
    console.log(user)
    console.log("Change password")

    reset()
  }

  const closeResetModal = () => {
    setResetModal(!resetModal)
  }

  const submitResetPassword = () => {
    console.log("reset password now")
  }

  const customForm: Array<CustomModalForm> = [
    {
      label: "Old Password",
      divProps: {
        className: "col-span-6 pt-3"
      },
      labelProps: {
        htmlFor: "oldPassword"
      },
      inputProps: {
        type: "password",
        ...register('oldPassword', {
          required: true,
          minLength: 7,
        }),
        autoComplete: "password",
        placeholder: "Shhhh dont tell anyone"
      },
      children: errors.oldPassword &&
      errors.oldPassword.type === 'required' &&
        <p className='text-sm text-red-500'>
          This field is required
        </p>
    },
    {
      label: "New Password",
      divProps: {
        className: "col-span-6 pt-3"
      },
      labelProps: {
        htmlFor: "password"
      },
      inputProps: {
        type: "password",
        ...register('password', {
            required: true,
            minLength: 7,
        }),
        autoComplete: "password",
        placeholder: "Give it a new life ...."
      },
      children: <>
        {errors.password &&
        errors.password.type === 'required' &&
          <p className='text-sm text-red-500'>
            This field is required
          </p>
        }
        {errors.password &&
        errors.password.type === 'minLength' &&
          <p className='text-sm text-red-500'>
            Password should be more than 7 characters
          </p>
        }
        {errors.password &&
        errors.password.type === 'validate' &&
          <p className='text-sm text-red-500'>
            Password is not the same as confirm password
          </p>
        }
      </>
    },
    {
      label: "Confirm Password",
      divProps: {
        className: "col-span-6 pt-3"
      },
      labelProps: {
        htmlFor: "confirmPassword"
      },
      inputProps: {
        type: "password",
        ...register('confirmPassword', {
          validate: (val) => val === watch('password'),
          required: true,
          minLength: 7,
        }),
        autoComplete: "password",
        placeholder: "Now do that again ...."
      },
      children: <>
        {errors.confirmPassword &&
        errors.confirmPassword.type === 'required' &&
          <p className='text-sm text-red-500'>
            This field is required
          </p>
        }
        {errors.confirmPassword &&
        errors.confirmPassword.type === 'minLength' &&
          <p className='text-sm text-red-500'>
            Password should be more than 7 characters
          </p>
        }
        {errors.confirmPassword &&
        errors.confirmPassword.type === 'validate' &&
          <p className='text-sm text-red-500'>
            Confirm password is not the same as password
          </p>
        }
      </>
    }
  ]

  return (
    <div className='px-10 py-16'>
        <form id="updateUserPassword" onSubmit={handleSubmit(changePassword)}>
            <div className="px-4 py-5 bg-white sm:p-6 rounded-xl dark:bg-gray-900">
                <div className="grid grid-cols-6 gap-6">
                    <component.FormCustom customForm={customForm} />
                </div>
                <div className='flex flex-wrap pt-7 buttonmax:space-y-3'>
                    <button
                    type="submit"
                    className="
                        inline-flex
                        align-center
                        justify-center
                        px-4
                        py-2
                        mr-5
                        text-sm
                        font-medium
                        text-blue-900
                        bg-blue-100
                        border
                        border-transparent
                        rounded-md
                        hover:bg-blue-200
                        focus:outline-none
                        focus-visible:ring-2
                        focus-visible:ring-offset-2
                        focus-visible:ring-blue-500
                        "
                    >
                        <component.ButtonLoader isLoading={isLoading} classNameProps="text-inherit" />
                        <div className={`${isLoading && 'mt-1'} `}>Update</div>
                    </button>
                    <button
                        onClick={closeResetModal}
                        type="button"
                        className="
                        inline-flex
                        align-center
                        justify-center
                        px-4
                        py-2
                        text-sm
                        font-medium
                        text-red-900
                        bg-red-100
                        border
                        border-transparent
                        rounded-md
                        hover:bg-red-200
                        focus:outline-none
                        focus-visible:ring-2
                        focus-visible:ring-offset-2
                        focus-visible:ring-red-500
                        "
                    >
                        Reset Password
                    </button>
                </div>
            </div>
        </form>
        <component.ResetPasswordModal
            open={resetModal}
            closeModal={closeResetModal}
            submit={submitResetPassword}
            isLoading={false}
        />
    </div>
  )
}

export default connector(Password)