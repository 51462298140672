import { RadioGroup } from '@headlessui/react'
import { createElement, useEffect, useState, Fragment, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Profile from './profile/Profile';
import { Props, connector } from '../../types';
import Account from './account/Account';
import Password from './password/Password';
import hook from '../../hooks';

function CheckIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function Settings(props: Props) {
  const {user} = props

  const plans = useMemo(() => [
    {
      name: 'Profile',
      img: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
      </svg>,
      path: "/settings/profile",
      component: createElement(Profile, {...props}),
    },
    {
      name: 'Accounts',
      img: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
      </svg>,
      path: "/settings/account",
      component: createElement(Account, {...props}),
    },
    {
      name: 'Password',
      img: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
      </svg>,
      path: "/settings/password",
      component: createElement(Password, {...props}),
    }
  ], [props])

  const location = useLocation()
  
  const [selected, setSelected] = useState(plans.find(x => x.path === location.pathname))
  const [menu, setMenu] = useState(false)
  
  const [ref] = hook.useOutside(setMenu)

  const navigate = useNavigate()

  const routePage = (e: any) => {
    navigate(e.path)
    setSelected(e)
  }

  useEffect(() => {
    if (user.type === "google" && location.pathname === "/settings/password") {
      navigate("/settings/profile")
    }
    const find = plans.find(x => x.path === location.pathname)

    setSelected(find)

    return () => setSelected({
      name: "",
      img: <></>,
      path: "",
      component: <></>
    })

  }, [location.pathname, plans, navigate, user.type])

  const slider = () => {
    setMenu(!menu)
  }

  return (
    <div className={`
      m-10 
      dark:text-white
      dark:bg-gray-700
      bg-white
      border-gray-200
      dark:border-gray-900
      rounded-xl
      maxmobile:transition-all 
      maxmobile:duration-1000 
      maxmobile:relative
      ${menu ? "maxmobile:left-60" : "maxmobile:left-0"}
    `}>
      <div 
      className={`
        mobile:hidden 
        maxmobile:transition 
        maxmobile:duration-500 
        maxmobile:ease-in-out 
        maxmobile:rounded-full 
        maxmobile:absolute 
        maxmobile:flex 
        maxmobile:justify-start 
        maxmobile:p-3
        maxmobile:z-20
        `} 
        onClick={slider}
        ref={ref}
      >
        {menu?
          <svg
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="currentColor" 
            className="w-6 h-6 text-gray-500 dark:text-gray-200 text-2xl cursor-pointer">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        : 
          <svg 
            xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-gray-500 dark:text-gray-200 text-2xl cursor-pointer">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
          </svg>
        }
      </div>
      <div 
      className='
        flex 
        flex-wrap 
        justify-evenly'
      >
        <div 
          className={`
            mobile:w-[30%]
            mobile:block
            maxmobile:transition-all 
            maxmobile:duration-1000
            maxmobile:absolute
            maxmobile:h-screen 
            maxmobile:z-10 
            maxmobile:w-30
            ${menu ? "maxmobile:-left-72 maxmobile:pl-10" : "maxmobile:-left-full"}
          `}>
          <div className="w-full px-4 py-16">
            <div className="mx-auto w-full max-w-md">
              <RadioGroup value={selected} onChange={routePage}>
                  <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                  <div className="space-y-2">
                    {plans.map((item) => {
                      const checked = item.path === location.pathname
                      if (user.type === "google" && item.path === "/settings/password") {
                        return <Fragment key={item.path}></Fragment>
                      }
                      return (
                        <RadioGroup.Option
                        key={item.name}
                        value={item}
                        className={({ active }) =>
                          `${
                            active
                              ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                              : ''
                          }
                          ${
                            checked ? 'dark:bg-blueGray-800 bg-secondary bg-opacity-75 dark:text-white text-black' : 'bg-slate-100 dark:bg-gray-800 dark:text-blueGray-400'
                          }
                            relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                        }
                      >
                        {() => (
                          <>
                            <div className="flex w-full items-center justify-between">
                              <div className="flex flex-wrap items-center space-x-2">
                                <div className="text-sm">
                                  <RadioGroup.Label
                                    as="p"
                                    className={`font-medium  ${
                                      checked ? 'dark:text-white text-black' : 'text-blueGray-400'
                                    }`}
                                  >
                                    {item.img} 
                                  </RadioGroup.Label>
                                </div>
                                <div className="text-sm">
                                  <RadioGroup.Label
                                    as="p"
                                    className={`font-medium  ${
                                      checked ? 'dark:text-white text-black' : 'text-blueGray-400'
                                    }`}
                                  >
                                    {item.name}
                                  </RadioGroup.Label>
                                </div>
                              </div>
                              {checked && (
                                <div className="shrink-0 text-white pl-2">
                                  <CheckIcon className="h-6 w-6" />
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </RadioGroup.Option>
                      )
                    })}
                  </div>
                </RadioGroup>
              </div>
          </div>
        </div>
        <div className={
          `
          block
          mobile:w-[60%]
          `
        }>
          {plans.map(x => {
            if (x.path === location.pathname) {
              return (
                <Fragment key={x.path}>
                  {x.component}
                </Fragment>
              )
            } 
            return (
              <Fragment key={x.path}></Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default connector(Settings)