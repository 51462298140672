import { useMemo } from "react"
import { DataRender, Stock, StockData } from "../../types"
import moment from "moment"
import currency from "currency.js"
import functions from "../../function"
import { emptyStockData } from "../../variables/stock/stock_var"

const useStockGetData = (combo: Array<StockData>, filter: string) => {
    const data = useMemo(() => {
        if (combo) {
          
          const mapper = combo.map(x => {
            const unsold = x.data.filter(z => !z.sell_id)
            const sold = x.data.filter(z => z.sell_id)
            const find_sold = unsold.map(x => ({...x, sold: sold.filter(z => z.sell_id === x.id)}))
            return {...x, data: find_sold}
          })
    
          mapper.sort((a, b) => a.name.localeCompare(b.name))
    
          if (filter === "") {
            return mapper
          } else {
            const filters = mapper.filter(x => x.ticker.toLowerCase().includes(filter.toLowerCase()) || x.name.toLowerCase().includes(filter.toLowerCase()) || x.exch.toLowerCase().includes(filter.toLowerCase()))
            return filters
          }
        } 
        return []
    
    }, [combo, filter])

    return data
}

const useStockGetHistory = (data: Array<StockData>, history: Array<DataRender>) => {
    const memo = useMemo(() => {
        if (data && history) {
            const newData = data.map((x: Record<string, any>) => {
                const find = history.find((z: Record<string, any>) => Object.keys(z)[0] === x.ticker)
                if (find) {
                    const chartData = JSON.parse(find[x.ticker].data).data
    
                    const labels = chartData.map((z: Record<string,any>) => z.Date)
        
                    const newDataset = chartData.map((z: Record<string,any>) => z.Close)
        
                    const datasets = {
                        labels,
                        datasets: [
                            {
                            data: newDataset,
                            pointRadius: 0,
                            tension: 0.6
                            },
                        ],
                    };
        
                    return {...x, chartData: datasets}
                }
                return {...x}
            })

            newData.sort((a: Record<string, any>, b: Record<string, any>) => a.name.localeCompare(b.name))

            return newData
        } 
        return data
    }, [history, data])

    return memo
}

const useMemoStock = (data: StockData[], params: Record<string, any>) => {
    const filter = useMemo(() => {
      if (data) {
        const f = data.find(x => x.id === params.id)
        if (f) {
          const unsold = f!.data.filter(z => !z.sell_id)
          const sold = f!.data.filter(z => z.sell_id)
          const find_sold = unsold.map(x => ({...x, sold: sold.filter(z => z.sell_id === x.id)}))
          let final = {
            ...f, 
            data: find_sold
          }
          return final
        }
      } 
      return emptyStockData
  
    }, [data, params])
  
    return filter
}

const useMemoDividends = (
  data: Record<string, any>, 
  filter: Record<string, string>, 
  rgbaColor: (color: string) => any
) => {
    const dividends = useMemo(() => {
      if (data) {
        let dvdData = data.data
        
        if (filter.value !== "all") {
          const days = parseInt(filter.value)
          const minus = moment().subtract(days, "year")
  
          const filterData = dvdData.filter((x: Record<string, any>) => minus < moment(x.Date))
  
          dvdData = filterData
        } 
        
        const labels = dvdData.map((x: Record<string, any>) => moment(x.Date).format("YYYY-MM-DD"))
  
        const dividends_data = dvdData.map((x: Record<string,any>) => x.Dividends)

        const formatDate = dvdData.map((x: Record<string,any>) => ({...x, "Date": moment(x.Date).format("YYYY-MM-DD")}))

        let cagr = "0%"
        if (dividends_data.length > 0) {
          cagr = functions.cagr_calculation(dividends_data[0], dividends_data[dividends_data.length - 1], dividends_data.length)
        }

        const splits = cagr.split("%")

        const val = parseFloat(splits[0])
        let keys = "blueGray"
        if (val > 0) {
          keys= "green"
        } else if (val < 0) {
          keys = "red"
        } else {
          keys= "yellow"
        }

        const datasets = {
          labels,
          datasets: [
              {
              data: dividends_data,
              pointRadius: 4,
              tension: 0.6,
              backgroundColor: rgbaColor(keys),
              },
          ],
      };

        return [
          {
            "name": "Dividend Calendar",
            "value": `CAGR: ${cagr}`,
            "chartData": datasets,
            "default": formatDate
          }
        ]
      } 
      return []
  
    }, [data, filter, rgbaColor])
  
    return dividends
}

const useStockGetSnapData = (filter: any, symbol: string) => {
  const snap = useMemo(() => {
    if (filter) {
      let d: Array<Stock> = filter!.data
      d = functions.shares_amount(d)
      const avg_cost = functions.avg_calculator(d)
      const profilenLoss = functions.pNl(avg_cost, d, filter!.price)
      const sellEarnLoss = functions.sPnL(d)
      const shares = functions.share_calculator(d)
      const buyP = avg_cost.multiply(shares)
      let pprofilenLoss = currency(profilenLoss, {precision: 2}).divide(buyP.value).multiply(100)
      const currPrice = currency(filter!.price!, {precision: 2})
                            .multiply(shares)

      const market_price = currPrice.value

      if (isNaN(pprofilenLoss.value)) {
        pprofilenLoss = currency(0)
      }

      const pnlP = functions.sPnLP(d)

      return [
        {
          "name": "Symbol",
          "value": `${filter!.ticker}`
        },
        {
          "name": "Shares",
          "value": `${shares.value}`
        },
        {
          "name": "Avg. cost/share",
          "value": `${filter!.symbol} ${avg_cost.value}`
        },
        {
          "name": `Unrealized's gain (${symbol})`,
          "value": functions.currencyConversion(functions.convertBackAmount(
            profilenLoss.value, filter!.rate!).value, false, {
              pattern: `+#`, 
              negativePattern: `-#`
            }, true)
        },
        {
          "name": "Unrealized's Total's gain (%)",
          "value": currency(pprofilenLoss, {
            precision: 2,
            pattern: `+#`,
            negativePattern: `-#`
          }).format()
        },
        {
          "name": `Realized's gain (${symbol})`,
          "value": functions.currencyConversion(functions.convertBackAmount(
            sellEarnLoss, filter!.rate!).value, false, {
              pattern: `+#`, 
              negativePattern: `-#`
            }, true)
        },
        {
          "name": "Realized's Total's gain (%)",
          "value": currency(pnlP, {
            precision: 2,
            pattern: `+#`,
            negativePattern: `-#`
          }).format(),
        },
        {
          "name": `Market Value (${symbol})`,
          "value": functions.currencyConversion(functions.convertBackAmount(market_price, filter!.rate!).value)
        }
      ]
    }
    return []
  }, [filter, symbol])

  return snap
}

const stockMemo = {
  useStockGetData,
  useStockGetHistory,
  useMemoStock,
  useMemoDividends,
  useStockGetSnapData
}

export default stockMemo;