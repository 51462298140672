import { Disclosure } from '@headlessui/react'
import { ShortenTableProps } from '../../types'

function ShortenTable(props: ShortenTableProps) {
  const {data = [], column = [], shortenColumn, click} = props

  return (
    <div className="w-full px-1 pt-4">
      <div className="w-full rounded-2xl bg-white shadow-md mb-3 dark:bg-gray-900 p-2">
        {data.length === 0 && 
          <div className='p-5 animate-pulse align-middle justify-center flex dark:text-blueGray-400 text-blueGray-800'>No result found...</div>
        }
        {data.map((item: Record<string, any>, key: number) => {
          const value = shortenColumn[1].render ? 
          shortenColumn[1].render(item[shortenColumn[0].dataIndex],item)
          :
          item[shortenColumn[1].dataIndex]

          let color: string = "blueGray"
          if (value && typeof(value) === "object" && typeof(value.props.children) === "string") {
            if (value.props.children.includes("+")) {
              color = "green"
            } else if (value.props.children.includes("-")) {
              color = "red"
            } else {
              color = "yellow"
            }
          }
        
          return (
            <Disclosure key={key}>
              {({ open }) => (
                <div className='mt-2 pb-2'>
                  <Disclosure.Button className={`
                    flex 
                    w-full 
                    justify-between 
                    rounded-lg 
                    bg-${color}-100 
                    dark:bg-${color}-700 
                    px-4 
                    py-2 
                    text-left 
                    text-sm 
                    font-medium 
                    text-${color}-900 
                    dark:text-${color}-400 
                    hover:bg-${color}-200 
                    dark:hover:bg-${color}-300 
                    dark:hover:text-${color}-800 
                    focus:outline-none 
                    focus-visible:ring 
                    focus-visible:ring-${color}-500 
                    focus-visible:ring-opacity-75
                  `}>
                    <div>{shortenColumn[0].render? 
                      shortenColumn[0].render(item[shortenColumn[0].dataIndex],item)
                    :
                    item[shortenColumn[0].dataIndex]}</div>
                    <div className='flex space-x-5'>
                      <div>
                        {shortenColumn[1].render? 
                        shortenColumn[1].render(item[shortenColumn[0].dataIndex],item)
                        :
                        item[shortenColumn[1].dataIndex]
                        }
                      </div>
                      <svg 
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24" 
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={`${
                          open ? 'rotate-180 transform' : ''
                        } h-5 w-5 text-${color}-500`}
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    </div>
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                    <div>
                      {column.map((y, z: number) => {
                        const renders = y.render && y.render(item[y.dataIndex], item).props.children

                        if (typeof(renders) !== "object") {
                          return (
                            <ul key={z} className='flex flex-wrap justify-between'>
                              <li className='font-bold dark:text-blueGray-400 text-gray-900'>{y.title}</li>
                              <li className='font-medium dark:text-blueGray-400 text-gray-900'>{y.render ?
                                    y.render(item[y.dataIndex], item) :
                                    <div
                                      className="
                                        text-center
                                        text-sm
                                      "
                                    >
                                      {item[y.dataIndex]}
                                    </div>
                                  }</li>
                            </ul>     
                          )
                        } else {
                          return (
                            <div key={z} className='flex align-middle justify-center mt-2'>
                              {y.render ? y.render(item[y.dataIndex], item)
                                :
                                <></>
                              }
                            </div>
                          )
                        }
                      })}
                    </div>
                    {click && 
                      <div className='flex align-middle justify-center mt-2'>
                        <button className='p-1 w-full bg-blueGray-700 hover:bg-blueGray-600 dark:text-blueGray-400 text-blueGray-800 rounded-lg' onClick={() => click(item)}>Settings</button>
                      </div>
                    }
                  </Disclosure.Panel>
                </div>
              )}
            </Disclosure>
          )
        })}
      </div>
    </div>
  )
}

export default ShortenTable