import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import currency from 'currency.js';
import moment from 'moment';
import { Props, ColumnRender, connector, Stock, FixedSizeArray, ShortColumnRender } from "../../types";
import { useDeleteAdditionalStock, useSellStock } from './mutateStock';
import { encoding } from '../../encryption/encryption';
import component from '../../components';
import hook from '../../hooks';


function SoldInfo(
  props: Props
) {
  const param = useParams()

  const {
    data,
    isLoading: dataLoading,
    refetch,
    isFetching,
  } = hook.useGetStock(props.user); 

  const filter = useMemo(() => {
    if (data) {
      const f = data.find(x => x.id === param.id)
      const unsold = f!.data.filter(z => !z.sell_id)
      const sold = f!.data.filter(z => z.sell_id)
      const find_sold = unsold.map(x => ({...x, sold: sold.filter(z => z.sell_id === x.id)}))
      let final = {
        ...f, 
        data: find_sold,
        sold: find_sold.find(z => z.id === parseInt(param.stockid!))
      }
      return final
    } 
    return undefined

  }, [data, param])

  const {
    register,
    handleSubmit,
    formState: {
      errors,
    },
    reset,
    setValue: setSellValue,
  } = useForm();


  const [edit, setEdit] = useState(0)

  const current = filter?.data.find((x:Stock) => x.id === parseInt(param.stockid!))

  const values = (row: Record<string, any>) => {
    if (current && current.sold.length > 1) {
      const n_of_shares = currency(current.sold.map((x: Stock) => x.shares).reduce((x: number, y: number) => x + y, 0), {precision: 2})
      const val = Math.abs(currency(n_of_shares, {precision: 2}).subtract(current.shares).value)
      return currency(row.shares, {precision: 2}).add(val).value
    } else if (current) {
      return Math.abs(currency(current.shares, {precision: 2}).value)
    }
    return undefined
  }

  const editingMode = (row: Record<string, any>) => {
    setSellValue("id", row.sell_id)
    setSellValue("sell_cost", row.cost)
    setSellValue("sell_shares", row.shares)
    setSellValue("sell_id", row.id)
    setSellValue("sell_date", row.date)
    setEdit(row.id)
  }

  const closeEditingMode = () => {
    setEdit(0)
    reset()
  }

  const {mutate: sellStockMutate, isLoading: sellStockLoading} = useSellStock()

  const submit: SubmitHandler<FieldValues> = (e) => {
    if (filter) {
      const encoded = encoding(e)

      const payload = {
        userInformation: encoded
      }

      const mergeIDnPayload = [
        filter.id, payload
      ]

      sellStockMutate(mergeIDnPayload, {
        onSuccess: ({data}) => {
          if (data.success) {
            closeEditingMode()
            refetch()
          }
        }
      })
    }
  }

  const {mutate: deleteAdditionalMutate, isLoading: deleteAdditionalLoading} =  useDeleteAdditionalStock()

  const deleteSold = (e: Record<string, any>) => {
    if (filter) {
      const encoded = encoding({id: e})
      const payload = {
        userInformation: encoded
      }

      const mergeIDnPayload = [
        filter.id, payload
      ]

      deleteAdditionalMutate(mergeIDnPayload, {
        onSuccess: ({data}) => {
          if (data.success) {
            refetch()
          }
        }
      })
    } 
  }

  const shortenColumn: FixedSizeArray<ShortColumnRender, 2> = [
    {
      dataIndex: 'date',
      render: (date, _) => {
        return (
          <div className="text-center text-sm text-inherit">{moment(date).format("Do ddd, YYYY")}</div>
        )
      }
    },
    {
      dataIndex: 'shares'
    },
  ]
  

  const column: Array<ColumnRender> = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (_, row) => {
        return (
        <>
          {row.id === edit ?
            <div className='flex flex-wrap w-full'>
              <input
                type="date"
                {...register('sell_date', {required: true})}
                className='
                dark:text-white
                dark:bg-gray-700
                border-gray-300
                dark:border-gray-900
                mt-1
                focus:ring-primary
                focus:border-primary
                block 
                w-full
                shadow-sm
                sm:text-sm
                rounded-md
                '
              />
              {errors.sell_date &&
                <p className='text-sm text-red-500'>
                This field is required
                </p>
              }
            </div>
            :
            <div className="text-center text-sm text-inherit">
              {row.date}
            </div>
          }
        </>
        )
      }
    },
    {
      title: 'Cost/Share',
      dataIndex: 'cost',
      render: (_, row) => (
        <>
          {row.id === edit ?
            <div className='flex flex-wrap w-full'>
              <input
                type="number"
                step=".000001"
                {...register('sell_cost', {required: true})}
                className='
                dark:text-white
                dark:bg-gray-700
                border-gray-300
                dark:border-gray-900
                mt-1
                focus:ring-primary
                focus:border-primary
                block
                w-full
                shadow-sm
                sm:text-sm
                rounded-md
                '
              />
              {errors.sell_cost &&
                <p className='text-sm text-red-500'>
                This field is required
                </p>
              }
            </div>
            :
            <div className="text-center text-sm text-inherit">
              {row.cost}
            </div>
          }
        </>
      )
    },
    {
      title: 'Shares',
      dataIndex: 'shares',
      render: (_, row) => (
        <>
          {row.id === edit ?
            <div className='flex flex-wrap w-full'>
              <input
                type="number"
                min="0"
                step=".00001"
                {...register('sell_shares', {required: true, min: 0, max: values(row)})}
                className='
                dark:text-white
                dark:bg-gray-700
                border-gray-300
                dark:border-gray-900
                mt-1
                focus:ring-primary
                focus:border-primary
                block
                w-full
                shadow-sm
                sm:text-sm
                rounded-md
                '
              />
              {
                errors.sell_shares && errors.sell_shares.type === "max" ?
                <p className='text-sm text-red-500'>
                    Shares should be lower than {values(row)}
                </p>
                : errors.sell_shares &&
                <p className='text-sm text-red-500'>
                    This field is required
                </p>
              }
            </div>
            :
            <div className="text-center text-sm text-inherit">
              {row.shares}
            </div>
          }
        </>
      )
    },
    ...[
      edit !== 0 ?
      {
        title: 'Edit',
        dataIndex: '',
        render: (_: string, row: Record<string, any>) => edit === row.id ?
        <div className="w-full text-center text-sm text-inherit">
          <button
            type="submit"
            className={`
              inline-flex
              align-middle 
              justify-center
              p-1
              w-full
              text-sm
              font-medium
              text-yellow-900
              bg-yellow-100
              border
              border-transparent
              rounded-md
              focus:outline-none
              focus-visible:ring-2
              focus-visible:ring-offset-2
              focus-visible:ring-yellow-500
            `}
          >
            <component.ButtonLoader isLoading={sellStockLoading} classNameProps="text-inherit" />
            Submit
          </button>
        </div>
        :
        <></>
      }: {
        title: "",
        dataIndex: "",
      }], 
    {
      title: 'View',
      dataIndex: '',
      render: (_, row) => (
        <div className="w-full text-center text-sm text-inherit">
          <button
            type="button"
            onClick={() => {
              if (row.id === edit) {
                return closeEditingMode()
              } else {
                return editingMode(row)
              }
            }}
            className={`
              inline-flex
              align-middle 
              justify-center
              p-1
              w-full
              text-sm
              font-medium
              text-${row.id === edit ? "red": "yellow"}-900
              bg-${row.id === edit ? "red": "yellow"}-100
              border
              border-transparent
              rounded-md
              focus:outline-none
              focus-visible:ring-2
              focus-visible:ring-offset-2
              focus-visible:ring-${row.id === edit ? "red": "yellow"}-500
            `}
          >
            {row.id === edit ? "Close":"Edit"}
          </button>
        </div>
      )
    },
    ...[
      edit === 0 ?
      {
        title: 'Delete',
        dataIndex: 'delete',
        render: (_: string, row: Record<string, any>) => (
          <div className="w-full text-center text-sm text-inherit">
            <button
              type="button"
              onClick={() => deleteSold(row.id)}
              className={`
                inline-flex
                align-middle 
                justify-center
                p-1
                w-full
                text-sm
                font-medium
                text-red-900
                bg-red-100
                border
                border-transparent
                rounded-md
                focus:outline-none
                focus-visible:ring-2
                focus-visible:ring-offset-2
                focus-visible:ring-red-500
              `}
            >
              <component.ButtonLoader isLoading={deleteAdditionalLoading} classNameProps="text-inherit" />
              Delete
            </button>
          </div>
        )
      }: 
      {
        title: '',
        dataIndex: '',
      }
    ],
  ]

  if (dataLoading) {
    return <component.Loading />;
  }

  return (
    <div className='p-4 h-full'>
      <div
        className={`flex flex-wrap justify-between items-center align-middle mt-3 maxsizs:space-y-8`}
      >
          <div className={`font-semibold text-sm text-black dark:text-white`}>
              <Link to={`/stock/${param.id}`} className='rounded-md
                shadow-md
                p-3
                text-sm
                bg-gray-300
                hover:bg-gray-500
                dark:bg-gray-500
                dark:hover:bg-gray-300
                font-medium
                dark:text-gray-100
                dark:hover:text-gray-700
                hover:text-white'
              >
                Back
              </Link>
          </div>
          <div className={`font-semibold text-xl text-black dark:text-white text-center`}>
            Sold: {filter?.name}
          </div>
          <div className='space-x-3 flex flex-wrap' />
      </div>
      <div className='mt-8'>
        <form name="edit_sold_stock" onSubmit={handleSubmit(submit)}>
          <div className='hidden md:inline'>
            <component.Table 
              column={column}
              data={filter?.sold?.sold}
              loading={sellStockLoading || isFetching}
            />
          </div>
          <div className='inline md:hidden'>
            <component.ShortenTable 
              shortenColumn={shortenColumn}
              column={column}
              data={filter?.sold?.sold}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default connector(SoldInfo)