import currency from "currency.js"
import {Stock as Stocks} from '../../types';

const share_calculator = (arr: Array<Stocks>) =>  {
  const shares = currency(arr.map(x =>  x.shares).reduce((x, y) => x + y, 0), {precision: 2})

  return shares
}

const avg_calculator = (arr: Array<Stocks>) => {
  const avg_cost_per_share = arr.map(x => {
    if (x.shares >= 0) {
      const total = currency(x.cost, {precision: 2}).multiply(x.shares).value
      
      return total 
    }
    return 0
  })
 
  const cost = currency(avg_cost_per_share.reduce((x, y) => x + y, 0), {precision: 2}).value
  const shares = share_calculator(arr)

  const avg_cost = currency(cost, {precision: 2}).divide(shares)

  if (isNaN(avg_cost.value)) {
    return currency(0)
  }

  return avg_cost
}

const shares_amount = (arr: Array<Stocks>) => {
  const shares = arr.map(x => {
    const n_of_sold_shares = share_calculator(x.sold!)
    const shares = currency(x.shares, {precision: 2}).subtract(n_of_sold_shares)
    return {...x, shares: shares.value, ori_shares: x.shares}
  })

  return shares
}

const pNl = (
  avg_cost: currency,
  arr: Array<Stocks>,
  price: any
) => {
  const shares = share_calculator(arr)

  const buyPrice = avg_cost.multiply(shares)
                        
  const currPrice = currency(price, {precision: 2})
                      .multiply(shares)
                      
  const profilenLoss = currPrice.subtract(buyPrice)

  if (isNaN(profilenLoss.value)) {
    return currency(0)
  }

  return profilenLoss
}

const sPnL = (
  arr: Array<Stocks>
) => {
  const allSellPnL = arr.map(x => {
    const sold = x.sold!.map(z => {
      const soldP = currency(z.cost, {precision: 2}).multiply(z.shares)
      const buyP = currency(x.cost, {precision: 2}).multiply(z.shares)

      const earningNloss = soldP.subtract(buyP)

      return {...z, pnl: earningNloss.value}
    })

    const winLoss = currency(sold.map(z => z.pnl).reduce((x, y) => x + y, 0), {precision: 2})

    if (isNaN(winLoss.value)) {
      return {
        ...x,
        pnl: 0
      }      
    }

    return {
      ...x,
      pnl: winLoss.value
    }           
  })

  const total = allSellPnL.map(x => x.pnl).reduce((x, y) => x + y, 0)
  
  return total
}

const sPnLP = (
  arr: Array<Stocks>
) => {
  const allSellPnL = arr.map(x => {
    const sold = x.sold!.map(z => {
      const soldP = currency(z.cost, {precision: 2}).multiply(z.shares)
      const buyP = currency(x.cost, {precision: 2}).multiply(z.shares)

      const earningNloss = soldP.subtract(buyP)

      const percentage = currency(earningNloss, {precision: 2}).divide(buyP).multiply(100)

      return {...z, pnl: percentage.value}
    })

    const winLoss = currency(sold.map(z => z.pnl).reduce((x, y) => x + y, 0), {precision: 2})

    if (isNaN(winLoss.value)) {
      return {
        ...x,
        pnl: 0
      }      
    }

    return {
      ...x,
      pnl: winLoss.value
    }           
  })

  const total = allSellPnL.map(x => x.pnl).reduce((x, y) => x + y, 0)
  
  return total
}

const cagr_calculation = (begin: number, end: number, period: number) => {
  const cagr = (end / begin) ** (1 / period) - 1

  const convert = currency(cagr, {
    precision: 2,
    pattern: `#%`,
    negativePattern: `-#%`
  }).format()

  return convert
}


const stockCalculate = {
  share_calculator,
  avg_calculator,
  shares_amount,
  pNl,
  sPnL,
  sPnLP,
  cagr_calculation
}

export default stockCalculate;