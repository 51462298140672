import currency from 'currency.js';
import { useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { encoding } from '../../encryption/encryption';
import { ColumnRender, connector, Props, Stock as Stocks, StockData, FixedSizeArray, SnapRender, DataRender } from '../../types';
import component from '../../components';
import { useAddStock, useDeleteStock, useSearchTicker } from './mutateStock';
import hook from '../../hooks';
import functions from '../../function';

function Stock(props: Props) {
  const navigate = useNavigate()
  const {
    setAlertStatus,
    setAlert,
    setAlertMessage,
    currency: currentCurrency
  } = props

  const [addStock, setAddStock] = useState(false)
  const [ticker, setTicker] = useState("")
  const [stockSelection, setStockSelection] = useState([])
  const [selectedStock, setSelectedStock] = useState({})
  const [deleteStock, setDeleteStock] = useState(false)
  const [selectedDelete, setSelectedDelete] = useState<Array<DataRender>>([])
  const [filter, setFilter] = useState("")

  const symbol = currentCurrency.name === "MYR" ? "RM" : "$"

  const {mutate, isLoading: tickerLoading} = useSearchTicker()
  const searchTicker = (e: HTMLFormElement) => {
    e.preventDefault()
    mutate(ticker, {
        onSuccess: ({data}) => {
            if (data.success) {
              const d = data.data
              const tickers = d.quotes.map((x: Record<string, any>) => ({
                name: `${x.shortname} (${x.exchDisp})`,
                value: x.symbol,
                exch: x.exchDisp,
              }))
              setStockSelection(tickers)
            } else {
              setAlert(true)
              setAlertStatus("info")
              setAlertMessage(data.message)
            }
        },
    })
  }

  const {
    register,
    handleSubmit,
    formState: {
      errors,
    },
    reset,
  } = useForm();

  const addStockModal = () => {
    setAddStock(!addStock)
    setStockSelection([])
    setSelectedStock({})
    setTicker("")
    reset()
  }

  const setSelectedStockDelete = (
    e: Record<string, any>,
    row: Record<string, any>  
  ) => {
    let data = {
      id: row.id,
      checked: e.target.checked
    }
    setSelectedDelete((cur: Array<DataRender>) => {
      if (cur.length > 0) {
        const finds = cur.find(x => x.checked && x.id === data.id)
        const unfind = cur.filter(x => x.id !== data.id)
        if (finds) {
          return [...unfind, data]
        }
        return [...unfind, data]
      }
      return [...cur, data]
    })
  }

  const shortenColumn: FixedSizeArray<SnapRender, 3> = [
    {
      type: "title",
      dataIndex: 'name'
    },
    {
      type: "data",
      dataIndex: 'price',
    },
    {
      type: "data",
      dataIndex: 'total_gain_percentage',
      render: (_, row: any) => {
        let d: Array<Stocks> = row.data
        d = functions.shares_amount(d)
        let avg_cost = functions.avg_calculator(d)

        const profilenLoss = functions.pNl(avg_cost, d, row.price)
        const shares = functions.share_calculator(d)
        const buyP = avg_cost.multiply(shares)
        let pprofilenLoss = currency(profilenLoss, {precision: 2}).divide(buyP.value).multiply(100)

        if (isNaN(pprofilenLoss.value)) {
          pprofilenLoss = currency(0)
        }

        const result = currency(pprofilenLoss, {
          precision: 2,
          pattern: `+#`,
          negativePattern: `-#`
        }).format()

        let keys = "nothing"
        if (result.includes("+")) {
          keys= "positive"
        } else if (result.includes("-")) {
          keys = "negative"
        }

        return (
          <component.EarnLoss key={keys} keys={keys} />
        ) 
      }
    }
  ]

  const column: Array<ColumnRender> = [
    ...[deleteStock ? {
      title: '',
      dataIndex: '',
      render: (_: any, row: Record<string, any>) => (
          <input
            className='ml-4 rounded-sm cursor-pointer dark:bg-blueGray-800'
            type="checkbox"
            onChange={e => setSelectedStockDelete(e, row)}
          />
      ),
    }: {
      title: "",
      dataIndex: "",
    }],
    {
      title: 'Symbol',
      dataIndex: 'ticker',
    },
    {
      title: 'Shares',
      dataIndex: 'share',
      render: (_, row) => {

        let d: Array<Stocks> = row.data
        d = functions.shares_amount(d)
        const share = functions.share_calculator(d)
        return (
          <div
            className="text-center text-sm  text-inherit"
          >
            {share.value}
          </div>
        )
      }
    },
    {
      title: 'Avg. cost/share',
      dataIndex: 'avg_cost_per_share',
      render: (_, row) => {
        let d: Array<Stocks> = row.data
        d = functions.shares_amount(d)
        const avg_cost = functions.avg_calculator(d)

        return (
          <div
            className="text-center text-sm text-inherit"
          >
            {`${row.symbol} ${avg_cost.value}`}
          </div>
        )
      }
    },
    {
      title: 'Price',
      dataIndex: 'price',
    },
    {
      title: `Unrealized's gain (${symbol})`,
      dataIndex: 'total_gain',
      render: (_, row) => {
        let d: Array<Stocks> = row.data
        d = functions.shares_amount(d)
        const avg_cost = functions.avg_calculator(d)

        const profilenLoss = functions.pNl(avg_cost, d, row.price)

        return (
          <div
            className="text-center text-sm text-inherit"
          >
            {functions.currencyConversion(functions.convertBackAmount(
              profilenLoss.value, row.rate).value, false, {
                pattern: `+#`, 
                negativePattern: `-#`
              }, true)}
          </div>
        ) 
      }
    },
    {
      title: 'Unrealized\'s Total\'s gain (%)',
      dataIndex: 'total_gain_percentage',
      render: (_, row) => {
        let d: Array<Stocks> = row.data
        d = functions.shares_amount(d)
        let avg_cost = functions.avg_calculator(d)

        const profilenLoss = functions.pNl(avg_cost, d, row.price)
        const shares = functions.share_calculator(d)
        const buyP = avg_cost.multiply(shares)
        let pprofilenLoss = currency(profilenLoss, {precision: 2}).divide(buyP.value).multiply(100)

        if (isNaN(pprofilenLoss.value)) {
          pprofilenLoss = currency(0)
        }

        return (
          <div
            className="text-center text-sm text-inherit"
          >
            {
              currency(pprofilenLoss, {
                precision: 2,
                pattern: `+#`,
                negativePattern: `-#`
              }).format()
            }
          </div>
        ) 
      }
    },
    {
      title: `Realized's gain (${symbol})`,
      dataIndex: 'total_gain',
      render: (_, row) => {
        let d: Array<Stocks> = row.data
        d = functions.shares_amount(d)
        const sellEarnLoss = functions.sPnL(d)

        return (
          <div
            className="text-center text-sm text-inherit"
          >
            {functions.currencyConversion(functions.convertBackAmount(
              sellEarnLoss, row.rate).value, false, {
                pattern: `+#`, 
                negativePattern: `-#`
              }, true)}
          </div>
        ) 
      }
    },
    {
      title: 'Realized\'s Total\'s gain (%)',
      dataIndex: 'total_gain_percentage',
      render: (_, row) => {
        let d: Array<Stocks> = row.data
        d = functions.shares_amount(d)
        const pnlP = functions.sPnLP(d)
      
        return (
          <div
            className="text-center text-sm text-inherit"
          >
            {
              currency(pnlP, {
                precision: 2,
                pattern: `+#`,
                negativePattern: `-#`
              }).format()
            }
          </div>
        ) 
      }
    },
    {
      title: `Market Value (${symbol})`,
      dataIndex: 'market_value',
      render: (_, row) => {
        let d: Array<Stocks> = row.data
        d = functions.shares_amount(d)
        const shares = functions.share_calculator(d)
        const currPrice = currency(row.price, {precision: 2})
                            .multiply(shares)

        const finalVal = currPrice.value

        return (
          <div
            className="text-center text-sm text-inherit"
          >
            {functions.currencyConversion(functions.convertBackAmount(finalVal, row.rate).value)}
          </div>
        ) 
      }
    },
  ];

  const {mutate: addStockMutation, isLoading: addStockLoading} = useAddStock()

  const submit: SubmitHandler<FieldValues> = (e) => {

    const data = encoding({
      ...selectedStock,
      stock_data: e,
      exp: Math.round(Date.now() / 1000) + (60 * 10), // 10 min expiration
    })

    const payload = {
      userInformation: data,
    };

    addStockMutation(payload, {
      onSuccess: ({data}) => {
        if (data.success) {
          addStockModal()
          refetch()
        } else {
          addStockModal()
          setAlertStatus("error")
          setAlert(true)
          setAlertMessage(data.message)
        }
      },
      onError: ({response}) => {
        const res: Record<string, any> = response!
        const errMsg = JSON.parse(res.data.message)
        const err = errMsg['detail']
        setAlertStatus("error")
        setAlert(true)
        setAlertMessage(err)
      }
    })
  };

  const addAdditional = (
    row: StockData,
  ) => {
    if (!deleteStock) {
      navigate(row.id)
    } else { 
      const dts = {
        id: row.id,
        checked: true,
      }
      setSelectedDelete((cur: Array<DataRender>) => {
        if (cur.length > 0) {
          const finds = cur.find(x => x.checked && x.id === dts.id)
  
          const unfind = cur.filter(x => x.id !== dts.id)
          if (finds) {
            let d = {
              ...dts,
              checked: !dts.checked
            }
            return [...unfind, d]
          }
          return [...unfind, dts]
        }
        return [...cur, dts]
      })
    }
  }

  const {mutate: deleteMutate, isLoading: deleteLoading} = useDeleteStock()

  const confirmStockDelete = () => {
    const find = selectedDelete.filter(x => x.checked)
    if (find.length > 0) {
      const encoded = encoding({
        data: find
      })
      const payload = {
        userInformation: encoded
      }
      deleteMutate(payload, {
        onSuccess: ({data}) => {
          if (data.success) {
            resetDeleteStock()
            refetch()
          } else {
            console.log(data)
          }
        }
      })
    } else {
      setAlert(true)
      setAlertStatus("info")
      setAlertMessage("Please select a stock to delete")
    }
  }

  const resetDeleteStock = () => {
    setDeleteStock(!deleteStock)
    setSelectedDelete([])
  }

  const {
    data: combo,
    isLoading: dataLoading,
    refetch: dataFetch,
    isFetching: dataFetching
  } = hook.useGetStock(props.user); 

  const {
    data: history,
    isLoading: historyLoading,
    refetch: historyRefetch,
    isFetching: historyFetchLoading
  } = hook.useGetStockHistory(props.user);

  const refetch = () => {
    dataFetch()
    historyRefetch()
  }

  const data = hook.useStockGetData(combo, filter)

  const renderHistory = hook.useStockGetHistory(data, history!)

  const invested = currency(functions.currencyConversion(data.map(row => {
    let d: Array<Stocks> = row.data

    d = functions.shares_amount(d)
    const avg_cost = functions.avg_calculator(d)
    const share = functions.share_calculator(d)
    const total = avg_cost.multiply(share).value 

    return functions.convertBackAmount(total, row.rate).value
  }).reduce((x, y) => x + y, 0)), { symbol: symbol, precision: 2}).format()

  const holdings = currency(functions.currencyConversion(data.map(row => {
    let d: Array<Stocks> = row.data
    d = functions.shares_amount(d)
    const share = functions.share_calculator(d)
    const total = share.multiply(row.price).value 
    return functions.convertBackAmount(total, row.rate).value
  }).reduce((x, y) => x + y, 0)), { symbol: symbol, precision: 2}).format()

  const gain = currency(functions.currencyConversion(data.map(row => {
    let d: Array<Stocks> = row.data
    d = functions.shares_amount(d)
    
    const avg_cost = functions.avg_calculator(d)

    const profilenLoss = functions.pNl(avg_cost, d, row.price)
    
    const sellEarnLoss = functions.sPnL(d)

    const profitnLoss = profilenLoss.add(sellEarnLoss)

    const finalVal = profitnLoss.value
    return functions.convertBackAmount(finalVal, row.rate).value
  }).reduce((x, y) => x + y, 0)), {
    precision: 2,
    pattern: `+${symbol}#`, 
    negativePattern: `-${symbol}#`,
  }).format()

  const {data: dividend} = hook.useGetAllDividends(data)

  const dividends = currency(functions.currencyConversion(
    dividend?.reduce((x: number, y: number) => x + y, 0)), {
      precision: 2,
      pattern: `+${symbol}#`, 
      negativePattern: `-${symbol}#`,
    }
  ).format()

  const basicSnap: FixedSizeArray<SnapRender, 3> = [
    {
      type: "title",
      dataIndex: 'name'
    },
    {
      type: "data",
      dataIndex: 'price',
    },
    {
      type: "data",
      dataIndex: 'price',
      render: (_, row: any) => {
        let keys = ""
        if (row.price.includes("+")) {
          keys= "positive"
        } else if (row.price.includes("-")) {
          keys = "negative"
        }
        return (
          <component.EarnLoss key={keys} keys={keys} />
        ) 
      }
    }
  ]

  const datas = [
    {
      "name": "Invested",
      "price": invested,
    },
    {
      "name": "Holdings",
      "price": holdings,
    },
    {
      "name": "Gains",
      "price": gain,
    },
    {
      "name": "Dividends",
      "price": dividends,
    }
  ]

  if (dataLoading || dataFetching) {
    return <component.Loading />;
  }

  return (
    <div>
      <div className='flex justify-start font-bold text-2xl p-5 text-black dark:text-white text-center'>
        Stocks
      </div>
      <div className='pt-0 p-4'>
        <div
          className={
            `flex mb-5 justify-between items-end align-middle flex-wrap pt-3`
          }
        >
          <div className='space-x-3 flex flex-wrap'>
            {!deleteStock ?
                <button
                  onClick={addStockModal}
                  type="button"
                  className='
                    rounded-md
                    shadow-md
                    p-3
                    text-sm
                    bg-green-300
                    hover:bg-primary
                    hover:text-white
                    dark:bg-secondary
                    dark:hover:bg-primary
                    font-medium
                    dark:text-gray-200
                  '
                >
                  Add Stock
                </button>
              :
                <button
                  onClick={confirmStockDelete}
                  type="button"
                  className={`
                    inline-flex
                    align-baseline 
                    justify-center
                    p-3
                    font-medium
                    border
                    border-transparent
                    rounded-md
                    shadow-md
                    ${deleteLoading? "cursor-progress" : ""}
                    text-sm
                    bg-green-300
                    hover:bg-primary
                    hover:text-white
                    dark:bg-secondary
                    dark:hover:bg-primary
                    dark:text-gray-200
                  `}
                >
                  <component.ButtonLoader isLoading={deleteLoading} classNameProps="text-inherit mt-1" />
                  <div className={`${deleteLoading && 'mt-1'} `}>Delete</div>
                  
                </button>
            }
            <button
              onClick={resetDeleteStock}
              type="button"
              className={`
                rounded-md
                shadow-md
                p-3
                text-sm
                bg-red-100
                hover:bg-red-500
                dark:bg-red-800
                dark:hover:bg-red-500
                font-medium
                dark:text-gray-200
                hover:text-white
                `}
            >
              {deleteStock ? "Close" : "Delete"}
            </button>
          </div>
          <div className='flex justify-end buttonmax:w-full buttonmax:pt-5'>
            <input 
              className='rounded-xl w-full dark:bg-blueGray-800 dark:text-gray-100'
              type="search"
              placeholder='Search'
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
        </div>
        
        {!deleteStock &&
          <div className='mx-auto flex justify-center align-middle items-center'>
            <component.Snap
              shortenColumn={basicSnap}
              customSnap={datas}
              additionalProps='px-8 pt-2'
            />
          </div>
        }
        <div>
          <div className='hidden md:inline'>
            <component.Table
              column={column}
              classNameProps="mt-5"
              data={data}
              click={addAdditional}
            />
          </div>
          <div className='inline md:hidden'>
            <component.Snap
              shortenColumn={shortenColumn}
              customSnap={renderHistory}
              click={addAdditional}
              chartsLoading={historyLoading || historyFetchLoading}
              selectedDelete={selectedDelete}
            />
          </div>
        </div>
      </div>
      <component.AddStockModal
        open={addStock}
        isLoading={addStockLoading}
        closeModal={addStockModal}
        register={register}
        submit={submit}
        handleSubmit={handleSubmit}
        errors={errors}
        tickerLoading={tickerLoading}
        stockSelection={stockSelection}
        setStockSelection={setStockSelection}
        ticker={ticker}
        setTicker={setTicker}
        searchTicker={searchTicker}
        selectedStock={selectedStock}
        setSelectedStock={setSelectedStock}
      />
    </div>
  )
}

export default connector(Stock)