import component from '../../components';
import {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {encoding} from '../../encryption/encryption';
import {useUserSignIn} from './mutateAuth';
import Shared from './components/Shared';
import {LockClosedIcon} from '@heroicons/react/solid';
import {useForm, SubmitHandler, FieldValues} from 'react-hook-form';
import {connector, CustomModalForm, Props, User} from '../../types';
import SSO from './components/SSO';

function Signin(props: Props) {
  const {register, handleSubmit, formState: {errors}, reset} = useForm();

  const {
    setUser,
    setIsLoggedIn,
    setAdmin,
    setAlert,
    setAlertMessage,
    setAlertStatus
  } = props;

  const [message, setMessage] = useState({type: '', message: ''});

  const navigate = useNavigate();

  const {mutate, isLoading} = useUserSignIn();

  const success = (data: User) => {
    reset();
    if (data.is_superuser) {
      setAdmin(true);
    }
    setUser(data);
    setIsLoggedIn(true);
    navigate('/');
  };

  const submits: SubmitHandler<FieldValues> = (data) => {
    const userInfo = encoding({
      ...data,
      email: data.email.toLowerCase(),
      exp: Math.round(Date.now() / 1000) + (60 * 10), // 10 min expiration
    });

    const payload = {
      userInformation: userInfo,
    };

    mutate(payload, {
      onSuccess: ({data: res}) => {
        if (res.success) {
          success(res.user[0]);
        } else {
          setAlert(true)
          if (Object.prototype.hasOwnProperty.call(res, "header")) {
            setAlertMessage(`${res.header}: ${res.message}`)
          } else {
            setAlertMessage(`Error: ${res.message}`)
          }
          setAlertStatus("error")
          if (res.error === 'email') {
            setMessage({type: 'email', message: res.message});
          } else if (res.error === 'password') {
            setMessage({type: 'password', message: res.message});
          }
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const customForm: Array<CustomModalForm> = [
    {
      divProps: {
        className:"col-span-6 sm:col-span-6"
      },
      label: "Email address",
      labelProps: {
        htmlFor: "email-address"
      },
      inputProps: {
        type: "email",
        ...register('email', {
          pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
          required: true,
        }),
        autoComplete: "email",
        placeholder: "Welcome...."
      },
      children: <>
        {errors.email &&
          errors.email.type === 'pattern' &&
            <p className='text-sm text-red-500'>
              Invalid email
            </p>
        }
        {errors.email &&
          errors.email.type === 'required' &&
            <p className='text-sm text-red-500'>
              This field is required
            </p>
        }
        {message &&
          message.type === 'email' &&
            <p className='text-sm text-red-500'>
              {message.message}
            </p>
        }
      </>
    },
    {
      divProps: {
        className: "col-span-6 sm:col-span-6"
      },
      label: "Password",
      labelProps: {
        htmlFor: "password"
      },
      inputProps: {
        type: "password",
        ...register('password', {required: true, minLength: 7}),
        autoComplete: "password",
        placeholder: "Shhhh dont tell anyone"
      },
      children: <>
        {errors.password &&
          errors.password.type === 'required' &&
            <p className='text-sm text-red-500'>
              This field is required
            </p>
        }
        {errors.password &&
          errors.password.type === 'minLength' &&
            <p className='text-sm text-red-500'>
              Password should be more than 7 characters
            </p>
        }
        {message &&
          message.type === 'password' &&
            <p className='text-sm text-red-500'>
              {message.message}
            </p>
        }
      </>
    }
  ]

  return (
    <Shared
      to="signup"
      link="Sign up now"
      header="Sign in to your account"
      reset={reset}
      {...props}
    >
      <form id="signin" onSubmit={handleSubmit(submits)}>
        <div className="shadow overflow-hidden sm:rounded-md">
          <SSO {...props} text="Sign in with" />
          <div className="px-4 py-5 bg-white sm:p-6 dark:bg-gray-900">
            <div className="grid grid-cols-6 gap-6">
              <component.FormCustom customForm={customForm} />
              <div className="col-span-6 sm:col-span-3">
              </div>
              <div className="col-span-6 sm:col-span-3">
                <div className="text-sm">
                  <Link
                    to="/reset-password"
                    className="
                      float-right
                      font-medium
                      text-secondary
                      hover:text-primary
                    "
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-900 pb-5">
            <button
              type="submit"
              className="
                inline-flex
                w-full
                justify-center
                py-2
                px-4
                border
                border-transparent
                shadow-sm
                text-sm
                font-medium
                rounded-md
                text-white
                bg-primary
                hover:bg-secondary
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-primary"
            >
              <component.ButtonLoader isLoading={isLoading} />
              <span className="left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="
                    h-5
                    w-5
                    text-white
                    group-hover:text-secondary
                  "
                  aria-hidden="true"
                />
              </span>
                Sign in
            </button>
          </div>
        </div>
      </form>
    </Shared>
  );
}

export default connector(Signin);
