export const emptyStockData = {
    created_at: "",
    data: [],
    id: "",
    name: "",
    exch: "",
    symbol: "",
    currency: "",
    price: 0,
    ticker: "",
    rate: 0,
    user_id: 0,
}
