export const emptyLoanData = {
    created_at: "",
    data: [],
    id: "",
    loan_type: "",
    name: "",
    total_amount: 0,
    user_id: 0,
    total_with_interest: 0,
    interest_total: 0, 
    interest_rate: 0, 
    years: 0,
    months: 0
}

export const initialSelected = {
    ...emptyLoanData,
    data: [
      {
        date: '',
        id: 0,
        interest_rate: 0,
        status: '',
        total_amount: 0,
        years: 0,
        months: 0,
        paid_amount: 0,
      },
    ],
};